import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../models/user';
import { global } from './global';

@Injectable({
    providedIn: 'root'
})

export class BuscarService{

    public url: string;
    public identity;
    public token;
    public details;
    public cadianStorage;
    public datos_reserva;

    constructor(
        public _http: HttpClient
    ){
        this.url = global.url;
    }

    buscarCadians(form): Observable<any>{
        return this._http.post(this.url+'user/buscar-cadians', form);
    }

    buscarClientes(form): Observable<any>{
        return this._http.post(this.url+'user/buscar-clientes', form);
    }

    getDatosReserva(){
        let datos = JSON.parse(localStorage.getItem('datos_reserva'));

        if(datos && datos != 'undefined'){
            this.datos_reserva = datos;
        }
        else{
            this.datos_reserva = null;
        }

        return this.datos_reserva;
    }
}