import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { CadianService } from 'src/app/services/cadian.service';
import { Cadian } from 'src/app/models/cadian';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-documentos',
  templateUrl: './documentos.component.html',
  styleUrls: ['./documentos.component.css']
})
export class DocumentosComponent implements OnInit {
  public cadian : Cadian;
  public identity;
  public token;
  public details;
  public uploadedFiles: Array<File> = [];
  public fotoComprobacionFile: Array<File> = [];
  public facturaComprobacionFile: Array<File> = [];
  public cartaFile: Array<File> = [];
  public percent_progress;
  public error_upload;

  // The following variable ("requiredFields") is used to verify the profile
  // progress percent of the cadian. The "requiredFields.property.field" is
  // just an indentifier and it can be any string value.
  private requiredFields: any = {
    // Cadian data
    profile_img: {field: 'cadian.foto', filled: false},
    gender: {field: 'cadian.key_genero', filled: false},
    birth_date: {field: 'cadian.fecha_nacimiento', filled: false},
    fare1: {field: 'cadian.tarifa_hora', filled: false},
    fare2: {field: 'cadian.tarifa_hora2', filled: false},
    fare3: {field: 'cadian.tarifa_hora3', filled: false},
    fare4: {field: 'cadian.tarifa_mensual', filled: false},
    about_me: {field: 'cadian.acerca_de_mi', filled: false},

    // Cadian address
    street: {field: 'addressData.street', filled: false},
    outside_number: {field: 'addressData.outsideNumber', filled: false},
    sector: {field: 'addressData.sector', filled: false},
    city: {field: 'addressData.city', filled: false},
    province: {field: 'addressData.province', filled: false},
    telephone: {field: 'cadian.telefono', filled: false},

    // Cadian documents
    dni_img: {field: 'cadian.foto_dni', filled: false},
    dni_img_verify: {field: 'cadian.foto_dni_comprobacion', filled: false}
  };

  constructor(
    public _userService: UserService,
    public _cadianService: CadianService,
  ) { 
    this.cadian = new Cadian(1,'','',1,1,1,'',1,'',1,1,'','','','','','','','',1,1,1,[],[],'','',[],[],0,[],'','',0,0,0,'',[],'','','',0,0);
    this.loadUser();
  }

  public ngOnInit(): void {
    this.refreshDocumentsNames();
  }

  private refreshDocumentsNames(): void {
    this._userService.getCadianDocumentsNames(this.token).subscribe(
      (response: any) => {
        this.details.foto_dni = response.foto_dni;
        this.details.foto_dni_comprobacion = response.foto_dni_comprobacion;
        this.details.comprobante_factura = response.comprobante_factura;
        this.details.carta_no_antecedentes = response.carta_no_antecedentes;
        localStorage.setItem('details', JSON.stringify(this.details));
        this._userService.detailsChangeEvent.emit();
      },
      (error: any) => {
        // ...
      }
    );
  }

  private refreshProgressBarPercent(): void {
    let fieldsFilled = 0;

    for (let property in this.requiredFields) {
      if (this.requiredFields[property].filled) {
        fieldsFilled++;
      }
    }

    let percent = (100 / Object.keys(this.requiredFields).length) * fieldsFilled;
    this.percent_progress = percent >= 100 ? 100 : Math.ceil(percent);
  }

  public onSubmit(form): void {
    this.refreshProgressBarPercent();
    
    if(this.cadian.check_autorizacion == 0 && this.percent_progress == 100){
      Swal.fire({
        title: 'Un momento',
        icon: 'info',
        text: 'Al aceptar estas afirmando que los datos ingresados son reales y que estos serán utilizados para procesar su depuración.',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aceptar'
      }).then((result) => {
        if (result.value) {
          this.cadian.check_autorizacion = 1;
          this.updateCadian();
        }
      });
    } else {
      this.updateCadian();
    }
  }

  private updateCadian(): void {
    this.error_upload = false;

    if(this.uploadedFiles.length > 0){
      this.submitImageCedula();
    }

    if(this.fotoComprobacionFile.length > 0){
      this.submitFotoComprobacionFile();
    }

    if(this.facturaComprobacionFile.length > 0){
      this.submitFacturaComprobacionFile();
    }

    if(this.cartaFile.length > 0){
      this.submitCartaNoAntecedentes();
    }

    let promise = new Promise((resolve, reject) => {
      setTimeout(() => {
        if(!this.error_upload){
          resolve('Todo bien');
        } else {
          reject('Algo estuvo mal');
        }
      }, 1000);
    });

    promise.then(() => {
        Swal.fire(
          'Documentos actualizados',
          'Tus documentos han sido actualizados con éxito.',
          'success'
        );
    });
  }

  private loadUser(): void {
    this.identity = this._userService.getIdentity();
    this.token = this._userService.getToken();
    this.details = this._userService.getDetails();

    this.cadian = new Cadian(
      this.details.id_cadian, 
      this.details.nombre, 
      this.details.apellidos,
      this.details.key_provincia,
      this.details.key_municipio,
      this.details.key_sector,
      this.details.key_servicios,
      this.details.numero,
      this.details.direccion,
      this.details.key_tipo_cadian,
      this.details.key_genero,
      this.details.correo,
      this.details.telefono,
      this.details.foto,
      this.details.key_dni,
      this.details.foto_dni,
      this.details.foto_dni_comprobacion,
      this.details.key_formacion,
      this.details.key_idiomas,
      this.details.key_uso_pc,
      this.details.key_forma_pago,
      this.details.estatus,
      [],
      [],
      this.details.acerca_de_mi,
      this.details.puesto,
      [],
      [],
      this.details.check_autorizacion,
      [],
      this.details.carta_no_antecedentes,
      this.details.comprobante_factura,
      this.details.tarifa_hora,
      this.details.tarifa_hora2,
      this.details.tarifa_hora3,
      this.details.fecha_nacimiento,
      this.details.key_aptitudes,
      this.details.key_experiencia,
      this.details.facebook,
      this.details.instagram,
      this.details.tarifa_mensual,
      this.details.codigo_postal,
      this.details.ser_autonomo
    );

    if (this.cadian.foto != null) this.requiredFields.profile_img.filled = true;
    if (this.cadian.key_genero != null) this.requiredFields.gender.filled = true;
    if (this.cadian.fecha_nacimiento != null) this.requiredFields.birth_date.filled = true;
    if (this.cadian.tarifa_hora != null) this.requiredFields.fare1.filled = true;
    if (this.cadian.tarifa_hora2 != null) this.requiredFields.fare2.filled = true;
    if (this.cadian.tarifa_hora3 != null) this.requiredFields.fare3.filled = true;
    if (this.cadian.acerca_de_mi != null) this.requiredFields.about_me.filled = true;
    if (this.cadian.tarifa_mensual != null) this.requiredFields.fare4.filled = true;
    if (this.cadian.telefono != null) this.requiredFields.telephone.filled = true;
    if (this.cadian.foto_dni != null) this.requiredFields.dni_img.filled = true;
    if (this.cadian.foto_dni_comprobacion != null) this.requiredFields.dni_img_verify.filled = true;
    this.getCadianAddress();
  }

  private getCadianAddress(): void {
    this._userService.getUserData(this._userService.getToken()).subscribe(
      (response: any) => {
        if (response.street != '') this.requiredFields.street.filled = true;
        if (response.outside_number != '') this.requiredFields.outside_number.filled = true;
        if (response.sector != '') this.requiredFields.sector.filled = true;
        if (response.city != '') this.requiredFields.city.filled = true;
        if (response.province != '') this.requiredFields.province.filled = true;
      },
      (error: any) => {
        // ...
      }
    );
  }

  public submitImageCedula(): void {
    this.uploadedFiles.forEach((element) => {
      let formData = new FormData();
      let fileExtension = element.name.split(".").pop();
      let imageName = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15) + '.'+fileExtension;
      formData.append('cedula', element, imageName);

      let promise = this._userService.updateImageCedula(this.token, formData).toPromise();

      promise.then(
        (response) => {
          if (response.status == 'success') {
            this.details = response.changes;
            localStorage.setItem('details', JSON.stringify(this.details));
            this._userService.detailsChangeEvent.emit();
          }
        },
        (error) => {
          let errores = '';

          Object.keys(error.error.errors).forEach(function (k) {
            errores+= '- '+ error.error.errors[k]+"<br>";
          });

          Swal.fire({
            title: 'UPS',
            icon: 'error',
            html: errores
          });
        }
      );
    });
  }

  public submitFotoComprobacionFile(): void {
    this.fotoComprobacionFile.forEach((element) => {
      let formData = new FormData();
      let fileExtension = element.name.split(".").pop();
      let imageName = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15) + '.'+fileExtension;
      formData.append('foto_comp', element, imageName);

      this._cadianService.updateImageFotoComprobacion(this.token, formData).subscribe(
        response => {
          if (response.status == 'success') {
            this.details = response.changes;
            localStorage.setItem('details', JSON.stringify(this.details));
            this._userService.detailsChangeEvent.emit();
          }
        },
        errorResponse => {
          this.error_upload = true;
          let errores = '';

          Object.keys(errorResponse.error.errors).forEach(function (k) {
            errores+= '- '+ errorResponse.error.errors[k]+"<br>";
          });

          Swal.fire({
            title: 'UPS',
            icon: 'error',
            html: errores
          });
        }
      );
    });
  }

  public submitFacturaComprobacionFile(): void {
    this.facturaComprobacionFile.forEach((element) => {
      let formData = new FormData();
      let fileExtension = element.name.split(".").pop();
      let imageName = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15) + '.'+fileExtension;
      formData.append('factura', element, imageName);

      this._cadianService.updateFacturaComprobacion(this.token, formData).subscribe(
        response => {
          if (response.status == 'success') {
            this.details = response.changes;
            localStorage.setItem('details', JSON.stringify(this.details));
            this._userService.detailsChangeEvent.emit();
          }
        },
        errorResponse => {
          this.error_upload = true;
          let errores = '';

          Object.keys(errorResponse.error.errors).forEach(function (k) {
            errores+= '- '+ errorResponse.error.errors[k]+"<br>";
          });

          Swal.fire({
            title: 'UPS',
            icon: 'error',
            html: errores
          });
        }
      );
    });
  }

  public submitCartaNoAntecedentes(): void {
    this.cartaFile.forEach((element) => {
      let formData = new FormData();
      let fileExtension = element.name.split(".").pop();
      let imageName = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15) + '.'+fileExtension;
      formData.append('carta', element, imageName);

      this._cadianService.updateCartaNoAntecedentes(this.token, formData).subscribe(
        response => {
          if (response.status == 'success') {
            this.details = response.changes;
            localStorage.setItem('details', JSON.stringify(this.details));
            this._userService.detailsChangeEvent.emit();
          }
        },
        errorResponse => {
          this.error_upload = true;
          let errores = '';

          Object.keys(errorResponse.error.errors).forEach(function (k) {
            errores+= '- '+ errorResponse.error.errors[k]+"<br>";
          });

          Swal.fire({
            title: 'UPS',
            icon: 'error',
            html: errores
          });
        }
      );
    });
  }
}
