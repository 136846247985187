import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { BlogService } from 'src/app/services/blog.service';
import { SuscripcionService } from 'src/app/services/suscripcion.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2';
import { DomSanitizer } from '@angular/platform-browser';
declare const runEmbed: any;


@Component({
  selector: 'app-articulo-blog',
  templateUrl: './articulo-blog.component.html',
  styleUrls: ['./articulo-blog.component.css']
})
export class ArticuloBlogComponent implements OnInit {

  public identity;
  public token;
  public details;
  public articulo;
  public categorias;
  public articulos_random;
  

  constructor(
    public _userService: UserService,
    public _blogService: BlogService,
    public _suscripcionService: SuscripcionService,
    private _router: Router,
    private _route: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) { 
    this.loadUser();
  }

  ngOnInit() {
    this.checkArticulo();
    this.getCategorias();
    this.randomArticulos();
  }

  loadUser(){
    this.identity = this._userService.getIdentity();
    this.token = this._userService.getToken();
    this.details = this._userService.getDetails();
  }

  checkArticulo(){

    this._route.params.subscribe(params => {

      let article = params['article'];

      if(article != undefined || article != ''){

          this._blogService.articulo(article).subscribe(
            response => {

              if(response.status == 'success'){
                this.articulo = response.articulo;
                this.articulo.descripcion = this.sanitizer.bypassSecurityTrustHtml(this.articulo.descripcion);
                setTimeout(() => {
                  runEmbed();
                }, 3000);
              }

            },
            errorResponse => {
              this._router.navigate(['/blog']);
            }
          );
      }

    });
}

enviarSuscripcion(form: NgForm){

  let formData = new FormData();
  formData.append('nombre', form.value.nombre);
  formData.append('apellidos', form.value.apellidos);
  formData.append('correo', form.value.correo);

  this._suscripcionService.nuevaSuscripcion(formData).subscribe(
    response => {
      if(response.status == 'success'){
        Swal.fire('Muy bien',response.message,'success');
      }
    },
    errorResponse => {
      Swal.fire('UPS',errorResponse.error.message,'error');
    }
  );
}

getCategorias(){
  this._blogService.getCategorias().subscribe(
    response => {
      if(response.status == 'success'){
        this.categorias = response.categorias;
      }
    },
    errorResponse => {
      console.log(errorResponse);
    }
  );
}

randomArticulos(){
  this._blogService.randomArticuloos().subscribe(
    response => {
      if(response.status == 'success'){
        this.articulos_random = response.articulos;
      }
    },
    errorResponse => {
      console.log(errorResponse);
    }
  );
}

}
