export class Cliente {
    constructor(
        public id: number,
        public nombre: string,
        public apellidos: string,
        public provincia: string,
        public municipio: string,
        public sector: string,
        public numero: number,
        public direccion: string,
        public tipo_cliente: string,
        public key_genero: number,
        public correo: string,
        public telefono: string,
        public foto: string,
        public key_dni: string,
        public foto_dni: string,
        public key_forma_pago: number,
        public estatus: string,
        public acerca_de_mi: string,
        public puesto: string,
        public check_autorizacion: number,
        public foto_dni_comprobacion: string,
        public solicitudes_autonomas: number,
        public facebook: string,
        public instagram: string,
        public codigo_postal: number,
        public key_provincia: number
    ) { }
}