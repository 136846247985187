import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserLoggedGuard } from './guards/userLoggedGuard.service';
import { IsGuestGuard } from './guards/isGuestGuard.service';
import { IsCustomer } from './guards/isCustomer.service';

//IMPORTAR COMPONENTES
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { RegistercadianComponent } from './components/registercadian/registercadian.component';
import { HomeComponent } from './components/home/home.component';
import { ErrorComponent } from './components/error/error.component';
import { TiporegistroComponent } from './components/tiporegistro/tiporegistro.component';
import { PerfilClienteComponent } from './components/perfil-cliente/perfil-cliente.component';
import { ContratosComponent } from './components/perfil-cliente/contratos/contratos.component';
import { MiCuentaComponent } from './components/perfil-cliente/mi-cuenta/mi-cuenta.component';
import { PerfilCadianComponent } from './components/perfil-cadian/perfil-cadian.component';
import { MiCuentaComponent2 } from './components/perfil-cadian/mi-cuenta/mi-cuenta.component';
import { IsCadian } from './guards/isCadian.service';
import { CurriculumComponent } from './components/perfil-cadian/curriculum/curriculum.component';
import { RegistroClienteGuard } from './guards/registroClienteGuard';
import { RegistroCadianGuard } from './guards/registroCadianGuard';
import { RecuperarPasswordComponent } from './components/recuperar-password/recuperar-password.component';
import { ForgotComponent } from './components/forgot/forgot.component';
import { BuscarComponent } from './components/buscar/buscar.component';
import { DocumentosComponent } from './components/perfil-cadian/documentos/documentos.component';
import { DocumentosComponent2 } from './components/perfil-cliente/documentos/documentos.component';
import { ReferenciasComponent } from './components/perfil-cadian/referencias/referencias.component';
import { NuevaOfertaComponent } from './components/perfil-cliente/nueva-oferta/nueva-oferta.component';
import { VacantesComponent } from './components/vacantes/vacantes.component';
import { ClienteTradEmp } from './guards/ClienteTradEmp';
import { BalanceComponent } from './components/balance/balance.component';
import { RecargarBalanceComponent } from './components/balance/recargar-balance/recargar-balance.component';
import { ReservasComponent } from './components/reservas/reservas.component';
import { DetalleComponent } from './components/reservas/detalle/detalle.component';
import { TransferirComponent } from './components/balance/transferir/transferir.component';
import { MisVacantesComponent } from './components/vacantes/mis-vacantes/mis-vacantes.component';
import { PerfilPublicoComponent } from './components/perfil-publico/perfil-publico.component';
import { FeedClienteComponent } from './components/perfil-cliente/feed-cliente/feed-cliente.component';
import { TutorialesComponent } from './components/tutoriales/tutoriales.component';
import { ArticuloComponent } from './components/tutoriales/articulo/articulo.component';
import { FinanzasComponent } from './components/finanzas/finanzas.component';
import { HistorialComponent } from './components/historial/historial.component';
import { FeedCadianComponent } from './components/perfil-cadian/feed-cadian/feed-cadian.component';
import { ConfirmacionReservaComponent } from './components/confirmacion-reserva/confirmacion-reserva.component';
import { MensajeriaComponent } from './components/mensajeria/mensajeria.component';
import { MisPostulacionesComponent } from './components/perfil-cadian/mis-postulaciones/mis-postulaciones.component';
import { FavoritosComponent } from './components/favoritos/favoritos.component';
import { ComoFuncionaComponent } from './components/como-funciona/como-funciona.component';
import { BlogComponent } from './components/blog/blog.component';
import { ArticuloBlogComponent } from './components/blog/articulo-blog/articulo-blog.component';
import { ApprovedComponent } from './components/payment/approved/approved.component';
import { ContactanosComponent } from './components/contactanos/contactanos.component';
import { CanceledComponent } from './components/payment/canceled/canceled.component';
import { DeclinedComponent } from './components/payment/declined/declined.component';
import { SoporteComponent } from './components/soporte/soporte.component';
import { SubastaComponent } from './components/subasta/subasta.component';
import { MembresiasComponent } from './components/membresias/membresias.component';

//DEFINIR LAS RUTAS
const appRoutes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'inicio', component: HomeComponent},
  {path: 'login', component: LoginComponent, canActivate: [IsGuestGuard]},
  {path: 'logout/:sure', component: LoginComponent, canActivate: [UserLoggedGuard]},
  {path: 'registro', component: RegisterComponent, canActivate: [IsGuestGuard]},
  {path: 'recuperar-password', component: RecuperarPasswordComponent, canActivate: [IsGuestGuard]},
  {path: 'forgot/:token', component: ForgotComponent, canActivate: [IsGuestGuard]},
  {path: 'soporte/:token', component: SoporteComponent},
  {path: 'subastas/:token', component: SubastaComponent, canActivate: [UserLoggedGuard]},
  {path: 'buscar', component: BuscarComponent},
  {path: 'perfil-cliente', component: PerfilClienteComponent, canActivate: [UserLoggedGuard, IsCustomer],
    children: [
      {path: '', component: FeedClienteComponent},
      {path: 'documentos', component: DocumentosComponent2},
      {path: 'contratos', component: ContratosComponent},
      {path: 'balance', component: BalanceComponent},
      {path: 'reservas', component: ReservasComponent},
      {path: 'reservas/:token', component: DetalleComponent},
      {path: 'mis-vacantes', component: MisVacantesComponent},
      {path: 'mi-cuenta', component: MiCuentaComponent},
      {path: 'historial', component: HistorialComponent},
      {path: 'favoritos', component: FavoritosComponent},
      {path: 'tutoriales', component: TutorialesComponent},
      {path: 'tutoriales/:article', component: ArticuloComponent}
    ]
  },
  {path: 'perfil-cadian', component: PerfilCadianComponent, canActivate: [UserLoggedGuard, IsCadian],
    children: [
      {path: '', component: FeedCadianComponent},
      {path: 'curriculum', component: CurriculumComponent},
      {path: 'documentos', component: DocumentosComponent},
      {path: 'referencias', component: ReferenciasComponent},
      {path: 'balance', component: BalanceComponent},
      {path: 'reservas', component: ReservasComponent},
      {path: 'reservas/:token', component: DetalleComponent},
      {path: 'mis-postulaciones', component: MisPostulacionesComponent},
      {path: 'finanzas', component: FinanzasComponent},
      {path: 'mi-cuenta', component: MiCuentaComponent2},
      {path: 'historial', component: HistorialComponent},
      {path: 'favoritos', component: FavoritosComponent},
      {path: 'tutoriales', component: TutorialesComponent},
      {path: 'tutoriales/:article', component: ArticuloComponent}
    ]
  },
  {path: 'nueva-oferta', component: NuevaOfertaComponent, canActivate: [UserLoggedGuard, ClienteTradEmp]},
  {path: 'vacantes', component: VacantesComponent, canActivate: [UserLoggedGuard]},
  {path: 'recargar-balance', component: RecargarBalanceComponent, canActivate: [UserLoggedGuard]},
  {path: 'transferir-balance', component: TransferirComponent, canActivate: [UserLoggedGuard]},
  {path: 'perfil/:token', component: PerfilPublicoComponent, canActivate: [UserLoggedGuard]},
  {path: 'blog', component: BlogComponent},
  {path: 'blog/:article', component: ArticuloBlogComponent},
  {path: 'confirmacion/:token', component: ConfirmacionReservaComponent, canActivate: [UserLoggedGuard]},
  {path: 'mensajeria/:token', component: MensajeriaComponent, canActivate: [UserLoggedGuard]},
  {path: 'como-funciona', component: ComoFuncionaComponent},
  {path: 'membresias', component: MembresiasComponent},
  {path: 'payment-approved', component: ApprovedComponent},
  {path: 'payment-canceled', component: CanceledComponent},
  {path: 'payment-declined', component: DeclinedComponent},
  {path: 'contactanos', component: ContactanosComponent},
  {path: '**', component: ErrorComponent}
];

//EXPORTAR CONFIGURACIÓN
export const appRoutingProviders: any[] = [];
export const routing: ModuleWithProviders = RouterModule.forRoot(appRoutes);