import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { global } from './global';

@Injectable({
    providedIn: 'root'
})
export class ClienteService {
    url: string;
    identity;
    token;
    details;

    constructor(public _http: HttpClient) {
        this.url = global.url;
    }

    update(token,user): Observable<any> {
        let json = JSON.stringify(user);
        let params = 'json='+json;
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
        headers = headers.set('Authorization', token);
        return this._http.put(this.url+'user/update-cliente', params, {headers: headers});
    }

    updateContrasena(token, password, currentPassword): Observable<any> {
        let obj = {"new_password":password, "current_password": currentPassword}; // Crear objeto
        let headers = new HttpHeaders().set('Authorization', token);
        return this._http.post(this.url+'user/password/change', obj, {headers: headers});
    }

    updateImageFotoComprobacion(token, formData: any): Observable<any> {
        let headers = new HttpHeaders().set('Authorization', token);
        return this._http.post(this.url+'user/upload-cliente-foto-comprobacion', formData, {headers: headers});
    }

    nuevaOferta(token, formData: any): Observable<any> {
        let headers = new HttpHeaders().set('Authorization', token);
        return this._http.post(this.url+'user/nueva-oferta', formData, {headers: headers});
    }

    pagarDestacadoBalance(token, form: any): Observable<any> {
        let headers = new HttpHeaders().set('Authorization', token);
        return this._http.post(this.url+'user/pagar-destacado-balance', form, {headers: headers});
    }

    pagarDesbloqueoBalance(token, form: any): Observable<any> {
        let headers = new HttpHeaders().set('Authorization', token);
        return this._http.post(this.url+'user/pagar-desbloqueo-balance', form, {headers: headers});
    }

    stats(token):Observable<any> {
        let headers = new HttpHeaders().set('Authorization', token);
        return this._http.get(this.url+'user/cliente-stats',{headers: headers});
    }
}