import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { HistorialService } from 'src/app/services/historial.service';
import { global } from '../../services/global';
declare let $: any;

@Component({
	selector: 'app-historial',
	templateUrl: './historial.component.html',
	styleUrls: ['./historial.component.css']
})
export class HistorialComponent implements OnInit {
	private identity: any;
	private token: any;
	private details: any;
	public historial: any[] = [];
	public apiUrl = global.url;

	public unlockedCadianData: any = {
		image: null,
		name: null,
		age: 0,
		province: null,
		city: null,
		studies_level: null,
		aptitudes: []
	};

	constructor(
		private userService: UserService,
		private historialService: HistorialService
	) {
		this.loadUser();
	}

	public ngOnInit(): void {
		this.getHistorial();
	}

	private loadUser(): void {
		this.identity = this.userService.getIdentity();
		this.token = this.userService.getToken();
		this.details = this.userService.getDetails();
	}

	public getUnlockedCadianData(unlockDate: string): void {
		this.historialService
			.getUnlockedCadianData(this.details.id_cliente, unlockDate, this.token)
			.subscribe(
				(response: any) => {
					this.unlockedCadianData.image = response.profile_image;
					this.unlockedCadianData.name = response.name == null ? '- Sin definir -' : response.name;
					this.unlockedCadianData.age = response.age == null ? '- Sin definir -' : response.age;
					this.unlockedCadianData.province = response.province == null ? '- Sin definir -' : response.province;
					this.unlockedCadianData.city = response.city == null ? '- Sin definir -' : response.city;
					this.unlockedCadianData.studies_level = response.studies_level == null ? '- Sin definir -' : response.studies_level;
					this.unlockedCadianData.aptitudes = response.aptitudes;
					this.showModalUnlockedCadian();
				},
				(error: any) => {
					// ...
				}
			);
	}

	private getHistorial(): void {
		this.historialService.getHistorial(this.token).subscribe(
			(response: any) => {
				if (response.status == 'success') {
					this.historial = response.historial;
				}
			},
			(error: any) => {
				// ...
			}
		);
	}

	private showModalUnlockedCadian(): void {
		$('#modalUnlockedCadian').modal('show');
	}
}
