import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SubastasService } from 'src/app/services/subastas.service';
import { global } from '../../services/global';
import { ClienteService } from 'src/app/services/cliente.service';
import { DesbloqueoCuidadorService } from 'src/app/services/desbloqueoCuidador.service';
import { CargosService } from 'src/app/services/cargos.service';
import { VacantesService } from 'src/app/services/vacantes.service';
import Swal from 'sweetalert2';
import * as UIkit from 'uikit';

@Component({
	selector: 'app-subasta',
	templateUrl: './subasta.component.html',
	styleUrls: ['./subasta.component.css']
})
export class SubastaComponent implements OnInit {
	public identity;
	public token;
	public details;
	public subasta: any = {};
	public cliente: any = {};
	public oferta: any = {};
	public aptitudes;
	public url;
	public expiracion: any = 0;
	public tarifa;
	public participantes: any = [];

	public coundownTexts: any = {
		Days: "Días",
		Hours: "Hor.",
		Minutes: "Min.",
		Seconds: "Seg."
	};

	public cadian_obj: any = {};
	public cargos = [];
	public id_cuidador;
	public cadian_pagado = false;

	public auctionPaymentData: any = {
		cadian_name: '',
		cadian_fare: '',
		auction_hours: '',
		auction_amount: '',
		client_balance: ''
	};

	constructor(
		public _userService: UserService,
		private _router: Router,
		private _route: ActivatedRoute,
		private _subastasService: SubastasService,
		public _clienteService: ClienteService,
		public _desbloqueoService: DesbloqueoCuidadorService,
		public _cargosService: CargosService,
		public _vacantesService: VacantesService
	) { 
		this.loadUser();
		this.url = global.url;
	}

	ngOnInit() {
		this.getSubasta();
		this.getCargos();
	}

	loadUser() {
		this.identity = this._userService.getIdentity();
		this.token = this._userService.getToken();
		this.details = this._userService.getDetails();
	}

	getSubasta() {
		this._route.params.subscribe(params => {
			let token_subasta = params['token'];

			if (token_subasta != undefined || token_subasta != '') {
				this._subastasService.getSubasta(token_subasta,this.token).subscribe(
					response => {
						if (response.status == 'success') {
							this.subasta = response.subasta;

							console.log(this.subasta);

							this.auctionPaymentData.auction_hours = this.subasta.auction_hours;
							this.auctionPaymentData.client_balance = this.subasta.client_balance;

							this.cliente = response.cliente;
							this.oferta = response.oferta;
							this.aptitudes = response.aptitudes;
							this.expiracion = response.expiracion;
							this.participantes = response.participantes;
						}
					},
					errorResponse => {
						Swal.fire('¡UPS!', errorResponse.error.message , 'error');
						this._router.navigate(['/']);
					}
				);
			} else {
				// ...
			}
		});
	}

	ofertar() {
		let form = new FormData();
		form.append('tarifa', this.tarifa);
		form.append('token_subasta', this.subasta.token);

		this._subastasService.ofertar(this.token,form).subscribe(
			response => {
				if(response.status == 'success'){
					Swal.fire('¡Muy bien!', response.message , 'success');
					this.participantes = response.participantes;
					this.tarifa = 0;
				}
			},
			errorResponse => {
				Swal.fire('¡UPS!', errorResponse.error.message , 'error');
			}
		);
	}

	public pagarDesbloqueo(): void {
		let form = new FormData();
		form.append('id_cadian', this.id_cuidador);

		Swal.fire({
			title: 'Un momento',
			icon: 'info',
			text: 'Confirmanos que quieres pagar el desbloqueo de este cuidador',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Pagar'
		}).then((result) => {
			if (result.value) {
				this._clienteService.pagarDesbloqueoBalance(this.token, form).subscribe(
					(response: any) => {
						if (response.status == 'success') {
							Swal.fire('¡Muy bien!', response.message , 'success');
						} else {
							Swal.fire('UPS', response.message , 'error');
						}
					},
					(error: any) => {
						// ...
					}
				);
			}
		});
	}

	getCargos() {
		this._cargosService.getCargos().subscribe(
			response => {
				if (response.status == 'success') {
					this.cargos = response.cargos;
				}
			},
			errorResponse => {
				//...
			}
		);
	}

	public contactar(): void {
		let form = new FormData();
		form.append('id_cadian', this.id_cuidador);
		this.cadian_pagado = false;

		// CONSULTAMOS SI EL CUIDADOR A CONTACTAR YA FUE CONTACTADO ANTERIORMENTE
		this._desbloqueoService.checkDesbloqueo(this.token, form).subscribe(
			(response: any) => {
				if (response.status == 'success') {
					this.cadian_pagado = true;
					this._router.navigate(['/mensajeria/' + response.token_conversacion]);
				} else {
					let modal = UIkit.modal("#modal-desbloqueo");
					modal.show();
				}
			},
			(error: any) => {
				// ...
			}
		);
	}

	public selectAuctionPostulant(): void {
		if (parseFloat(this.auctionPaymentData.client_balance) < parseFloat(this.auctionPaymentData.auction_amount)) {
			Swal.fire({
				title: 'Saldo insuficiente',
				icon: 'info',
				text: 'No tienes saldo suficiente para pagar la subasta. Por favor realiza una recarga e intenta de nuevo.',
				confirmButtonText: 'Aceptar'
			});
		} else {
			this._subastasService.selectAuctionPostulant(this.subasta.id, this.id_cuidador.toString(), this.token).subscribe(
				(response: any) => {
					Swal.fire({
						title: 'Cuidador seleccionado',
						icon: 'success',
						html: 'Puedes contactar al cuidador en el menú <span style="color: black; font-weight: bold; display: inline-block;">Mis vacantes</span>',
						confirmButtonText: 'Aceptar',
						allowOutsideClick: false,
						allowEscapeKey: false,
						allowEnterKey: false
					});

					this._userService.sharedStorage = {show_auction_in_process: true};
					this._router.navigate(['/perfil-cliente/mis-vacantes']);
				},
				(error: any) => {
					// ...
				}
			);
		}
	}

	public showAuctionPaymentData(cadian: any): void {
		this.auctionPaymentData.cadian_name = cadian[2].nombre + cadian[2].apellidos;
		this.auctionPaymentData.cadian_fare = parseFloat(cadian[1]).toFixed(2);
		this.auctionPaymentData.auction_amount = (parseFloat(cadian[1].toString()) * parseFloat(this.auctionPaymentData.auction_hours.toString())).toFixed(2);
		UIkit.modal("#modal_payment_data").show();
	}

	public showConfirmCancelAuctionWind(): void {
    Swal.fire({
      icon: 'info',
      html: '¿Desea cancelar esta subasta?',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showCancelButton: true,
      cancelButtonText: 'No',
      showConfirmButton: true,
      confirmButtonText: 'Si'
    }).then((result: any) => {
      if (result.isConfirmed) {
        this._vacantesService.cancelAuction(this.subasta.key_oferta.toString(), this.token).subscribe(
          (response: any) => {
            Swal.fire({
              icon: 'success',
              title: 'Subasta cancelada',
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
              confirmButtonText: 'Aceptar'
            }).then(() => {
            	this._router.navigate(['/perfil-cliente/mis-vacantes']);
            });
          },
          (error: any) => {
            // ...
          }
        );
      }
    });
  }
}
