import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { global } from './global';

@Injectable({
  providedIn: 'root'
})
export class TutorialesService {

  public url: string;

  constructor(
    private _http: HttpClient
  ) { 
    this.url = global.url;
  }

    getTutoriales():Observable<any>{
      let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
      return this._http.get(this.url+'user/tutoriales', {headers: headers});
    }

    getCategorias():Observable<any>{
      return this._http.get(this.url+'user/categorias-tutorial');
    }

    randomArticuloos():Observable<any>{
      return this._http.get(this.url+'user/random-articulos');
    }


    buscar(form: any):Observable<any>{
        return this._http.post(this.url+'user/busqueda-tutorial', form);
    }

    articulo(slug):Observable<any>{
        return this._http.get(this.url+'user/tutoriales/'+slug);
    }
}
