import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
declare let google: any;
declare let $: any;
import * as UIkit from 'uikit';
@Component({
	selector: 'google-maps-address',
	templateUrl: './address.component.html',
	styleUrls: ['./address.component.css']
})
export class AddressComponent implements OnInit, AfterViewInit {
	@ViewChild('inputStreet', { static: false }) private inputStreet: ElementRef<any>;
	@ViewChild('inputSector', { static: false }) private inputSector: ElementRef<any>;
	@ViewChild('inputCity', { static: false }) private inputCity: ElementRef<any>;
	@ViewChild('inputProvince', { static: false }) private inputProvince: ElementRef<any>;
	@ViewChild('inputStreetTooltip', { static: false }) private inputStreetTooltip: ElementRef<any>;
	@ViewChild('inputSectorTooltip', { static: false }) private inputSectorTooltip: ElementRef<any>;
	@ViewChild('inputCityTooltip', { static: false }) private inputCityTooltip: ElementRef<any>;
	@ViewChild('inputProvinceTooltip', { static: false }) private inputProvinceTooltip: ElementRef<any>;
	@Input() inputAddressData: any;
	@Output() onAddressChange = new EventEmitter<any>();

	public addressData = {
		street: '',
		outsideNumber: '',
		sector: '',
		city: '',
		province: '',
		latitude: 0,
		longitude: 0
	};

	private countryForAutocomplete = 'do';

	constructor() {
		//...
	}

	public emitAddressData(): void {
		this.onAddressChange.emit(this.addressData);
	}

	public ngOnInit(): void {
		if (this.inputAddressData != undefined) {
			this.addressData.street = this.inputAddressData.street;
			this.addressData.outsideNumber = this.inputAddressData.outsideNumber;
			this.addressData.sector = this.inputAddressData.sector;
			this.addressData.city = this.inputAddressData.city;
			this.addressData.province = this.inputAddressData.province;
		}
	}

	public ngAfterViewInit(): void {
		this.initAutocompleteStreet();
		this.initAutocompleteSector();
		this.initAutocompleteCity();
		this.initAutocompleteProvince();

		//------------------------------------------------------------------------------------------

		// Google maps API - Geocode example

		/* let geocoder = new google.maps.Geocoder();

		geocoder.geocode({ location: { lat: 24.801316, lng: -107.475804 } }, (results, status) => {
			if (status === "OK") {
				if (results[0]) {
					console.log('geocoder', results[0]);
				} else {
					window.alert("No results found");
				}
			} else {
				window.alert("Geocoder failed due to: " + status);
			}
		}); */

		//------------------------------------------------------------------------------------------

	}

	private initAutocompleteStreet(): void {
		let autocompleteStreet = new google.maps.places.Autocomplete(
			this.inputStreet.nativeElement,
			{
				componentRestrictions: { country: this.countryForAutocomplete },
				fields: ['address_components', 'geometry'],
				types: ['address']
			}
		);

		autocompleteStreet.addListener('place_changed', () => {
			this.setData(autocompleteStreet.getPlace());
		});
	}

	private initAutocompleteSector(): void {
		let autocompleteSector = new google.maps.places.Autocomplete(
			this.inputSector.nativeElement,
			{
				componentRestrictions: { country: this.countryForAutocomplete },
				fields: ['address_components'],
				types: ['geocode', 'establishment']
			}
		);

		autocompleteSector.addListener('place_changed', () => {
			this.inputSectorTooltip.nativeElement.style.display = 'none';
			let data = autocompleteSector.getPlace();
			this.addressData.sector = '';

			if (data.hasOwnProperty('address_components')) {
				let configuredNeighborhood = false;

				for (let item of data.address_components) {
					for (let type of item.types) {
						if (type == 'neighborhood' || type == 'sublocality_level_1' || type == 'sublocality') {
							this.addressData.sector = item.long_name;
							configuredNeighborhood = true;
							break;
						}
					}

					if (configuredNeighborhood) {
						break;
					}
				}

				if (!configuredNeighborhood) {
					this.inputSectorTooltip.nativeElement.style.display = 'inline';
				}
			} else {
				this.inputSectorTooltip.nativeElement.style.display = 'inline';
			}

			this.emitAddressData();
		});
	}

	private initAutocompleteCity(): void {
		let autocompleteCity = new google.maps.places.Autocomplete(
			this.inputCity.nativeElement,
			{
				componentRestrictions: { country: this.countryForAutocomplete },
				fields: ['address_components'],
				types: ['(regions)']
			}
		);

		autocompleteCity.addListener('place_changed', () => {
			this.inputCityTooltip.nativeElement.style.display = 'none';
			let data = autocompleteCity.getPlace();
			this.addressData.city = '';

			if (data.hasOwnProperty('address_components')) {
				let configuredLocality = false;

				for (let item of data.address_components) {
					for (let type of item.types) {
						if (type == 'locality') {
							this.addressData.city = item.long_name;
							configuredLocality = true;
							break;
						}
					}

					if (configuredLocality) {
						break;
					}
				}

				if (!configuredLocality) {
					this.inputCityTooltip.nativeElement.style.display = 'inline';
				}
			} else {
				this.inputCityTooltip.nativeElement.style.display = 'inline';
			}

			this.emitAddressData();
		});
	}

	private initAutocompleteProvince(): void {
		let autocompleteProvince = new google.maps.places.Autocomplete(
			this.inputProvince.nativeElement,
			{
				componentRestrictions: { country: this.countryForAutocomplete },
				fields: ['address_components'],
				types: ['(regions)']
			}
		);

		autocompleteProvince.addListener('place_changed', () => {
			this.inputProvinceTooltip.nativeElement.style.display = 'none';
			let data = autocompleteProvince.getPlace();
			this.addressData.province = '';

			if (data.hasOwnProperty('address_components')) {
				let configuredData = false;

				for (let item of data.address_components) {
					for (let type of item.types) {
						if (type == 'administrative_area_level_1') {
							this.addressData.province = item.long_name;
							configuredData = true;
							break;
						}
					}

					if (configuredData) {
						break;
					}
				}

				if (!configuredData) {
					this.inputProvinceTooltip.nativeElement.style.display = 'inline';
				}
			} else {
				this.inputProvinceTooltip.nativeElement.style.display = 'inline';
			}

			this.emitAddressData();
		});
	}

	private setData(data: any): void {
		this.addressData.street = '';
		this.addressData.sector = '';
		this.addressData.city = '';
		this.addressData.province = '';
		this.addressData.latitude = 0;
		this.addressData.longitude = 0;
		this.inputStreetTooltip.nativeElement.style.display = 'none';
		this.inputSectorTooltip.nativeElement.style.display = 'none';
		this.inputCityTooltip.nativeElement.style.display = 'none';
		this.inputProvinceTooltip.nativeElement.style.display = 'none';

		if (data.hasOwnProperty('address_components')) {
			this.addressData.latitude = data.geometry.location.lat();
			this.addressData.longitude = data.geometry.location.lng();
			let configuredRoute = false;

			for (let item of data.address_components) {
				for (let type of item.types) {
					if (type == 'route') {
						this.addressData.street = item.long_name;
						configuredRoute = true;
						break;
					} else if (type == 'neighborhood' || type == 'sublocality_level_1' || type == 'sublocality') {
						this.addressData.sector = item.long_name;
						break;
					} else if (type == 'locality') {
						this.addressData.city = item.long_name;
						break;
					} else if (type == 'administrative_area_level_1') {
						this.addressData.province = item.long_name;
						break;
					}
				}
			}

			if (!configuredRoute) {
				this.inputStreetTooltip.nativeElement.style.display = 'inline';
			}
		} else {
			this.inputStreetTooltip.nativeElement.style.display = 'inline';
		}

		this.emitAddressData();
	}
}
