import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { PostulacionesService } from 'src/app/services/postulaciones.service';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { CadianService } from '../../../services/cadian.service';
import { FeedService } from '../../../services/feed.service';
import { UserService } from '../../../services/user.service';
import { global } from '../../../services/global';
import { ServiciosService } from '../../../services/servicios.service';
import { ProvinciasService } from '../../../services/provincias.service';
import { MunicipiosService } from '../../../services/municipios.service';
import { DistritosService } from '../../../services/distritos.service';
import { AtencionesService } from '../../../services/atenciones.service';
import { FavoritoService } from '../../../services/favorito.service';
import { CargosService } from '../../../services/cargos.service';
import { MembresiasService } from '../../../services/membresias.service';
import { faDotCircle } from '@fortawesome/free-solid-svg-icons';
import { SubastasService } from 'src/app/services/subastas.service';

import Swal from 'sweetalert2';
import * as UIkit from 'uikit';

@Component({
  selector: 'app-feed-cadian',
  templateUrl: './feed-cadian.component.html',
  styleUrls: ['./feed-cadian.component.css']
})
export class FeedCadianComponent implements OnInit, OnDestroy {
  public identity;
  public token;
  public details;
  public ofertas: any = [];
  public url;
  public p: number = 1;
  public oferta_selected: any;
  public documentos: Array<File> = [];
  public carta_presentacion: boolean = false;
  public documentos_subidos;
  public tipo = 'trabajo';
  public select_oferta = 'todos';
  public select_edad = 'todos';
  public select_experiencia = 0;
  public select_tipo = 'todos';
  public municipios = [];
  public select_ciudad = 'todos';
  public select_categoria = 'todos';
  public atenciones;
  public provincias;
  public distritos;
  public servicios;
  public faStar = faStar;
  public cargos = [];
  public membresia = [];
  public faDotCircle = faDotCircle;
  public city: string = '';

  constructor(
    public _userService: UserService,
    public _feedService: FeedService,
    public _cadianService: CadianService,
    public _postulacionService: PostulacionesService,
    public _servicioService: ServiciosService,
    public _provinciasService: ProvinciasService,
    public _municipiosService: MunicipiosService,
    public _distritosService: DistritosService,
    public _atencionesService: AtencionesService,
    public _favoritoService: FavoritoService,
    public _membresiasService: MembresiasService,
    public _cargosService: CargosService,
    private loadingBarService: LoadingBarService,
    private _router: Router,
    private _subastasService: SubastasService
  ) {
    this.loadUser();
    this.url = global.url;
    this.oferta_selected = { titulo: '' }
  }

  ngOnInit() {
    this.getOfertas();
    this.getProvincias();
    this.getDistritos();
    this.getAtenciones();
    this.getMunicipios();
    this.getServicios();
    this.getCargos();
    this.getMembresiaEstatus();
  }

  ngOnDestroy() {
    this.loadingBarService.complete();
  }

  loadUser() {
    this.identity = this._userService.getIdentity();
    this.token = this._userService.getToken();
    this.details = this._userService.getDetails();
  }

  getMembresiaEstatus() {
    this._membresiasService.getMembresiaEstatus(this.token).subscribe(
      (response: any) => {
        if (response.status == 'success') {
          this.membresia = response.membresias;
        } else {
          // ...
        }
      },
      (error: any) => {
        // ...
      }
    );
  }

  getCargos() {
    this._cargosService.getCargos().subscribe(
      (response: any) => {
        if (response.status == 'success') {
          this.cargos = response.cargos;
        } else {
          // ...
        }
      },
      (error: any) => {
        // ...
      }
    );
  }

  getServicios() {
    this._servicioService.getServicios().subscribe(
      (response: any) => {
        if (response.status == 'success') {
          this.servicios = response.servicios;
        } else {
          // ...
        }
      },
      (error: any) => {
        // ...
      }
    );
  }

  getProvincias() {
    this._provinciasService.getProvincias().subscribe(
      (response: any) => {
        if (response.status == 'success') {
          this.provincias = response.provincias;
        } else {
          // ...
        }
      },
      (error: any) => {
        let errores = '';

        Object.keys(error.error.error).forEach(function (k) {
          errores += '- ' + error.error.error[k] + "<br>";
        });

        Swal.fire({
          title: 'UPS',
          icon: 'error',
          html: errores
        });
      }
    );
  }

  getMunicipios() {
    this._municipiosService.getMunicipios().subscribe(
      (response: any) => {
        if (response.status == 'success') {
          this.municipios = response.municipios;
        } else {
          // ...
        }
      },
      (error: any) => {
        // ...
      }
    );
  }

  getDistritos($event = null, change = false) {
    let provincia = 0;

    if (change) {
      provincia = $event.target.selectedOptions[0].value;
    }

    this._distritosService.getDistritosByProvincia(provincia).subscribe(
      (response: any) => {
        if (response.status == 'success') {
          this.distritos = response.distritos;
        } else {
          // ...
        }
      },
      (error: any) => {
        let errores = '';

        Object.keys(error.error.error).forEach(function (k) {
          errores += '- ' + error.error.error[k] + "<br>";
        });

        Swal.fire({
          title: 'UPS',
          icon: 'error',
          html: errores
        });
      }
    );
  }

  getAtenciones() {
    this._atencionesService.getAtenciones().subscribe(
      (response: any) => {
        if (response.status == 'success') {
          this.atenciones = response.atenciones;
        } else {
          // ...
        }
      },
      (error: any) => {
        // ...
      }
    );
  }

  getOfertas() {
    this.loadingBarService.complete();
    this.loadingBarService.start();

    this._feedService.getOfertas(
      this.token,
      this.select_oferta,
      this.select_edad,
      this.select_experiencia,
      // this.select_ciudad,
      this.city,
      this.select_categoria
    ).subscribe(
      (response: any) => {
        if (response.status == 'success') {
          this.ofertas = response.ofertas;
          this.p = 1;
        } else {
          // ...
        }

        this.loadingBarService.complete();
      },
      (error: any) => {
        this.loadingBarService.complete();
      }
    );
  }

  ofertasPageChange(page, element) {
    element.scrollIntoView({behavior: 'smooth', block: 'center'});
    this.p = page;
  }

  public onSubmit(): void {
    if (this.oferta_selected.tipo_reserva != undefined && this.oferta_selected.tipo_reserva == 2) {
      Swal.fire({
        icon: 'info',
        title: `Tarifa maxima $${this.oferta_selected.tarifa} RD`,
        html: 'Recuerda que esta es una subasta y mientras menor sea tu oferta tendrás mayores posibilidades de ser elegido(a).',
        input: 'text',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Enviar'
      }).then((result: any) => {
        if (result.isConfirmed) {
          this.sendAuctionBid(result.value);
        }
      });
    } else {
      let cargo = 0;
      let tipo = "";
      let string_title;

      if (this.oferta_selected.tipo == 'RESERVA') {
        cargo = this.oferta_selected.verificados == 0 ? this.cargos[27].costo : this.cargos[29].costo;
        tipo = "reserva";
      } else {
        if (this.oferta_selected.key_tipo_cadian == 2) {
          cargo = this.oferta_selected.verificados == 0 ? this.cargos[27].costo : this.cargos[29].costo;
        } else {
          cargo = this.oferta_selected.verificados == 0 ? this.cargos[28].costo : this.cargos[30].costo;
        }

        tipo = "oferta";
      }

      let formData = new FormData();
      // formData.append('carta', form.value.carta);
      formData.append('oferta', this.oferta_selected.id);
      formData.append('tipo', this.oferta_selected.tipo);
      formData.append('verificados', this.oferta_selected.verificados);
      formData.append('documentos', this.documentos_subidos);

      Swal.fire({
        title: 'Un momento',
        icon: 'info',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Estoy de acuerdo',
        onOpen: () => { Swal.showLoading(); }
      }).then((result: any) => {
        if (result.value) {
          this.sendPostulation(formData);
        }
      });

      this._cadianService.getPrice(this.token, this.oferta_selected.id, tipo).then(
        (response: any) => {
          setTimeout(() => {
            string_title = 'Se te realizara un cargo de RD$' + cargo + ' para postularte a esta oferta. ';
            Swal.hideLoading();

            Swal.update({
              showConfirmButton: true,
              showCancelButton: true,
              text: string_title
            });
          }, 500);
        },
        (error: any) => {
          setTimeout(() => {
            string_title = '¿Desea postularse en esta oferta? ';
            Swal.hideLoading();

            Swal.update({
              showConfirmButton: true,
              showCancelButton: true,
              text: string_title
            });
          }, 500);
        }
      );
    }
  }

  private sendAuctionBid(fare: string): void {
    let form = new FormData();
    form.append('tarifa', fare);
    form.append('token_subasta', this.oferta_selected.token_subasta);

    this._subastasService.ofertar(this.token, form).subscribe(
      (response: any) => {
        if(response.status == 'success'){
          Swal.fire('¡Muy bien!', response.message, 'success');
        }
      },
      (error: any) => {
        Swal.fire('¡UPS!', error.error.message , 'error');
      }
    );
  }

  private sendPostulation(formData: FormData): void {
    this._cadianService.enviarPostulacion(this.token, formData).subscribe(
      (response: any) => {
        if (response.status == 'success') {
          Swal.fire({
            title: '¡Muy bien!',
            text: response.message,
            icon: 'success'
          }).then((result: any) => {
            if (result.value) {
              window.location.href = '/perfil-cadian/mis-postulaciones';
            }
          });
        }
      },
      (error: any) => {
        if (error.error.validacion == "BALANCE") {
          Swal.fire({
            title: 'UPS',
            text: 'No tienes Balance sufuiente',
            icon: 'error',
            showCancelButton: true,
            cancelButtonColor: '#d33',
            confirmButtonText: 'Recargar balance',
            cancelButtonText: 'Cancelar'
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.href = "/recargar-balance";
            }
          });
        } else {
          Swal.fire({
            title: 'UPS',
            icon: 'error',
            showCancelButton: true,
            text: error.error.message,
            confirmButtonText: 'Verifica tu cuenta',
            cancelButtonText: 'OK',
          }).then((result) => {
            if (result.isConfirmed) {
              this.checkAutonomo();
            }
          });
        }
      }
    );
  }

  subirDocumentos() {
    if (this.documentos.length == 0) {
      Swal.fire('¡UPS!', 'Sube minimo 1 documento para la postulación.', 'error');
      return;
    }

    let formData = new FormData();

    for (let i = 0; i < this.documentos.length; i++) {
      formData.append('documentos[' + i + ']', this.documentos[i], this.documentos[i].name);
    }

    formData.append('oferta', this.oferta_selected.id);
    formData.append('tipo', this.oferta_selected.tipo);

    Swal.fire({
      title: 'Subiendo documentos...',
      icon: 'info',
      text: '',
      showCloseButton: false,
      showCancelButton: false,
      showConfirmButton: false
    });

    this._postulacionService.subirDocumentos(this.token, formData).subscribe(
      (response: any) => {
        if (response.status == 'success') {
          Swal.fire('¡Muy bien!', 'Documentos subidos con éxito.', 'success');
          this.documentos_subidos = response.documentos;
        } else {
          // ...
        }
      },
      (error: any) => {
        Swal.fire({
          title: 'UPS',
          icon: 'error',
          text: error.error.message
        });
      }
    );
  }

  public setOferta(obj: any): void {
    this.oferta_selected = obj;
    this.documentos_subidos = undefined;
    this.carta_presentacion = false;
    this.onSubmit();

    // let modal = UIkit.modal("#modal-postularse");
    // modal.show();
  }

  shortDesc(text: any) {
    let text_replace = text.replace(/<(.|\n)*?>/g, "");
    let text_array = text_replace.split(' ');
    let short_text = '';

    if (text_array.length == 0) {
      return text;
    }

    for (let i = 0; i <= text_array.length; i++) {
      if (i == 35 || text_array[i] == undefined) {
        return short_text + '..... ';
      }

      short_text += text_array[i] + ' ';
    }
  }

  diasTrabajo(str) {
    let explode = str.split(',');
    let length = explode.length;
    return explode[0] + '-' + explode[length - 1];
  }

  verOferta(obj) {
    this.carta_presentacion = false;
    this.oferta_selected = obj;
    let modal = UIkit.modal("#modal-oferta");
    modal.show();
  }

  postulacionModal(oferta) {
    if (oferta.tipo == 'RESERVA' && (this.details.fecha_autorizacion == null || this.details.fecha_autorizacion == '')) {
      Swal.fire('¡UPS!', 'Para postularse a reservas por horas necesitas ser Cuidador Autónomo y tener cuenta activada', 'error');
      return;
    }

    this.oferta_selected = oferta;

    if (this.identity != null) {
      // let modal = UIkit.modal("#modal-postularse");
      // modal.show();
      this.onSubmit();
    } else {
      Swal.fire('¡UPS!', 'Ingresa como Cuidador para postularte a esta oferta', 'error');
    }
  }

  explode(string) {
    return string.split(",");
  }

  favorito(obj) {
    this._favoritoService.favorito(this.token, obj.tipo, obj.id).subscribe(
      (response: any) => {
        if (response.status == 'success') {
          if (response.accion == 'agregado') {
            obj.favorito = obj.id;
          } else if (response.accion == 'eliminado') {
            obj.favorito = null;
          }
        } else {
          // ...
        }
      },
      (error: any) => {
        Swal.fire({
          title: 'UPS',
          icon: 'error',
          text: error.error.message
        });
      }
    );
  }

  experiencia(id) {
    let str = '';
    switch (id) {
      case 1:
        str = '1 Año';
        break;
      case 2:
        str = '2 Años';
        break;
      case 3:
        str = '3 Años';
        break;
      case 4:
        str = '+5 Años';
        break;
      default:
        str = id;
        break;
    }

    return str;
  }

  verHorarios(oferta) {
    this.oferta_selected = oferta;
    let modal = UIkit.modal("#modal-horarios");
    modal.show();
  }

  parse(str) {
    return JSON.parse(str);
  }
  checkAutonomo() {


    if (this.identity.role_user == 'CADIAN' && this.details.key_tipo_cadian != 1) {
      let modal = UIkit.modal("#modal-cambio");
      modal.show();
    } else {
      this.details.key_tipo_cadian = 2;
    }
  }
  public serAutonomo(): void {
    if (this.details.key_tipo_cadian == 1) {
      Swal.fire('UPS', 'Usted ya es un Cuidador Autónomo', 'error');
      return;
    }

    this._cadianService.serAutonomo(this.token).subscribe(
      (response: any) => {
        if (response.status == 'success') {
          this.details.ser_autonomo = 1;
          localStorage.setItem('details', JSON.stringify(this.details));

          if (this.details.fecha_autorizacion == null || this.details.fecha_autorizacion == '') {
            Swal.fire('¡Muy bien!', 'Su cuenta de Cuidador sera revisada en un lapso de 24-48 horas para verificar que esta cumpla con los requisitos.', 'success');
          } else {
            Swal.fire('¡Muy bien!', 'Su cuenta de cuidador ha cambiado a Autónomo.', 'success');
          }

          this.details.key_tipo_cadian = 1;
          this.details = response.changes;
          localStorage.setItem('details', JSON.stringify(this.details));
        }
      },
      (errorResponse: any) => {
        if (errorResponse.error.message == 'documentos') {
          this.details.ser_autonomo = 1;
          localStorage.setItem('details', JSON.stringify(this.details));

          this._router.navigate(['/perfil-cadian/documentos']);
          Swal.fire('Un momento', 'Necesitas subir tu comprobante de facturas y carta no antecedentes penales', 'info');
          let modal = UIkit.modal("#modal-cambio");
          modal.hide();
        } else {
          Swal.fire('¡UPS!', errorResponse.error.message, 'error');
        }
      }
    );
  }
}

