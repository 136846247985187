import { Component, OnInit } from '@angular/core';
import { FAQService } from 'src/app/services/FAQ.service';

@Component({
  selector: 'app-contactanos',
  templateUrl: './contactanos.component.html',
  styleUrls: ['./contactanos.component.css']
})
export class ContactanosComponent implements OnInit {

  public resultados;
  public categoria : number = 3;
  public loading = false;

  constructor(
    public _FAQService: FAQService
  ) { }

  ngOnInit() {
    this.getFAQByCategory();
  }

  getFAQByCategory(){
    this.loading = true;

    this._FAQService.getFAQByCategory(this.categoria).subscribe(
      response => {
        
        if(response.status == 'success'){
          this.loading = false;
          this.resultados = response.response;
        }

      },
      errorResponse => {
        console.log(errorResponse);
      }
    );
  }

}
