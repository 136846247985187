import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { Cadian } from 'src/app/models/cadian';
import { UserService } from 'src/app/services/user.service';
import { CadianService } from 'src/app/services/cadian.service';

@Component({
  selector: 'app-referencias',
  templateUrl: './referencias.component.html',
  styleUrls: ['./referencias.component.css']
})
export class ReferenciasComponent implements OnInit {

  public cadian : Cadian;
  public identity;
  public token;
  public details;
  public percent_progress;
  public referencias_personales;
  public referencias_laborales;


  constructor(
    public _userService: UserService,
    public _cadianService: CadianService,
  ) { 
    this.cadian = new Cadian(1,'','',1,1,1,'',1,'',1,1,'','','','','','','','',1,1,1,[],[],'','',[],[],0,[],'','',0,0,0,'',[],'','','',0,0);
    this.loadUser();
    this.getReferencias();
  }

  ngOnInit() {

    this.referencias_laborales = {
      'nombre_referencia1' : '',
      'telefono_referencia1' : '',
      'nombre_referencia2' : '',
      'telefono_referencia2' : '',
    }

    this.referencias_personales = {
      'nombre_referencia1' : '',
      'telefono_referencia1' : '',
      'nombre_referencia2' : '',
      'telefono_referencia2' : '',
    }

  }

  ngDoCheck(){
    
    
  }

  onSubmit(form){

    this.calcularBarra();
    
    if(this.cadian.check_autorizacion == 0 && this.percent_progress == 100){
      Swal.fire({
        title: 'Un momento',
        icon: 'info',
        text: 'Al aceptar estas afirmando que los datos ingresados son reales y que estos serán utilizados para procesar su depuración.',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aceptar'
      }).then((result) => {
        if (result.value) {
          this.cadian.check_autorizacion = 1;
          this.updateReferencias();
        }
      });
  }
  else{
    this.updateReferencias();
  }

  }

  public updateReferencias(){

    this._cadianService.updateReferencias(this.token, this.referencias_personales, this.referencias_laborales).subscribe(
      response => {
        
        if(response.status == 'success'){

          Swal.fire('Datos actualizados con éxito', response.message , 'success');
          
        }

      },
      errorResponse => {
        let errores = '';
        Object.keys(errorResponse.error.error).forEach(function(k){
          errores+= '- '+ errorResponse.error.error[k]+"<br>";
        });

        Swal.fire({
          title: 'UPS',
          icon: 'error',
          html: errores
        });
      }
    );

  }

  loadUser(){
    this.identity = this._userService.getIdentity();
    this.token = this._userService.getToken();
    this.details = this._userService.getDetails();

    //rellenar objeto cadian
    this.cadian = new Cadian(
      this.details.id_cadian, 
      this.details.nombre, 
      this.details.apellidos,
      this.details.key_provincia,
      this.details.key_municipio,
      this.details.key_sector,
      this.details.key_servicios,
      this.details.numero,
      this.details.direccion,
      this.details.key_tipo_cadian,
      this.details.key_genero,
      this.details.correo,
      this.details.telefono,
      this.details.foto,
      this.details.key_dni,
      this.details.foto_dni,
      this.details.foto_dni_comprobacion,
      this.details.key_formacion,
      this.details.key_idiomas,
      this.details.key_uso_pc,
      this.details.key_forma_pago,
      this.details.estatus,
      [],
      [],
      this.details.acerca_de_mi,
      this.details.puesto,
      [],
      [],
      this.details.check_autorizacion,
      [],
      this.details.carta_no_antecedentes,
      this.details.comprobante_factura,
      this.details.tarifa_hora,
      this.details.tarifa_hora2,
      this.details.tarifa_hora3,
      this.details.fecha_nacimiento,
      this.details.key_aptitudes,
      this.details.key_experiencia,
      this.details.facebook,
      this.details.instagram,
      this.details.tarifa_mensual,
      this.details.codigo_postal
      );
  }


  public calcularBarra(){
    let count = Object.keys(this.cadian).length;
    let inputs_vacios = 0;
    let noRequeridos = ['id', 'key_forma_pago', 'nombre','apellidos','correo','estatus','dias','horarios','servicios','certificados','key_servicios', 'check_autorizacion', 'key_dni', 'key_tipo_cadian','key_aptitudes','puesto','key_uso_pc','key_experiencia','key_idiomas','key_formacion', 'facebook', 'instagram', 'tarifa_mensual', 'key_forma_pago', 'key_sector'];
    Object.entries(this.cadian).forEach(([key, value]) => {
      if(noRequeridos.includes(key)){
        count--;
        return;
      }

      if((value == '' || value == null || value == undefined) && !Array.isArray(value)){
          inputs_vacios++;
      }
    });

    let porcentaje = 100/count;
    this.percent_progress = Math.ceil(porcentaje * (count - inputs_vacios)) >= 100 ? 100 : Math.ceil(porcentaje * (count - inputs_vacios));

  }

  public getReferencias(){

    //OBTENER REFERENCIAS DEL CADIAN
    this._cadianService.getReferencias(this.token).subscribe(
      response => {
        
        if(response.status == 'success'){

          if(response.referencias_laborales != null){
            this.referencias_laborales = response.referencias_laborales;
          }

          if(response.referencias_personales != null){
            this.referencias_personales = response.referencias_personales;
          }
          
        }

      },
      errorResponse => {
        let errores = '';
        Object.keys(errorResponse.error.error).forEach(function(k){
          errores+= '- '+ errorResponse.error.error[k]+"<br>";
        });

        Swal.fire({
          title: 'UPS',
          icon: 'error',
          html: errores
        });
      }
    );
  }
}
