import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { ReservaService } from 'src/app/services/reserva.service';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import * as UIkit from 'uikit';
import { Router } from '@angular/router';
import { VacantesService } from 'src/app/services/vacantes.service';
declare let Html5QrcodeScanner: any;
declare let Html5Qrcode: any;
import { SubastasService } from 'src/app/services/subastas.service';

@Component({
  selector: 'app-reservas',
  templateUrl: './reservas.component.html',
  styleUrls: ['./reservas.component.css']
})
export class ReservasComponent implements OnInit {
  public auctionCode: string = '';
  public selectedAuctionId: string = '';
  public identity;
  public token;
  public details;
  public reservas = [];
  faEllipsisH = faEllipsisH;
  public estatus : number = 4;
  public tarifa : number = 0;
  public token_identificacion;
  public rating;
  public comentario;
  public openedVacanciesFilter: string = '1';
  
  // public openedOffersFilter: string = '1';
  // public offers: any[] = [];
  public tipo = 'reserva';
  public offer;
  
  public offer_id;
  public offer_formated_id;
  public offer_titulo;
  public offer_descripcion;
  public offer_ciudad;
  public offer_fecha_inicio;
  public offer_fecha_fin;
  public offer_tarifa;
  public offer_foto;

  public vacanciesPaginationConfig: any = {
    current_page: 1,
    items_per_page: 0,
    total_items: 0
  };

  public currentGroup: string = 'inprocess';
  public vacancies: any[] = [];

  public diasList = [
    {dia: 'Lunes'},
    {dia:'Martes'},
    {dia:'Miércoles'},
    {dia:'Jueves'},
    {dia:'Viernes'},
    {dia:'Sábado'},
    {dia:'Domingo'}
  ];

  public activeTab: number = 1;

  constructor(
    public _userService: UserService,
    public _reservaService: ReservaService,
    private _router: Router,
    public _vacantesService: VacantesService,
    private _subastasService: SubastasService
  ) {
    this.loadUser();
   }

  ngOnInit() {
    this.getReservas();
    this.getOffers();
  }

  loadUser(){
    this.identity = this._userService.getIdentity();
    this.token = this._userService.getToken();
    this.details = this._userService.getDetails();
  }

  
  modalOffer(oferta_id, obj) {
    this.offer_id = oferta_id;
    this.offer_formated_id = obj.formated_id;
    this.offer_titulo = obj.titulo;
    this.offer_descripcion = obj.descripcion;
    this.offer_ciudad = obj.ciudad;
    this.offer_fecha_inicio = obj.fecha_oferta;
    this.offer_fecha_fin = obj.fecha_limite_destacado;
    this.offer_tarifa = obj.tarifa;
    this.offer_foto = obj.foto;
  }
  
  public onOpenedOffersFilterChange(option: string, status: string): void {
    switch (option) {
      case '1':
        this.tipo = 'reserva';
        this.getRandomVacancies('1', '1');
        break;
      case '2':
        this.tipo = 'reserva';
        this.getAuctionVacancies('1', status);
        break;
      case '3':
        this.tipo = 'trabajo';
        this.getWorkVacancies('1', '1');
        break;
    }
  }

  getOffers(){

    
    this.onOpenedOffersFilterChange('1', '1');
  
  
  }

  public getRandomVacancies(page: string, status: string): void {
    this._vacantesService.getUserRandomVacancies(page, status, this.currentGroup, this.token).subscribe(
      (response: any) => {
        this.vacanciesPaginationConfig.items_per_page = response.items_per_page;
        this.vacanciesPaginationConfig.total_items = response.total_items;
        this.vacancies = response.items;
      },
      (error: any) => {
        // ...
      }
    );
  }

  public getAuctionVacancies(page: string, status: string): void {
    this.vacancies = [];

    this._vacantesService.getCarerActiveAuctionOffer(page, status, this.currentGroup, this.token).subscribe(
      (response: any) => {
        this.vacanciesPaginationConfig.items_per_page = response.items_per_page;
        this.vacanciesPaginationConfig.total_items = response.total_items;
        this.vacancies = response.items;
        console.log('this.vacancies', this.vacancies);
      },
      (error: any) => {
        // ...
      }
    );
  }

  public getWorkVacancies(page: string, status: string): void {
    this._vacantesService.getUserWorkVacancies(page, status, this.currentGroup, this.token).subscribe(
      (response: any) => {
        this.vacanciesPaginationConfig.items_per_page = response.items_per_page;
        this.vacanciesPaginationConfig.total_items = response.total_items;
        this.vacancies = response.items;
      },
      (error: any) => {
        // ...
      }
    );
  }

  getReservas(estatus_selected = 0){

      if(estatus_selected != this.estatus){
        this.estatus = estatus_selected == 0 ? 4 : estatus_selected;
        this._reservaService.getReservasByUser(this.token, this.estatus).subscribe(
          response => {

            if(response.status == 'success'){
              this.reservas = response.reservas;
            }

          },
          errorResponse => {
            console.log(errorResponse);
          }
        );
    }
    
  }

  aceptarReserva1(reserva){
    //CALCULO DE HORAS
    const dateOneObj = new Date(reserva.fecha_reserva_inicio).getTime();
    const dateTwoObj = new Date(reserva.fecha_reserva_final).getTime();
    let hours = Math.abs(dateOneObj - dateTwoObj) / 36e5;

    Swal.mixin({
      input: 'number',
      confirmButtonText: 'Siguiente &rarr;',
      showCancelButton: true,
      progressSteps: ['1']
    }).queue([
      {
        title: 'Tarifa',
        text: 'Ingresa tu tarifa por hora para esta reserva.'
      }
    ]).then((result: any) => {
      if (result.value) {
        const tarifa = result.value[0];
        if(tarifa > 0){
          this.aceptarReserva2(reserva.token_identificacion, tarifa);
        }
      }
    })
  }

  aceptarReserva2(token_reserva, tarifa){
    if(this.identity.role_user == 'CADIAN'){
        let form = new FormData();
        form.append('token', token_reserva);
        form.append('tarifa', tarifa);
        this._reservaService.aceptarReserva(this.token, form).subscribe(
          response => {
            if(response.status == 'success'){
              Swal.fire('¡Muy bien!', response.message , 'success');
              //Recargamos las reservas
              this.getReservas();
            }
          },
          errorResponse => {
            console.log(errorResponse);
          }
        );
    }
  }

  pagarReserva(token_reserva){
    if(this.identity.role_user == 'CLIENTE'){
        let form = new FormData();
        form.append('token_identificacion', token_reserva);
        this._reservaService.pagarReserva(this.token, form).subscribe(
          response => {
            if(response.status == 'success'){
              Swal.fire('¡Muy bien!', response.message , 'success');
              this.getTokenQR(token_reserva);
              //Recargamos las reservas
              this.getReservas();
            }
          },
          errorResponse => {
            Swal.fire('¡UPS!', errorResponse.error.message , 'error');
          }
        );
    }
  }

  cancelarReserva(token_reserva){

    Swal.fire({
      title: '¿Estás seguro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Cancelar reserva!'
    }).then((result) => {
      if (result.value) {
        let form = new FormData();
        form.append('token', token_reserva);
            this._reservaService.cancelarReserva(this.token, form).subscribe(
              response => {
                if(response.status == 'success'){
                  Swal.fire('OK', response.message , 'success');
                  //Recargamos las reservas
                  this.getReservas();
                }
              },
              errorResponse => {
                console.log(errorResponse);
              }
            );
      }
    });

  }

  confirmarReserva(token_reserva){
        let form = new FormData();
        form.append('token', token_reserva);
        this._reservaService.aceptarReserva(this.token, form).subscribe(
          response => {
            if(response.status == 'success'){
              Swal.fire({
                title: 'Confirmación',
                icon: 'info',
                html:
                  'Escanea este <b>codigo QR</b><br>' +
                  response.qr+'<br>'+
                  'o haz click en este enlace para confirmar la reserva<br>'+
                  '<a href="http://localhost:4200/confirmacion/'+response.token+'">http://localhost:4200/confirmacion/'+response.token+'</a> ',
                showCloseButton: true,
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonText:
                  '<i class="fa fa-thumbs-up"></i> Great!',
                confirmButtonAriaLabel: 'Thumbs up, great!',
                cancelButtonText:
                  '<i class="fa fa-thumbs-down"></i>',
                cancelButtonAriaLabel: 'Thumbs down'
              })
            }
          },
          errorResponse => {
            console.log(errorResponse);
          }
        );
  }

  getTokenQR(token_identificacion){
      let form = new FormData();
      form.append('token_identificacion', token_identificacion);
      this._reservaService.TokenQR(this.token, form).subscribe(
        response => {
          if(response.status == 'success'){
            Swal.fire({
              title: 'Confirmación',
              icon: 'info',
              html:
                'Muestra este <b>codigo QR</b> para confirmar tu identidad con tu cuidador/cliente<br>' +
                response.qr+'<br>'+
                'o haz click en este enlace para confirmar la reserva<br>'+
                '<a href="http://localhost:4200/confirmacion/'+response.token+'">http://localhost:4200/confirmacion/'+response.token+'</a> ',
              showCloseButton: true,
              showCancelButton: true,
              focusConfirm: false,
              confirmButtonText:
                '<i class="fa fa-thumbs-up"></i> Great!',
              confirmButtonAriaLabel: 'Thumbs up, great!',
              cancelButtonText:
                '<i class="fa fa-thumbs-down"></i>',
              cancelButtonAriaLabel: 'Thumbs down'
            })
          }
        },
        errorResponse => {
          console.log(errorResponse);
        }
      );
  }

  opinar(){
    if(this.token_identificacion != undefined){

      let form = new FormData();
      form.append('token_identificacion', this.token_identificacion);
      form.append('rating', this.rating);
      form.append('comentario', this.comentario);

      this._reservaService.opinar(this.token, form).subscribe(
        response => {
          if(response.status == 'success'){
            Swal.fire('¡Muy bien!', response.message , 'success');
            let modal = UIkit.modal("#modal-comentario");
            modal.hide();
            //Recargamos las reservas
            this.getReservas();
          }
        },
        errorResponse => {
          Swal.fire('¡UPS!', errorResponse.error.message , 'error');
        }
      );
    }
  }

  irConversacion(token){
    this._router.navigate(['/mensajeria/'+token]);
  }

  buscarOtroCuidador(reserva){
    Swal.fire({
      title: '¿Estás seguro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Buscar otro cuidador'
    }).then((result) => {
      if (result.value) {
        let form = new FormData();
        form.append('token_reserva', reserva.token_identificacion);
            this._reservaService.buscarOtroCuidador(this.token, form).subscribe(
              response => {
                if(response.status == 'success'){
                  Swal.fire('OK', response.message , 'success');
                  //Recargamos las reservas
                  this.getReservas();
                }
              },
              errorResponse => {
                Swal.fire('¡UPS!', errorResponse.error.message , 'error');
              }
            );
      }
    });
  }


  public onVacanciesFilterChange(option: string, status: string): void {
    switch (option) {
      case '1':
        this.tipo = 'reserva';
        this.getRandomVacancies('1', '1');
        break;
      case '2':
        this.tipo = 'reserva';
        this.getAuctionVacancies('1', status);
        break;
      case '3':
        this.tipo = 'trabajo';
        this.getWorkVacancies('1', '1');
        break;
    }
  }

  public showAuctionStartCode(vacant: any): void {
    console.log(vacant);
    this.auctionCode = '';

    UIkit.modal("#modal_auction_code", {
      escClose: false,
      bgClose: false
    }).show();
  }

  public showAuctionEndCode(vacant: any): void {
    console.log(vacant);
    this.auctionCode = '';

    UIkit.modal("#modal_auction_code_end", {
      escClose: false,
      bgClose: false
    }).show();
  }

  public getAuctionStartCode(): void {
    this._subastasService.getAuctionStartCode(this.selectedAuctionId, this.token).subscribe(
      (response: any) => {
        this.auctionCode = response.code;
      },
      (erro: any) => {
        // ...
      }
    );
  }

  public getAuctionEndCode(): void {
    this._subastasService.getAuctionEndCode(this.selectedAuctionId, this.token).subscribe(
      (response: any) => {
        this.auctionCode = response.code;
      },
      (erro: any) => {
        // ...
      }
    );
  }
}
