import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params} from '@angular/router';
import { User } from '../../models/user';
import { UserService } from '../../services/user.service';
import Swal from 'sweetalert2';
// import { faUser, faKey } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public page_title: string;
  public user: User;
  public status: string;
  public token: string;
  public identity: any;
  public details: any;

  constructor(
    private userService: UserService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { 
    this.page_title = 'Identificate';
    this.user = new User(1, '', '', '', '', '', 2, '', '');
  }

  public ngOnInit(): void {
    this.logout();
  }

  public onSubmit(form): void {
    this.userService.login(this.user).subscribe(
      (response: any) => {
        if (response.status != 'error') {
          this.status = 'success';
          this.token = response;
          this.userService.login(this.user, true).subscribe(
            (response: any) => {
              this.identity = response;
              this.details = this.identity.details;
              localStorage.setItem('token', this.token);
              localStorage.setItem('identity', JSON.stringify(this.identity));
              localStorage.setItem('details', JSON.stringify(this.details));
              Swal.fire('Login exitoso...', 'Ingreso realizado con éxito', 'success');

              if (this.identity.role_user == 'CLIENTE') {
                this.userService.loginEvent.emit(true);
                this.router.navigate(['/perfil-cliente']);
              } else if (this.identity.role_user == 'CADIAN') {
                this.userService.loginEvent.emit(true);
                this.router.navigate(['/perfil-cadian']);
              } else {
                this.router.navigate(['/']);
              }
            },
            (error: any) => {
              this.status = 'error';
            }
          );
        } else {
          if (response.email_not_verified != undefined) {
            let msg;

            if (response.email_link != undefined && response.email_link != null) {
              msg = 'Por favor revisa la cuenta de correo con la que te registraste o da <a href="' + response.email_link + '" target="_blank" rel="noopener noreferrer">click aquí</a> y busca nuestro mensaje de bienvenida. Si no lo encuentras da click en el botón azul de abajo.';
            } else {
              msg = 'Por favor revisa la cuenta de correo con la que te registraste y busca nuestro mensaje de bienvenida. Si no lo encuentras da click en el botón azul de abajo.';
            }

            Swal.fire({
              icon: 'info',
              title: 'Cuenta no verificada',
              html: msg,
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
              showCancelButton: true,
              cancelButtonText: 'Aceptar',
              showConfirmButton: true,
              confirmButtonText: 'Reenviar correo'
            }).then((result) => {
              if (result.isConfirmed) {
                this.verifyEmailAgain(this.user.email);
              }
            });
          } else {
            Swal.fire(response.message, response.errors, 'error');
          }
        }
      },
      (error: any) => {
        this.status = 'error';
      }
    );
  }

  private verifyEmailAgain(email: string): void {
    Swal.fire({
      icon: 'info',
      title: 'Reenviando correo...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      onOpen: () => { Swal.showLoading(); }
    });

    this.userService.verifyEmailAgain(email).subscribe(
      (response: any) => {
        Swal.fire({
          icon: 'success',
          title: response.message,
          html: response.information,
          allowOutsideClick: false,
          allowEscapeKey: false,
          showConfirmButton: false,
          showCancelButton: false
        });
      },
      (error: any) => {
        if (error.error.email != undefined || error.error.user_not_found != undefined) {
          Swal.fire({
            icon: 'error',
            title: 'Correo no valido',
            html: 'El correo no es válido, por favor revísalo e intenta de nuevo',
            input: 'text',
            allowOutsideClick: false,
            allowEscapeKey: false,
            showConfirmButton: true,
            confirmButtonText: 'Continuar',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'
          }).then((result: any) => {
            if (result.isConfirmed) {
              this.verifyEmailAgain(result.value);
            }
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error desconocido',
            html: 'Algo salió mal. Por favor intenta de nuevo y si el problema sigue contacta a soporte.',
            allowOutsideClick: false,
            allowEscapeKey: false,
            showConfirmButton: true,
            confirmButtonText: 'Intentar de nuevo',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'
          }).then((result: any) => {
            if (result.isConfirmed) {
              this.verifyEmailAgain(email);
            }
          });
        }
      }
    );
  }

  public logout(): void {
    this.activatedRoute.params.subscribe((params: any) => {
      let logout = +params['sure'];

      if (logout == 1) {
        localStorage.removeItem('identity');
        localStorage.removeItem('token');
        localStorage.removeItem('details');
        this.identity = null;
        this.token = null;
        this.details = null;
        this.userService.loginEvent.emit(false);
        this.router.navigate(['/']);
      }
    });
  }
}
