import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from './shared/shared.module';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { routing, appRoutingProviders } from './app.routing';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UserLoggedGuard } from './guards/userLoggedGuard.service';
import { IsGuestGuard } from './guards/isGuestGuard.service';
import { IsCustomer } from './guards/isCustomer.service';
import { IsCadian } from './guards/isCadian.service';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxMaskModule } from 'ngx-mask';
import { AngularFileUploaderModule } from "angular-file-uploader";
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ProgressBarModule } from "angular-progress-bar"
import { RecaptchaModule } from 'angular-google-recaptcha';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { TagInputModule } from 'ngx-chips';
import { DpDatePickerModule } from 'ng2-date-picker';
import { ReadMoreModule } from 'ng-readmore';
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxStarRatingModule } from 'ngx-star-rating';
import { AgmCoreModule } from '@agm/core';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { HomeComponent } from './components/home/home.component';
import { ErrorComponent } from './components/error/error.component';
import { TiporegistroComponent } from './components/tiporegistro/tiporegistro.component';
import { RegistercadianComponent } from './components/registercadian/registercadian.component';
import { PerfilClienteComponent } from './components/perfil-cliente/perfil-cliente.component';
import { ContratosComponent } from './components/perfil-cliente/contratos/contratos.component';
import { MiCuentaComponent } from './components/perfil-cliente/mi-cuenta/mi-cuenta.component';
import { PerfilCadianComponent } from './components/perfil-cadian/perfil-cadian.component';
import { MiCuentaComponent2 } from './components/perfil-cadian/mi-cuenta/mi-cuenta.component';
import { CurriculumComponent } from './components/perfil-cadian/curriculum/curriculum.component';
import { RegistroClienteGuard } from './guards/registroClienteGuard';
import { RegistroCadianGuard } from './guards/registroCadianGuard';
import { RecuperarPasswordComponent } from './components/recuperar-password/recuperar-password.component';
import { ForgotComponent } from './components/forgot/forgot.component';
import { BuscarComponent } from './components/buscar/buscar.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DocumentosComponent } from './components/perfil-cadian/documentos/documentos.component';
import { DocumentosComponent2 } from './components/perfil-cliente/documentos/documentos.component';
import { ReferenciasComponent } from './components/perfil-cadian/referencias/referencias.component';
import { NuevaOfertaComponent } from './components/perfil-cliente/nueva-oferta/nueva-oferta.component';
import { VacantesComponent } from './components/vacantes/vacantes.component';
import { ClienteTradEmp } from './guards/ClienteTradEmp';
import { BalanceComponent } from './components/balance/balance.component';
import { RecargarBalanceComponent } from './components/balance/recargar-balance/recargar-balance.component';
import { ReservasComponent } from './components/reservas/reservas.component';
import { DetalleComponent } from './components/reservas/detalle/detalle.component';
import { PerfilPublicoComponent } from './components/perfil-publico/perfil-publico.component';
import { TransferirComponent } from './components/balance/transferir/transferir.component';
import { MisVacantesComponent } from './components/vacantes/mis-vacantes/mis-vacantes.component';
import { FeedClienteComponent } from './components/perfil-cliente/feed-cliente/feed-cliente.component';
import { TutorialesComponent } from './components/tutoriales/tutoriales.component';
import { ArticuloComponent } from './components/tutoriales/articulo/articulo.component';
import { FinanzasComponent } from './components/finanzas/finanzas.component';
import { HistorialComponent } from './components/historial/historial.component';
import { FeedCadianComponent } from './components/perfil-cadian/feed-cadian/feed-cadian.component';
import { ConfirmacionReservaComponent } from './components/confirmacion-reserva/confirmacion-reserva.component';
import { MensajeriaComponent } from './components/mensajeria/mensajeria.component';
import { MisPostulacionesComponent } from './components/perfil-cadian/mis-postulaciones/mis-postulaciones.component';
import { FavoritosComponent } from './components/favoritos/favoritos.component';
import { ComoFuncionaComponent } from './components/como-funciona/como-funciona.component';
import { BlogComponent } from './components/blog/blog.component';
import { ArticuloBlogComponent } from './components/blog/articulo-blog/articulo-blog.component';
import { ApprovedComponent } from './components/payment/approved/approved.component';
import { ContactanosComponent } from './components/contactanos/contactanos.component';
import { CanceledComponent } from './components/payment/canceled/canceled.component';
import { DeclinedComponent } from './components/payment/declined/declined.component';
import { SoporteComponent } from './components/soporte/soporte.component';
import { SubastaComponent } from './components/subasta/subasta.component';
import { MembresiasComponent } from './components/membresias/membresias.component';
import { ConfigService } from './services/config.service';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AddressComponent } from './shared/components/address/address.component';
import { ChatComponent } from './components/chat/chat.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CountdownModule } from "ng2-date-countdown";
import { IConfig } from 'ngx-mask';
export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};
import { QRCodeModule } from 'angularx-qrcode';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    HomeComponent,
    ErrorComponent,
    TiporegistroComponent,
    RegistercadianComponent,
    PerfilClienteComponent,
    ContratosComponent,
    MiCuentaComponent,
    PerfilCadianComponent,
    MiCuentaComponent2,
    CurriculumComponent,
    RecuperarPasswordComponent,
    ForgotComponent,
    BuscarComponent,
    DocumentosComponent,
    DocumentosComponent2,
    ReferenciasComponent,
    NuevaOfertaComponent,
    VacantesComponent,
    BalanceComponent,
    RecargarBalanceComponent,
    ReservasComponent,
    DetalleComponent,
    PerfilPublicoComponent,
    TransferirComponent,
    MisVacantesComponent,
    FeedClienteComponent,
    TutorialesComponent,
    ArticuloComponent,
    FinanzasComponent,
    HistorialComponent,
    FeedCadianComponent,
    ConfirmacionReservaComponent,
    MensajeriaComponent,
    MisPostulacionesComponent,
    FavoritosComponent,
    ComoFuncionaComponent,
    BlogComponent,
    ArticuloBlogComponent,
    ApprovedComponent,
    ContactanosComponent,
    CanceledComponent,
    DeclinedComponent,
    SoporteComponent,
    SubastaComponent,
    MembresiasComponent,
    AddressComponent,
    ChatComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    routing,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ImageCropperModule,
    NgxMaskModule.forRoot(options),
    AngularFileUploaderModule,
    NgMultiSelectDropDownModule.forRoot(),
    ProgressBarModule,
    NgxPaginationModule,
    FontAwesomeModule,
    RecaptchaModule.forRoot({
      siteKey: '6LeUys8UAAAAADJAoLwiQUhP4Km7gEWlaRy9C-LI',
    }),
    FileUploadModule,
    TagInputModule,
    DpDatePickerModule,
    NgxStarRatingModule,
    ReadMoreModule,
    NgxSpinnerModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCMqV_m5Ss3bXEnbzumYLiOLoD6-a5SpNE',
      libraries: ['places']
    }),
    LoadingBarModule,
    GooglePlaceModule,
    InfiniteScrollModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    NgbModule,
    CountdownModule,
    QRCodeModule
  ],
  providers: [
    appRoutingProviders,
    UserLoggedGuard,
    IsGuestGuard,
    IsCustomer,
    IsCadian,
    PerfilCadianComponent,
    RegistroClienteGuard,
    RegistroCadianGuard,
    ClienteTradEmp,
    ConfigService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
