import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { VacantesService } from 'src/app/services/vacantes.service';
import { ClienteService } from 'src/app/services/cliente.service';
import { PostulacionesService } from 'src/app/services/postulaciones.service';
import { SubastasService } from 'src/app/services/subastas.service';
import Swal from 'sweetalert2';

@Component({
	selector: 'app-mis-postulaciones',
	templateUrl: './mis-postulaciones.component.html',
	styleUrls: ['./mis-postulaciones.component.css']
})
export class MisPostulacionesComponent implements OnInit {
	public identity;
	public token;
	public details;
	public postulaciones = [];
	public oferta_id;
	public tipo = 'trabajo';
	public loader: boolean = true;
	public vacanciesFilter: string = '1';
	public gettingPostulations: boolean = false;

	public auctionPostulationsPagConfig: any = {
		total_items: 0,
		items_per_page: 0,
		current_page: 1
	};

	public postulations: any[] = [];

	constructor(
		public userService: UserService,
		public postulacionesService: PostulacionesService,
		private subastasService: SubastasService
	) {
		this.loadUser();
	}

	public ngOnInit(): void {
		this.getPostulaciones();
		// this.getPostulationsByFilter();
	}

	public loadUser(): void {
		this.identity = this.userService.getIdentity();
		this.token = this.userService.getToken();
		this.details = this.userService.getDetails();
	}

	public getPostulaciones(): void {
		this.postulacionesService.getPostulaciones(this.token).subscribe(
			(response: any) => {
				if (response.status == 'success') {
					this.postulaciones = response.postulaciones;
					this.loader = false;
				}
			},
			(error: any) => {
				// ...
			}
		);
	}

	public changeTipo(tipo: any): void {
		this.tipo = tipo;
		this.loader = true;
	}

	// ---------------------------------------------------------------------------

	public auctionPostulationsPagChange(page: string, vacanciesFilter: any): void {
		vacanciesFilter.scrollIntoView({block: "center", behavior: "smooth"});
		this.getPostulationsByFilter();
	}

	public getAuctionPostulations(page: string): void {
		this.postulacionesService.getCadianAuctionPostulations(page, this.token).subscribe(
			(response: any) => {
				this.auctionPostulationsPagConfig.items_per_page = response.per_page;
				this.auctionPostulationsPagConfig.total_items = response.total_items;
				this.postulations = response.items;
				this.gettingPostulations = false;
			},
			(error: any) => {
				this.gettingPostulations = false;
			}
		);
	}

	public getWorkPostulations(page: string): void {
		this.postulacionesService.getCadianWorkPostulations(page, this.token).subscribe(
			(response: any) => {
				this.auctionPostulationsPagConfig.items_per_page = response.per_page;
				this.auctionPostulationsPagConfig.total_items = response.total_items;
				this.postulations = response.items;
				this.gettingPostulations = false;
			},
			(error: any) => {
				this.gettingPostulations = false;
			}
		);
	}

	public getAllPostulations(page: string): void {
		this.postulacionesService.getAllCadianPostulations(page, this.token).subscribe(
			(response: any) => {
				this.auctionPostulationsPagConfig.items_per_page = response.per_page;
				this.auctionPostulationsPagConfig.total_items = response.total_items;
				this.postulations = response.items;
				this.gettingPostulations = false;
			},
			(error: any) => {
				this.gettingPostulations = false;
			}
		);
	}

	public onVacanciesFilterChange(): void {
		this.getPostulationsByFilter();
	}

	public getPostulationsByFilter(): void {
		this.gettingPostulations = true;
		this.postulations = [];

		switch (this.vacanciesFilter) {
			case '1':
				this.getAllPostulations('1');
				break;
			case '2':
				this.getAuctionPostulations('1');
				break;
			case '3':
				this.getWorkPostulations('1');
				break;
		}
	}

	public sendFare(maxFare: string, auctionToken: string): void {
		Swal.fire({
			icon: 'info',
			title: `Tarifa maxima RD $${maxFare}`,
			html: 'Recuerda que esta es una subasta y mientras menor sea tu oferta tendrás mayores posibilidades de ser elegido(a).',
			input: 'text',
			allowOutsideClick: false,
			allowEscapeKey: false,
			allowEnterKey: false,
			showCancelButton: true,
			cancelButtonText: 'Cancelar',
			confirmButtonText: 'Enviar'
		}).then((result: any) => {
			if (result.isConfirmed) {
				this.sendAuctionBid(result.value, auctionToken);
			}
		});
	}

	private sendAuctionBid(fare: string, auctionToken: string): void {
		let form = new FormData();
		form.append('tarifa', fare);
		form.append('token_subasta', auctionToken);

		this.subastasService.ofertar(this.token, form).subscribe(
			(response: any) => {
				if(response.status == 'success'){
					Swal.fire('¡Muy bien!', response.message, 'success');
				}
			},
			(error: any) => {
				Swal.fire('¡UPS!', error.error.message , 'error');
			}
		);
	}
}
