import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../models/user';
import { global } from './global';

@Injectable({
    providedIn: 'root'
})

export class OfertaService{

    public url: string;

    constructor(
        public _http: HttpClient
    ){
        this.url = global.url;
    }

    ofertas(token, form): Observable<any>{
        let headers = new HttpHeaders().set('Authorization', token);
        
        return this._http.post(this.url+'user/ofertas', form, {headers: headers});
    }

    editarOferta(token, form): Observable<any>{
        let headers = new HttpHeaders().set('Authorization', token);
        
        return this._http.post(this.url+'user/editar-oferta-trabajo', form, {headers: headers});
    }

    editarOfertaReserva(token, form): Observable<any>{
        let headers = new HttpHeaders().set('Authorization', token);
        
        return this._http.post(this.url+'user/editar-oferta-reserva', form, {headers: headers});
    }

    updateRecibirSolicitudes(token, form): Observable<any>{
        let headers = new HttpHeaders().set('Authorization', token);
        
        return this._http.post(this.url+'user/oferta-recibir-solicitudes', form, {headers: headers});
    }

}