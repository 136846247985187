import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../models/user';
import { global } from './global';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    public url: string;
    public identity: any;
    public token: any;
    public details: any;
    public cadianStorage: any;
    public sharedStorage: any;
    public loginEvent: EventEmitter<any> = new EventEmitter();
    public detailsChangeEvent: EventEmitter<any> = new EventEmitter();

    constructor(public httpClient: HttpClient) {
        this.url = global.url;
    }

    public register(user): Observable<any> {
        let json = JSON.stringify(user);
        let params = 'json=' + json;
        let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        return this.httpClient.post(this.url + 'register', params, {headers: headers});
    }

    public registerCadian(user): Observable<any> {
        let json = JSON.stringify(user);
        let params = 'json=' + json;
        let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        return this.httpClient.post(this.url + 'register-cadian', params, {headers: headers});
    }

    public login(user, gettoken = null): Observable<any> {
        if (gettoken != null) {
            user.gettoken = 'true';
        }

        let json = JSON.stringify(user);
        let params = 'json=' + json;
        let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        return this.httpClient.post(this.url + 'login', params, {headers: headers});
    }

    public updateImageProfile(token, formData: any): Observable<any> {
        let headers = new HttpHeaders().set('Authorization', token);
        return this.httpClient.post(this.url + 'user/upload-image-profile', formData, {headers: headers});
    }

    public updateImageCedula(token, formData: any): Observable<any> {
        let headers = new HttpHeaders().set('Authorization', token);
        return this.httpClient.post(this.url + 'user/upload-image-cedula', formData, {headers: headers});
    }

    public getIdentity(): any {
        let identity = JSON.parse(localStorage.getItem('identity'));

        if (identity && identity != 'undefined') {
            this.identity = identity;
        } else {
            this.identity = null;
        }

        return this.identity;
    }

    public getDetails(): any {
        let details = JSON.parse(localStorage.getItem('details'));

        if (details && details != 'undefined') {
            this.details = details;
        } else {
            this.details = null;
        }

        return this.details;
    }

    public getToken(): void {
        let token = localStorage.getItem('token');

        if (token && token != 'undefined') {
            this.token = token;
        } else {
            this.token = null;
        }

        return this.token;
    }

    public recuperarPassword(form): Observable<any> {
        return this.httpClient.post(this.url + 'forgot', form);
    }

    public checkTokenForgot(form): Observable<any> {
        return this.httpClient.post(this.url + 'check-token-forgot', form);
    }

    public cambiarPassword(form): Observable<any> {
        return this.httpClient.post(this.url + 'cambiar-password', form);
    }

    public enviarInvitacion(token, form): Observable<any> {
        let headers = new HttpHeaders().set('Authorization', token);
        return this.httpClient.post(this.url + 'user/invitacion', form, {headers: headers});
    }

    public updateOnline(token, form): Observable<any> {
        let headers = new HttpHeaders().set('Authorization', token);
        return this.httpClient.post(this.url + 'user/update-online', form, {headers: headers});
    }

    public isOnline(user, token): Observable<any> {
        let headers = new HttpHeaders().set('Authorization', token);
        return this.httpClient.get(this.url + 'is-online/' + user, {headers: headers});
    }

    public getNotificaciones(token, offset = 0, reload = false, onlyUnread): Observable<any> {
        let headers = new HttpHeaders().set('Authorization', token);
        return this.httpClient.get(this.url + 'user/notificaciones/' + offset + '/' + reload + '/' + onlyUnread, {headers: headers});
    }

    public getRandomReservaNotifData(referenceID, token): Observable<any> {
        let headers = new HttpHeaders().set('Authorization', token);
        return this.httpClient.get(this.url + 'get-random-reserva-notification-data?token_referencia=' + referenceID, {headers: headers});
    }

    public readNotification(token): Observable<any> {
        let headers = new HttpHeaders().set('Authorization', token);
        return this.httpClient.put(this.url + 'user/read-notificaciones', null, {headers: headers});
    }

    public agregarTarjeta(token, card): Observable<any> {
        let json = JSON.stringify(card);
        let params = 'json=' + json;
        let headers = new HttpHeaders().set('Authorization', token);
        return this.httpClient.post(this.url + 'user/agregar-tarjeta', params, {headers: headers});
    }

    public checkSession(token): Observable<any> {
        let headers = new HttpHeaders().set('Authorization', token);
        return this.httpClient.get(this.url + 'user/check-session', {headers: headers});
    }

    public getUserData(token): Observable<any> {
        let headers = new HttpHeaders().set('Authorization', token);
        return this.httpClient.get(this.url + 'user/data', {headers: headers});
    }

    public accesoSoporte(token): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        return this.httpClient.post(this.url + 'acceso-soporte/' + token, token, {headers: headers});
    }

    public getSoporteTokenSesion(token): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        return this.httpClient.post(this.url + 'token-soporte/' + token, token, {headers: headers});
    }

    public sendRecoveryPasswordEmail(email: string): Observable<any> {
        return this.httpClient.post<any>(this.url + 'user/password/send-recovery-email', {email: email});
    }

    public verifyUserEmail(token: string): Observable<any> {
        return this.httpClient.get(this.url + 'user/email/verify-nrv?token=' + token);
    }

    public getCadianDocumentsNames(token: string): Observable<any> {
        return this.httpClient.get(
            this.url + 'user/cadian/documents/names',
            {headers: new HttpHeaders().set('Authorization', token)}
        );
    }

    public getClientDocumentsNames(token: string): Observable<any> {
        return this.httpClient.get(
            this.url + 'user/client/documents/names',
            {headers: new HttpHeaders().set('Authorization', token)}
        );
    }

    public getEmailsDomainsData(): Observable<any> {
        return this.httpClient.get(this.url + 'emails/domains/data');
    }

    public verifyEmailAgain(email: string): Observable<any> {
        return this.httpClient.get(this.url + 'user/email/verify/send-email-again?email=' + email);
    }
}