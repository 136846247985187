import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { global } from './global';

@Injectable({providedIn: 'root'})
export class PostulacionesService {
  public url: string;

  constructor(
    private httpClient: HttpClient
  ) {
    this.url = global.url;
  }

  public getPostulaciones(token): Observable<any> {
    let headers = new HttpHeaders().set('Authorization', token);
    return this.httpClient.get(this.url+'user/mis-postulaciones',{headers: headers});
  }

  public subirDocumentos(token, formData: any): Observable<any> {
    let headers = new HttpHeaders().set('Authorization', token);
    return this.httpClient.post(this.url+'user/documentos-postulacion', formData, {headers: headers});
  }

  public getCadianAuctionPostulations(page: string, token: string): Observable<any> {
    return this.httpClient.get(
      this.url + 'user/auctions/postulations?page=' + page,
      {headers: new HttpHeaders().set('Authorization', token)}
    );
  }

  public getCadianWorkPostulations(page: string, token: string): Observable<any> {
    return this.httpClient.get(
      this.url + 'user/work/postulations?page=' + page,
      {headers: new HttpHeaders().set('Authorization', token)}
    );
  }

  public getAllCadianPostulations(page: string, token: string): Observable<any> {
    return this.httpClient.get(
      this.url + 'user/cadian/postulations?page=' + page,
      {headers: new HttpHeaders().set('Authorization', token)}
    );
  }
}
