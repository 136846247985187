import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { global } from './global';

@Injectable({
  providedIn: 'root'
})
export class MensajeriaService {

  public url: string;

  constructor(
    private _http: HttpClient
  ) { 

    this.url = global.url;

  }

  checkToken(token, token_conversacion):Observable<any>{
    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                    .set('Authorization', token);
    return this._http.get(this.url+'user/mensajeria/'+token_conversacion,{headers: headers});
  }

  enviarMensaje(token, form): Observable<any>{
    let headers = new HttpHeaders().set('Authorization', token);
    
    return this._http.post(this.url+'enviar-mensaje', form, {headers: headers});
  }
}
