import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { global } from './global';

@Injectable({
  providedIn: 'root'
})
export class VacantesService {

  public url: string;

  constructor(
    private _http: HttpClient
  ) { 
    this.url = global.url;
  }

  getMisVacantes(token, tipo):Observable<any>{
    let headers = new HttpHeaders().set('Authorization', token);
    return this._http.get(this.url+'user/mis-vacantes/'+tipo, {headers: headers});
  }

  buscar(token, form: any):Observable<any>{
    let headers = new HttpHeaders().set('Authorization', token);
    
    return this._http.post(this.url+'user/busqueda-tutorial', form, {headers: headers});
  }

  articulo(token, slug):Observable<any>{
    let headers = new HttpHeaders().set('Authorization', token);
    return this._http.get(this.url+'user/tutoriales/'+slug, {headers: headers});
  }

  delete(token,oferta): Observable<any>{
    let json = JSON.stringify(oferta);
    let params = 'json='+json;

    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                   .set('Authorization', token);

    return this._http.put(this.url+'user/delete-oferta', params, {headers: headers});
  }

  // --------------------------------------------------------

  public getUserRandomVacancies(page: string, status: string, group: string, token: string): Observable<any> {
    return this._http.get(
      this.url + 'user/vacancies/random',
      {
        params: new HttpParams({fromObject: {page: page, status: status, group: group}}),
        headers: new HttpHeaders().set('Authorization', token)
      }
    );
  }

  public getUserAuctionVacancies(page: string, status: string, group: string, token: string): Observable<any> {
    return this._http.get(
      this.url + 'user/vacancies/auction',
      {
        params: new HttpParams({fromObject: {page: page, status: status, group: group}}),
        headers: new HttpHeaders().set('Authorization', token)
      }
    );
  }

  public getCarerActiveAuctionOffer(page: string, status: string, group: string, token: string): Observable<any> {
    return this._http.get(
      this.url + 'user/vacancies/active-auction',
      {
        params: new HttpParams({fromObject: {page: page, status: status, group: group}}),
        headers: new HttpHeaders().set('Authorization', token)
      }
    );
  }

  public getUserWorkVacancies(page: string, status: string, group: string, token: string): Observable<any> {
    return this._http.get(
      this.url + 'user/vacancies/work',
      {
        params: new HttpParams({fromObject: {page: page, status: status, group: group}}),
        headers: new HttpHeaders().set('Authorization', token)
      }
    );
  }

  public getOfferPostulants(idOffer: string, token: string): Observable<any> {
    return this._http.get(
      this.url + 'user/vacancies/postulations?id_offer=' + idOffer,
      {headers: new HttpHeaders().set('Authorization', token)}
    );
  }

  public getSelectedOfferPostulant(idOffer: string, token: string): Observable<any> {
    return this._http.get(
      this.url + 'user/vacancies/auctions/postulant?id_offer=' + idOffer,
      {headers: new HttpHeaders().set('Authorization', token)}
    );
  }

  public cancelAuction(idAuction: string, token: any): Observable<any> {
    return this._http.get(
      this.url + 'user/vacancies/auctions/cancel?id_offer=' + idAuction,
      {headers: new HttpHeaders().set('Authorization', token)}
    );
  }
}
