import { Component, OnInit } from '@angular/core';
import { UserService } from './services/user.service';
import { Router, NavigationEnd } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { filter } from 'rxjs/operators';
import { ConfigService } from './services/config.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  public identity: any;
  public token: any;
  public details: any;
  private timeoutCounter = 60000;
  private isLogin = false;

  constructor(
    private userService: UserService,
    private router: Router,
    private ngxSpinnerService: NgxSpinnerService,
    private configService: ConfigService
  ) {
    this.getConfig();
    this.loadUser();

    if (this.userService.getToken() != null) {
      /* setInterval(() => {
        this.updateOnline();
        this.checkSession();
      }, 60000); */

      this.updateOnline();
      this.checkSession();
    }

    this.userService.loginEvent.subscribe(isLogin => {
      this.isLogin = isLogin;

      if (isLogin) {
        this.loadUser();
        this.getConfig();
        this.updateOnline();
        this.checkSession();
      } else {
        this.resetMainVariables();
      }
    });
  }

  private resetMainVariables() {
    this.identity = null;
    this.token = null;
    this.details = null;
  }

  ngOnInit() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => window.scroll(0, 0));
    this.ngxSpinnerService.show();

    setTimeout(() => {
      this.ngxSpinnerService.hide();
    }, 1000);
  }

  loadUser() {
    this.identity = this.userService.getIdentity();
    this.token = this.userService.getToken();
    this.details = this.userService.getDetails();
  }

  updateOnline() {
    if (this.isLogin) {
      let form = new FormData();
      form.append('id_user', this.identity.sub);

      this.userService.updateOnline(this.token, form).subscribe(
        response => {
          if (this.isLogin) {
            // ______
            //|      |
            //| NOTE |
            //|______|
            // Remember replace the following [setTimeout()] with websockets.
            setTimeout(() => {
              this.updateOnline();
            }, this.timeoutCounter);
          }
        },
        errorResponse => {
          //...
        }
      );
    }
  }

  checkSession() {
    if (this.userService.getToken() != null) {
      this.userService.checkSession(this.token).subscribe(
        response => {
          if (this.isLogin) {
            // ______
            //|      |
            //| NOTE |
            //|______|
            // Remember replace the following [setTimeout()] with websockets.
            setTimeout(() => {
              this.checkSession();
            }, this.timeoutCounter);
          }
        },
        errorResponse => {
          if (errorResponse.status == 401) {
            localStorage.removeItem('identity');
            localStorage.removeItem('token');
            localStorage.removeItem('details');
            this.identity = null;
            this.token = null;
            this.details = null;
            this.router.navigate(['/']);
          }
        }
      );
    }
  }

  getConfig() {
    this.configService.getConfig().subscribe(
      response => {
        this.configService.setRegisterEnabled(parseInt(response.config.register_enabled));
      },
      errorResponse => {
        //...
      }
    );
  }
}