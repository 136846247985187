import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { global } from './global';

@Injectable({
  providedIn: 'root'
})
export class IdiomasService {

    public url: string;
  
    constructor(
      private _http: HttpClient
    ) { 
  
      this.url = global.url;
  
    }

    getIdiomas():Observable<any>{

      let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
  
      return this._http.get(this.url+'idiomas',{headers: headers});
  
    }

    getIdiomasByUser(token):Observable<any>{

        let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                        .set('Authorization', token);
    
        return this._http.get(this.url+'user/idiomas',{headers: headers});
    
      }
  }