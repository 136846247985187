import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { FinanzasService } from 'src/app/services/finanzas.service';
import { CadianService } from 'src/app/services/cadian.service';
import Swal from 'sweetalert2';
import { faDotCircle } from '@fortawesome/free-solid-svg-icons';
import * as UIkit from 'uikit';
import { BancosService } from 'src/app/services/bancos.service';
import { TipoCuentasBancariasService } from 'src/app/services/tipo_cuentas_bancarias.service';
import { CuentasBancariasService } from 'src/app/services/cuentas_bancarias.service';

@Component({
  selector: 'app-finanzas',
  templateUrl: './finanzas.component.html',
  styleUrls: ['./finanzas.component.css']
})
export class FinanzasComponent implements OnInit {

  public identity;
  public token;
  public details;
  public historial = [];
  public years_card = [];
  public new_card = {
    nombre_titular : '',
    numero_tarjeta: '',
    mes_vencimiento: '',
    ano_vencimiento: ''
  }
  faDotCircle = faDotCircle;
  public bancos;
  public tipos;
  public cuentas: any = [];
  public banco_selected;
  public tipo_selected;
  public nombre;
  public numero_cuenta;
  public id = 0;

  constructor(
    public _userService: UserService,
    public _finanzasService: FinanzasService,
    public _cadianService: CadianService,
    public _bancosService: BancosService,
    public _tipoCuentasService: TipoCuentasBancariasService,
    public _cuentasBancariasService: CuentasBancariasService
  ) {
    this.loadUser();
   }

  ngOnInit() {
    this.getHistorial();
    this.getBancos();
    this.getCuentas();
    this.getTipos();
  }

  loadUser(){
    this.identity = this._userService.getIdentity();
    this.token = this._userService.getToken();
    this.details = this._userService.getDetails();
  }

  getHistorial(){
    this._finanzasService.getBalanceGenerado(this.token).subscribe(
      response => {
        if(response.status == 'success'){
          this.historial = response.historial;
        }
      },
      errorResponse => {
        console.log(errorResponse);
      }
    );
  }

  getBancos(){
    this._bancosService.getBancos().subscribe(
      response => {
        if(response.status == 'success'){
          this.bancos = response.bancos;
        }
      },
      errorResponse => {
        console.log(errorResponse);
      }
    );
  }

  getTipos(){
    this._tipoCuentasService.getTipos().subscribe(
      response => {
        if(response.status == 'success'){
          this.tipos = response.tipos;
        }
      },
      errorResponse => {
        console.log(errorResponse);
      }
    );
  }

  getCuentas(){
    this._cuentasBancariasService.getCuentas(this.token).subscribe(
      response => {
        if(response.status == 'success'){
          this.cuentas = response.cuentas;
          if(this.cuentas.length > 0){
            this.id = this.cuentas[0].id;
            this.nombre = this.cuentas[0].nombre;
            this.numero_cuenta = this.cuentas[0].numero;
            this.banco_selected = this.cuentas[0].key_banco;
            this.tipo_selected  = this.cuentas[0].key_tipo_cuenta;
          }
        }
      },
      errorResponse => {
        console.log(errorResponse);
      }
    );
  }

  
  agregarMetodoPago(){
    this._userService.agregarTarjeta(this.token, this.new_card).subscribe(
      response => {

        if(response.status == 'success'){
        }

      },
      errorResponse => {
        console.log(errorResponse);
      }
    );
  }

  serAutonomo(){

    if(this.details.key_tipo_cadian == 1){
      Swal.fire('UPS', 'Usted ya es un Cadian Autónomo' , 'error');
      return;
    }

    this._cadianService.serAutonomo(this.token).subscribe(
      response => {

        if(response.status == 'success'){

          Swal.fire('¡Muy bien!', 'Su cuenta de cuidador sera revisada en un lapso de 24 horas para verificar que esta cumpla con los requisitos.' , 'success');

          this.details.key_tipo_cadian = 1;
          //ACTUALIZAMOS LOCALSTORAGE
          this.details = response.changes;
          localStorage.setItem('details', JSON.stringify(this.details));

        }

      },
      errorResponse => {
        console.log(<any>errorResponse);
      }
    );

  }

  checkAutonomo($event){
    const checked = $event.target.checked;
    if(checked && this.identity.role_user == 'CADIAN' && this.details.key_tipo_cadian != 1){
      let modal = UIkit.modal("#modal-cambio");
      modal.show();
    }
  }

  update(){
    let form = new FormData();
    form.append('id', this.id.toString());
    form.append('nombre', this.nombre);
    form.append('numero', this.numero_cuenta);
    form.append('banco', this.banco_selected);
    form.append('tipo', this.tipo_selected);

    this._cuentasBancariasService.updateCuenta(this.token, form).subscribe(
      response => {
        if(response.status == 'success'){
          this.id = response.id;
          Swal.fire('¡Muy Bien!', response.message , 'success');
        }
      },
      errorResponse => {
        let errores = '';
        Object.keys(errorResponse.error.error).forEach(function(k){
          errores+= '- '+ errorResponse.error.error[k]+"<br>";
        });

        Swal.fire({
          title: 'UPS',
          icon: 'error',
          html: errores
        });
      }
    );
  }

}
