import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-canceled',
	templateUrl: './canceled.component.html',
	styleUrls: ['./canceled.component.css']
})
export class CanceledComponent implements OnInit {
	constructor() {
		// ...
	}

	public ngOnInit(): void {
		// ...
	}
}
