import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { global } from './global';

@Injectable({
  providedIn: 'root'
})
export class DesbloqueoCuidadorService {

  public url: string;

  constructor(
    private _http: HttpClient
  ) { 

    this.url = global.url;

  }

    checkDesbloqueo(token, formData: any): Observable<any>{

        let headers = new HttpHeaders().set('Authorization', token);

        return this._http.post(this.url+'user/check-desbloqueo', formData, {headers: headers});
    }
}
