import { Component, OnInit, ViewEncapsulation, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { Cliente } from 'src/app/models/cliente';
import { ClienteService } from 'src/app/services/cliente.service';
import { AddressComponent } from '../../../shared/components/address/address.component';
import Swal from 'sweetalert2';
declare let $: any;

@Component({
	selector: 'app-mi-cuenta',
	templateUrl: './mi-cuenta.component.html',
	styleUrls: ['../perfil-cliente.component.css'],
	encapsulation: ViewEncapsulation.None
})
export class MiCuentaComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild('addressComponent', {static: false}) private addressComponent: AddressComponent;
	public cliente: Cliente;
	private identity: any;
	private token: any;
	private details: any;
	private municipios: any;
	private provincias: any;
	private distritos: any;
	private sectores: any;
	public password: any;
	public password2: any;
	public currentPassword: any;
	private fotos_dni: any;
	private percent_progress: any;
	private uploadedFiles: Array<File> = [];

	// The following variable ("requiredFields") is used to verify the profile
	// progress percent of the client. The "requiredFields.property.field" is
	// just an indentifier and it can be any string value.
	private requiredFields: any = {
		// Client data
		profile_img: {field: 'cliente.foto', filled: false},
		gender: {
			field: 'cliente.key_genero',
			filled: false,
			popover_content: '#gender_required_popover_content',
			popover_to: '#gender_required_field'
		},
		about_me: {
			field: 'cliente.acerca_de_mi',
			filled: false,
			popover_content: '#aboutme_required_popover_content',
			popover_to: '#msg_aboutme2'
		},

		// Client address
		street: {
			field: 'addressData.street',
			filled: false,
			popover_content: '#address_required_popover_content',
			popover_to: '#google_maps_address2'
		},
		outside_number: {
			field: 'addressData.outsideNumber',
			filled: false,
			popover_content: '#address_required_popover_content',
			popover_to: '#google_maps_address2'
		},
		sector: {
			field: 'addressData.sector',
			filled: false,
			popover_content: '#address_required_popover_content',
			popover_to: '#google_maps_address2'
		},
		city: {
			field: 'addressData.city',
			filled: false,
			popover_content: '#address_required_popover_content',
			popover_to: '#google_maps_address2'
		},
		province: {
			field: 'addressData.province',
			filled: false,
			popover_content: '#address_required_popover_content',
			popover_to: '#google_maps_address2'
		},
		telephone: {
			field: 'cliente.telefono',
			filled: false,
			popover_content: '#address_required_popover_content',
			popover_to: '#google_maps_address2'
		},

		// Client documents
		dni_img: {field: 'cliente.foto_dni', filled: false},
		dni_img_verify: {field: 'cliente.foto_dni_comprobacion', filled: false}
	};

	constructor(
		private userService: UserService,
		private clienteService: ClienteService
	) {
		this.cliente = new Cliente(1, '', '', '', '', '', 1, '', '', 1, '', '', '', '', '', 1, '', '', '', 0, '', 0, '', '', 0, 0);
	}

	public ngOnInit(): void {
		// ...
	}

	public ngAfterViewInit(): void {
		this.loadUser();
	}

	public ngOnDestroy(): void {
		$('.element-with-popover-c2').popover('dispose');
	}

	public updateClientStep1(): void {
		if (this.cliente.acerca_de_mi) {
			this.cliente.acerca_de_mi = this.cliente.acerca_de_mi.replace(/<.*?>/, "");
		}

		if (this.cliente.check_autorizacion == 0 && this.percent_progress == 100) {
			Swal.fire({
				title: 'Un momento',
				icon: 'info',
				text: 'Al aceptar estas afirmando que los datos ingresados son reales y que estos serán utilizados para procesar su depuración.',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Aceptar'
			}).then((result) => {
				if (result.value) {
				this.cliente.check_autorizacion = 1;
				this.updateClientStep2();
				}
			});
		} else {
			this.updateClientStep2();
		}
	}

	public updateContrasena(): void {
		if (this.currentPassword == undefined) {
			Swal.fire({
				title: 'UPS',
				icon: 'error',
				html: 'Si desea cambiar la contraseña, debe escribir su contraseña actual.'
			});

			return;
		}

		if (this.password == undefined || this.password2 == undefined) {
			Swal.fire({
				title: 'UPS',
				icon: 'error',
				html: 'Si desea cambiar la contraseña, debe crear una nueva contraseña y confirmarla.'
			});

			return;
		}

		if (this.password.length < 8) {
			Swal.fire({
				title: 'UPS',
				icon: 'error',
				html: 'Error, la contraseña no debe ser menor a 8 caracteres.'
			});

			return;
		}

		if (this.password != this.password2) {
			Swal.fire({
				title: 'UPS',
				icon: 'error',
				html: 'Error, las contraseñas no coinciden.'
			});

			return;
		}

		if (this.password == this.password2) {
			Swal.fire({
				title: 'Cambiar contraseña',
				icon: 'info',
				text: '¿Deseas cambiar la contraseña de tu cuenta de usuario?',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Aceptar'
			}).then((result) => {
				if (result.value) {
					this.clienteService.updateContrasena(this.token, this.password, this.currentPassword).subscribe(
						(response: any) => {
							if (response.error == false) {
								Swal.fire('La constraseña se actualizo con éxito', response.message, 'success');
							}
						},
						(error: any) => {
							if (error.error.error_code == 1) {
								Swal.fire({
									title: 'UPS',
									icon: 'error',
									html: 'Error, no se pudo cambiar la contraseña.<br>Su contraseña actual no es correcta'
								});
							} else {
								Swal.fire({
									title: 'UPS',
									icon: 'error',
									html: 'Error, no se pudo cambiar la contraseña.<br>Intente de nuevo o verifique con su administrador'
								});
							}
						}
					);
				}
			});
		}
	}

	private getMergedClient(currentClient): any {
		return {
			acerca_de_mi: currentClient.acerca_de_mi,
			apellidos: currentClient.apellidos,
			check_autorizacion: currentClient.check_autorizacion,
			correo: currentClient.correo,
			estatus: currentClient.estatus,
			facebook: currentClient.facebook,
			foto: currentClient.foto,
			fotos_dni: currentClient.fotos_dni,
			foto_dni_comprobacion: currentClient.foto_dni_comprobacion,
			id: currentClient.id,
			instagram: currentClient.instagram,
			key_dni: currentClient.key_dni,
			key_forma_pago: currentClient.key_forma_pago,
			key_genero: currentClient.key_genero,
			nombre: currentClient.nombre,
			numero: currentClient.numero,
			puesto: currentClient.puesto,
			solicitudes_autonomas: currentClient.solicitudes_autonomas,
			telefono: currentClient.telefono,
			tipo_cliente: currentClient.tipo_cliente,
			street: this.addressComponent.addressData.street,
			outside_number: this.addressComponent.addressData.outsideNumber,
			sector: this.addressComponent.addressData.sector,
			city: this.addressComponent.addressData.city,
			province: this.addressComponent.addressData.province
		};
	}

	private updateClientStep2(): void {
		if (this.uploadedFiles.length > 0) {
			this.submitImageCedula();
		}

		this.clienteService.update(this.token, this.getMergedClient(this.cliente)).subscribe(
			(response: any) => {
				if (response.status == 'success') {
					Swal.fire('Datos actualizados con éxito', response.message, 'success');
					this.details = response.changes;
					localStorage.setItem('details', JSON.stringify(this.details));
					this.userService.detailsChangeEvent.emit();
					this.loadUser();
				}
			},
			(error: any) => {
				let errores = '';

				Object.keys(error.error.error).forEach(function (k) {
					errores += '- ' + error.error.error[k] + "<br>";
				});

				Swal.fire({
					title: 'UPS',
					icon: 'error',
					html: errores
				});
			}
		);
	}

	private loadUser(): void {
		this.identity = this.userService.getIdentity();
		this.token = this.userService.getToken();

		this.userService.getUserData(this.userService.getToken()).subscribe(
			(response: any) => {
				localStorage.setItem('details', JSON.stringify(response));
				this.details = this.userService.getDetails();

				this.cliente = new Cliente(
					this.identity.sub,
					this.details.nombre,
					this.details.apellidos,
					this.details.key_provincia,
					this.details.key_municipio,
					this.details.key_sector,
					this.details.numero,
					this.details.direccion,
					this.details.key_tipo_cliente,
					this.details.key_genero,
					this.details.correo,
					this.details.telefono,
					this.details.foto,
					this.details.cedula,
					this.details.foto_cedula,
					this.details.forma_pago,
					this.details.estatus,
					this.details.acerca_de_mi,
					this.details.puesto,
					this.details.check_autorizacion,
					this.details.foto_dni_comprobacion,
					this.details.solicitudes_autonomas,
					this.details.facebook,
					this.details.instagram,
					this.details.codigo_postal,
					this.details.key_provincia
				);

				this.addressComponent.addressData.street = response.street;
				this.addressComponent.addressData.outsideNumber = response.outside_number;
				this.addressComponent.addressData.sector = response.sector;
				this.addressComponent.addressData.city = response.city;
				this.addressComponent.addressData.province = response.province;

				if (this.addressComponent.addressData.street != '') this.requiredFields.street.filled = true;
				if (this.addressComponent.addressData.outsideNumber != '') this.requiredFields.outside_number.filled = true;
				if (this.addressComponent.addressData.sector != '') this.requiredFields.sector.filled = true;
				if (this.addressComponent.addressData.city != '') this.requiredFields.city.filled = true;
				if (this.addressComponent.addressData.province != '') this.requiredFields.province.filled = true;

				if (this.cliente.foto != null) this.requiredFields.profile_img.filled = true;
				if (this.cliente.key_genero != null) this.requiredFields.gender.filled = true;
				if (this.cliente.acerca_de_mi != null) this.requiredFields.about_me.filled = true;
				if (this.cliente.telefono != null) this.requiredFields.telephone.filled = true;
				if (this.cliente.foto_dni != null) this.requiredFields.dni_img.filled = true;
				if (this.cliente.foto_dni_comprobacion != null) this.requiredFields.dni_img_verify.filled = true;

				this.refreshProgressBarPercent();
			},
			(error: any) => {
				// ...
			}
		);
	}

	private refreshProgressBarPercent(): void {
		let fieldsFilled = 0;

		for (let property in this.requiredFields) {
			if (this.requiredFields[property].filled) {
				fieldsFilled++;
			}
		}

		let percent = (100 / Object.keys(this.requiredFields).length) * fieldsFilled;
		this.percent_progress = percent >= 100 ? 100 : Math.ceil(percent);
		this.showRequieredFieldsPopovers();
	}

	private showRequieredFieldsPopovers(): void {
		for (let property in this.requiredFields) {
			if (property != 'profile_img' && property != 'dni_img' && property != 'dni_img_verify') {
				if (!this.requiredFields[property].filled) {
					$(this.requiredFields[property].popover_to).popover({
						html: true,
						container: 'body',
						content: $(this.requiredFields[property].popover_content).html(),
						placement: 'top',
						fallbackPlacement: ['top'],
						trigger: 'manual'
					});

					$(this.requiredFields[property].popover_to).popover('show');
				} else {
					$(this.requiredFields[property].popover_to).popover('dispose');
				}
			}
		}
	}

	private submitImageCedula(): void {
		this.uploadedFiles.forEach(element => {
			let fileExtension = element.name.split(".").pop();
			let imageName = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15) + '.' + fileExtension;
			let formData = new FormData();
			formData.append('cedula', element, imageName);

			this.userService.updateImageCedula(this.token, formData).subscribe(
				(response: any) => {
					if (response.status == 'success') {
						this.fotos_dni.push(imageName);
					}
				},
				(error: any) => {
					let errores = '';

					Object.keys(error.error.error).forEach(function (k) {
						errores += '- ' + error.error.error[k] + "<br>";
					});

					Swal.fire({
						title: 'UPS',
						icon: 'error',
						html: errores
					});
				}
			);
		});
	}
}
