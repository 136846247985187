import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { ClienteService } from 'src/app/services/cliente.service';
import { OfertaService } from 'src/app/services/ofertas.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ServiciosService } from 'src/app/services/servicios.service';
import * as UIkit from 'uikit';
import { faMoneyBill, faHeart, faStar } from '@fortawesome/free-solid-svg-icons';
import { NgForm } from '@angular/forms';
import { CadianService } from 'src/app/services/cadian.service';
import Swal from 'sweetalert2';
import { FavoritoService } from 'src/app/services/favorito.service';


@Component({
  selector: 'app-vacantes',
  templateUrl: './vacantes.component.html',
  styleUrls: ['./vacantes.component.css']
})
export class VacantesComponent implements OnInit {

  public identity;
  public token;
  public details;
  public resultados = [];
  public servicios;
  public oferta_selected;
  faMoneyBill = faMoneyBill;
  faHeart = faHeart;
  faStar = faStar;
  public carta_presentacion : boolean = false;
  public documentos: Array<File> = [];

  constructor(
    public _userService: UserService,
    public _clienteService: ClienteService,
    public _cadianService: CadianService,
    public _ofertasService: OfertaService,
    public _servicioService: ServiciosService,
    public _favoritoService: FavoritoService,
    public _router: Router,
    private _route: ActivatedRoute
  ) { 
    this.loadUser();
    this.getServicios();
    this.oferta_selected = {
      titulo : '',
      descripcion : '',
      key_servicio : '',
      key_habilidades : '',
      tiempo_experiencia :'',
      horario_inicio : '',
      horario_fin : '',
      sueldo : '',
      beneficios : '',
      direccion : '',
      responsabilidades : '',
      descanso : '',
      nombre_servicio: '',
      habilidades : '',
      tipo: '',
      foto: '',
      nombre: ''
    }
  }

  ngOnInit() {
    this.getQuery();
  }

  loadUser(){
    this.identity = this._userService.getIdentity();
    this.token = this._userService.getToken();
    this.details = this._userService.getDetails();
  }

  getQuery(){

    let query = [];

    this._route.queryParams.subscribe((p: any) => {
      if (p.cat){
          query['cat'] = p.cat
      }
      if (p.oft){
        query['oft'] = p.oft
      }
    });

    let formData = new FormData();
    formData.append('cat', query['cat']);
    formData.append('oft', query['oft']);

    this._ofertasService.ofertas(this.token, formData).subscribe(
      response => {
        
        if(response.status == 'success'){

          this.resultados = response.resultado;

          if(query['cat']){
            let value_query = query['cat'].toString();
            console.log(value_query);
          }

        }

      },
      errorResponse => {
        console.log(errorResponse);
      }
    );

}

getServicios(){

  this._servicioService.getServicios().subscribe(
    response => {

      if(response.status == 'success'){
        this.servicios = response.servicios;
      }

    },
    errorResponse => {
      console.log(<any>errorResponse);
    }
  );
}

verOferta(obj){
  this.carta_presentacion = false;
  this.oferta_selected = obj;

  let modal = UIkit.modal("#modal-group-1");
  modal.show();
}

explode(string){
  return string.split(",");
}

onSubmit(form: NgForm) {

  let formData = new FormData();

  for (let i = 0; i < this.documentos.length; i++) {
    formData.append('documentos['+i+']', this.documentos[i], this.documentos[i].name);
  } 

  formData.append('carta', form.value.carta);
  formData.append('oferta', this.oferta_selected.id);
  formData.append('tipo', this.oferta_selected.tipo);

  this._cadianService.enviarPostulacion(this.token, formData).subscribe(
    response => {

      if(response.status == 'success'){
        Swal.fire({
          title: '¡Muy bien!',
          text: response.message,
          icon: 'success'
        }).then((result) => {
          if (result.value) {
            window.location.href = '/vacantes';
          }
        })
      }

    },
    errorResponse => {
        Swal.fire({
          title: 'UPS',
          icon: 'error',
          text: errorResponse.error.message
        });
    }
  );
}

favorito(oferta){
  this._favoritoService.favorito(this.token, 'vacante', oferta.id).subscribe(
    response => {
      if(response.status == 'success'){
        if(response.accion == 'agregado'){
          oferta.favorito = oferta.id;
        }
        else if(response.accion == 'eliminado'){
          oferta.favorito = null;
        }
      }
    },
    errorResponse => {
        Swal.fire({
          title: 'UPS',
          icon: 'error',
          text: errorResponse.error.message
        });
    }
  );
}


}
