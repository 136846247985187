import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
	selector: 'app-recuperar-password',
	templateUrl: './recuperar-password.component.html',
	styleUrls: ['./recuperar-password.component.css']
})
export class RecuperarPasswordComponent implements OnInit {
	public email_value = '';

	constructor(public userService : UserService, private router: Router) {
		// ...
	}

	public ngOnInit(): void {
		// ...
	}

	public onSubmit(form : NgForm): void {
		this.userService.sendRecoveryPasswordEmail(form.value['email']).subscribe(
			(response: any) => {
				Swal.fire({
					icon: 'success',
					title: 'Correo enviado',
					html: 'Te enviamos un correo con instrucciones para cambiar tu contraseña.',
					allowOutsideClick: false,
					allowEscapeKey: false,
					showConfirmButton: true,
					confirmButtonText: 'Aceptar'
				});
			},
			(error: any) => {
				if (
					error.error.errors != undefined && 
					error.error.errors.length > 0 && 
					parseInt(error.error.errors[0].substring(0, 1)) == 3
				) {
					Swal.fire({
						icon: 'warning',
						title: 'El correo no existe',
						html: 'Al parecer no existe ningún usuario registrado con ese correo. Por favor revísalo e intenta de nuevo.',
						allowOutsideClick: false,
						allowEscapeKey: false,
						showConfirmButton: true,
						confirmButtonText: 'Aceptar'
					});
				} else {
					Swal.fire({
						icon: 'error',
						title: 'Algo salió mal',
						html: 'Lo sentimos, al parecer tuvimos un problema con el servidor. Por favor intenta de nuevo y si el problema sigue cantata a soporte.',
						allowOutsideClick: false,
						allowEscapeKey: false,
						showConfirmButton: true,
						confirmButtonText: 'Aceptar'
					});
				}
			}
		);
	}
}
