import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CanActivate } from '@angular/router';
import { UserService } from '../services/user.service';

@Injectable({
    providedIn: 'root'
})

export class IsCadian implements CanActivate {

    constructor(
        private authService: UserService,
        private router: Router
    ) { }

    canActivate() {

        let identity = this.authService.getIdentity();

        if (identity != null && identity.role_user == 'CADIAN') {
            return true;
        }

        this.router.navigate(['/']);

        return false;
    }
}