import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { global } from './global';

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  public url: string;

  constructor(
    private _http: HttpClient
  ) { 
    this.url = global.url;
  }

    getBlogs(form):Observable<any>{
      return this._http.post(this.url+'user/blogs', form);
    }

    getCategorias():Observable<any>{
      return this._http.get(this.url+'user/categorias-blog');
    }

    randomArticuloos():Observable<any>{
      return this._http.get(this.url+'user/random-articulos');
    }


    buscar(form: any):Observable<any>{
        return this._http.post(this.url+'user/busqueda-blog', form);
    }

    articulo(slug):Observable<any>{
        return this._http.get(this.url+'user/blogs/'+slug);
    }
}
