import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { global } from './global';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

    public url: string;
    register_enabled: number;
  
    constructor(
      private _http: HttpClient
    ) { 
      this.url = global.url;
    }

    getConfig():Observable<any>{
        let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        return this._http.get(this.url+'config',{headers: headers});
    }

    setRegisterEnabled(number){
        this.register_enabled = number;
    }

    getRegisterEnabled(){
        return this.register_enabled;
    }

  }