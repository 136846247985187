import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { global } from '../../../services/global';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { ServiciosService } from 'src/app/services/servicios.service';
import Swal from 'sweetalert2';
declare let $: any;

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit, OnDestroy {
  public identity: any;
  public token: any;
  public details: any = null;
  public url: any;
  public notificaciones: any = [];
  public unread: number = 0;
  public faBell: any = faBell;
  public subcategorias: any = [];
  public servicios: any = [];
  public servicio_selected: number = 0;
  public subcategorias_selected: any = [];
  public offset: any = 0;
  public collapse: boolean = true;
  public opcion: boolean = false;
  private timeoutCounter: number = 60000; // 60000 = 1 minute
  private isLogin: boolean = false;
  private detailsChangeEventSubs: any;

  public randomReservaNotifData: any = {
    id: 0,
    clientName: null,
    clientLastName: null,
    clientImage: null,
    reservaTitle: null,
    reservaDescription: null
  };

  constructor(
    public userService: UserService,
    public serviciosService: ServiciosService
  ) {
    this.loadUser();
    this.url = global.url;

    this.detailsChangeEventSubs = this.userService.detailsChangeEvent.subscribe(() => {
      this.loadUser();
    });
  }

  ngOnInit() {
    if (this.userService.getToken() != null) {
      this.isLogin = true;
      this.getNotificaciones();
    }

    this.userService.loginEvent.subscribe(isLogin => {
      this.isLogin = isLogin;

      if (isLogin) {
        this.loadUser();
        this.getNotificaciones();
      } else {
        this.resetMainVariables();
      }
    });

    this.getServiciosHabilidades();
  }

  public ngOnDestroy(): void {
    this.detailsChangeEventSubs.unsubscribe();
  }

  clickMenu(menu) {
    if (this.opcion === false) {
      return menu;
    } else {
      this.opcion = false;
      return !menu;
    }
  }

  clickOpcion(opcion) {
    this.opcion = true;
    return opcion;
  }

  loadUser() {
    this.identity = this.userService.getIdentity();
    this.token = this.userService.getToken();
    this.details = this.userService.getDetails();
  }

  getServiciosHabilidades() {
    this.serviciosService.getServiciosHabilidades().subscribe(
      response => {
        if (response.status == 'success') {
          this.subcategorias = response.subcategorias;
          this.servicios = response.servicios;
        }
      },
      errorResponse => {
        //...
      }
    );
  }

  getNotificaciones(offset = false, reload = false, onlyUnread = false) {
    if (this.isLogin) {
      if (offset) {
        this.offset += 5;
      }

      this.userService.getNotificaciones(this.token, this.offset, reload, onlyUnread).subscribe(
        response => {
          if (response.status == 'success') {
            if (!offset) {
              this.notificaciones = response.notificaciones;
            } else {
              response.notificaciones.forEach(element => {
                let notifExists = false;

                for (let n of this.notificaciones) {
                  if (n.id == element.id) {
                    notifExists = true;
                    break;
                  }
                }

                if (!notifExists) {
                  this.notificaciones.unshift(element);
                }
              });
            }

            this.unread = response.count_unread;
          }

          if (this.isLogin) {
            // ______
            //|      |
            //| NOTE |
            //|______|
            // Remember replace the following [setTimeout()] with websockets.
            setTimeout(() => {
              this.getNotificaciones(true, false, true);
            }, this.timeoutCounter);
          }
        },
        errorResponse => {
          if (this.isLogin) {
            // ______
            //|      |
            //| NOTE |
            //|______|
            // Remember replace the following [setTimeout()] with websockets.
            setTimeout(() => {
              this.getNotificaciones(true, false, true);
            }, this.timeoutCounter);
          }
        }
      );
    }
  }

  private resetMainVariables() {
    this.identity = null;
    this.token = null;
    this.details = null;
    this.notificaciones = [];
    this.unread = 0;
    this.faBell = faBell;
    this.subcategorias = [];
    this.servicios = [];
    this.servicio_selected = 0;
    this.subcategorias_selected = [];
    this.offset = 0;
    this.collapse = true;
    this.opcion = false;

    this.randomReservaNotifData = {
      id: 0,
      clientName: null,
      clientLastName: null,
      clientImage: null,
      reservaTitle: null,
      reservaDescription: null
    };
  }

  readNotif($event) {
    if (this.unread > 0) {
      this.userService.readNotification(this.token).subscribe(
        response => {
          if (response.status == 'success') {
            this.unread = 0;
          }
        },
        errorResponse => {
          //...
        }
      );
    }
  }

  onScroll() {
    this.getNotificaciones(true);
  }

  linkNotif(tipo, referencia) {
    let link = '';
    
    switch (tipo) {
      case 1:
        link = '#';
        break;
      case 2:
        link = '#';
        break;
      case 3:
        link = '#';
        break;
      case 4:
        link = '#';
        break;
      case 5:
        link = '/buscar?b=2&id='+referencia;
        break;
      case 6:
        link = '/perfil/'+referencia;
        break;
      case 7:
        link = (this.identity.role_user == 'CLIENTE') ? '/perfil-cliente/historial' : '/perfil-cadian/historial';
        break;
      case 8:
        link = '#';
        break;
      case 9:
        link = '/perfil-cadian/finanzas';
        break;
      case 10:
        link = (this.identity.role_user == 'CLIENTE') ? '/perfil-cliente/reservas/'+referencia : '/perfil-cadian/reservas/'+referencia;
        break;
      case 11:
        link = '/perfil/'+this.details.identificador_token;
        break;
      case 12:
        link = '/buscar?b=2&id='+referencia;
        break;
      case 14:
        link = '/mensajeria/'+referencia;
        break;
      case 15:
        link = '/subastas/'+referencia;
        break;
      case 17:
        link = '/buscar?b=2&random='+referencia;
        break;
      case 18:
        link = (this.identity.role_user == 'CLIENTE') ? '/perfil-cliente/reservas/'+referencia : '/perfil-cadian/reservas/'+referencia;
        break;
    }

    return link;
  }

  selectServicio(id) {
    if (id == this.servicio_selected) {
      this.servicio_selected = 0;
      this.subcategorias_selected = [];
    } else {
      this.servicio_selected = id;
      this.subcategorias_selected = [];

      this.subcategorias.forEach(element => {
        if (element.id_servicio == id) {
          this.subcategorias_selected.push(element);
        }
      });
    }
  }

  busquedaCuidador() {
    if (this.identity.role_user != 'CLIENTE') {
      Swal.fire('UPS', 'Ingresa o registrate con una cuenta de cliente para buscar cuidadores', 'error');
    } else {
      window.location.href="/buscar";
    }
  }

  busquedaCliente() {
    if (this.identity.role_user != 'CADIAN') {
      Swal.fire('UPS', 'Ingresa o registrate con una cuenta de cuidador para buscar clientes', 'error');
    } else {
      window.location.href="/buscar";
    }
  }

  openModal(modalName: string, referenceID) {
    this.randomReservaNotifData = {
      id: referenceID,
      clientName: null,
      clientLastName: null,
      clientImage: null,
      reservaTitle: null,
      reservaDescription: null
    };

    Swal.fire({
      title: 'Obteniendo datos...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      onOpen: () => { // <- This is deprecated, use [didOpen] on new versions of Swal.
        Swal.showLoading()
      }
    });

    this.userService.getRandomReservaNotifData(referenceID, this.token).subscribe(
      response => {
        if (response.data !== null) {
          this.randomReservaNotifData.clientName = response.data.nombre;
          this.randomReservaNotifData.clientLastName = response.data.apellidos;
          this.randomReservaNotifData.clientImage = response.data.foto;
          this.randomReservaNotifData.reservaTitle = response.data.titulo;
          this.randomReservaNotifData.reservaDescription = response.data.descripcion;
          $('#'+modalName).modal({backdrop: "static"});
        }

        Swal.close();
      },
      error => {
        this.randomReservaNotifData.id = 0;
        Swal.close();
      }
    );
  }
}
