import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MensajeriaService } from 'src/app/services/mensajeria.service';
import { global } from '../../services/global';
import { faCheckDouble, faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons';
import { ReservaService } from 'src/app/services/reserva.service';
import Swal from 'sweetalert2';
import * as UIkit from 'uikit';
import * as moment from 'moment';
import { DistritosService } from 'src/app/services/distritos.service';
import { ProvinciasService } from 'src/app/services/provincias.service';
import { AtencionesService } from 'src/app/services/atenciones.service';
import { IDayCalendarConfig } from 'ng2-date-picker';
import { NgForm } from '@angular/forms';
import Echo from 'laravel-echo';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-mensajeria',
  templateUrl: './mensajeria.component.html',
  styleUrls: ['./mensajeria.component.css']
})
export class MensajeriaComponent implements OnInit, AfterViewInit  {

  @ViewChild('chat', { static: false }) chat: ElementRef

  echo: Echo;

  public identity;
  public token;
  public details;
  public conversacion: any = [];
  public last_msg: any = {};
  public url;
  public distritos;
  public provincias;
  public referencia: number;
  public token_conver;
  public tipo_referencia;
  public receptor = { nombre: '', apellidos: '', foto: ''};
  public emisor = { nombre: '', apellidos: '', foto: ''};
  faCheckDouble = faCheckDouble;
  faMapMarkedAlt = faMapMarkedAlt;
  public isDisabled: boolean = false;
  public isDisabledLocation: boolean = false;
  latitude: number;
  longitude: number;
  zoom:number;
  public mensajeInp;
  public referenciaData;
  public config = <IDayCalendarConfig>{
    locale: "es",
    min: moment(new Date()).format('DD-MM-YYYY'),
    format: "DD-MM-YYYY HH:mm",
    showSeconds: false
  };
  public atenciones;
  public total_estimado = 0;
  public fechai;
  public fechaf;
  public title;
  

  constructor(
    public _userService: UserService,
    public _mensajeriaService: MensajeriaService,
    public _reservaService: ReservaService,
    public _provinciasService: ProvinciasService,
    private _router: Router,
    public _atencionesService: AtencionesService,
    public _distritosService: DistritosService,
    private _route: ActivatedRoute,
  ) {
    this.loadUser();
    this.checkToken();
    this.url = global.url;

    setInterval(() => {
      this.checkToken(); 
    }, 30000);

    
    /* this.echo = new Echo({
      broadcaster: 'pusher',
      authHost: environment.urlBase,
      cluster: environment.pusher_cluster,
      key: environment.pusher_key,
      authEndpoint: "http://app-cad.local/broadcasting/auth",
      auth:
      {
          headers:
          {
              Accept: 'application/json ',
              Authorization: 'Bearer ' + this.token
          }
      },
      wsHost: environment.pusher_host,
      wsPort: 6001,
      disableStats: true,
      enabledTransports: ['ws']
    }); */
    

   }

  ngOnInit() {
    this.scrollToBottom();
    this.setCurrentLocation();
    this.getAtenciones();
    this.getProvincias();
    this.getDistritos();

    // console.log("Implementando el init");
    // this.echo.private('channel-message')
    //     .listen('MessageEvent', (resp) => {
    //       console.log(resp);
    //     });
    //this.websockets();
  }

  /*websockets(){
    console.log('Entro');
    const echo = new Echo({
      broadcaster: 'pusher',
      cluster: environment.pusher_cluster,
      key: environment.pusher_key,
      wsHost: environment.pusher_host,
      wsPort: 6001,
      wssHost: environment.pusher_host,
      wssPort: 6001,
      disableStats: true,
      enabledTransports: ['ws', 'wss']
    });
    echo.channel('channel-message')
      .listen('MessageEvent', (resp) => {
        console.log(resp);
        this.title = resp.message;
      });
    
    console.log('Salio');
  }*/

  ngAfterViewInit() {
    this.chat.nativeElement.scrollTop = this.chat.nativeElement.scrollHeight;
  }

  ngAfterViewChecked() {        
    this.scrollToBottom();        
  } 
  
  scrollToBottom(): void {
    try {
        this.chat.nativeElement.scrollTop = this.chat.nativeElement.scrollHeight;
    } catch(err) { }                 
  }

  loadUser(){
    this.identity = this._userService.getIdentity();
    this.token = this._userService.getToken();
    this.details = this._userService.getDetails();
  }

  getAtenciones(){
    this._atencionesService.getAtenciones().subscribe(
      response => {

        if(response.status == 'success'){
          this.atenciones = response.atenciones;
        }

      },
      errorResponse => {
        console.log(errorResponse);
      }
    );
  }

  checkToken(){

    this._route.params.subscribe(params => {

      let token_conversacion = params['token'];

      if(token_conversacion != undefined || token_conversacion != ''){

          this._mensajeriaService.checkToken(this.token, token_conversacion).subscribe(
            response => {
              if(response.status == 'success'){
                this.conversacion = response.conversacion;
                this.last_msg = response.last_msg;
                this.referencia = response.referencia;
                this.token_conver = token_conversacion;
                this.tipo_referencia = response.tipo;
                this.receptor.nombre = response.receptor[0];
                this.receptor.apellidos = response.receptor[1];
                this.emisor.nombre = response.emisor[0];
                this.emisor.apellidos = response.emisor[1];
                this.emisor.foto = response.emisor[2];
                this.receptor.foto = response.receptor[2];
                this.referenciaData = response.referencia_data;
              }
            },
            errorResponse => {
              this._router.navigate(['/']);
            }
          );
      }
      else{
        this._router.navigate(['/']);
      }

    });
}

enviarMensaje(event, mensaje){

  event.preventDefault();

  this.isDisabled = true;

  //SANITIZAMOS
  mensaje = mensaje.replace(/<.*?>/, "");

  const geo_location = 0;
  
  let form = new FormData();
  form.append('mensaje', mensaje);
  form.append('referencia', this.referencia.toString());
  form.append('token', this.token_conver);
  form.append('tipo', this.tipo_referencia);
  form.append('geo_location', geo_location.toString());

  this._mensajeriaService.enviarMensaje(this.token, form).subscribe(
    response => {
      if(response.status == 'success'){
        this.last_msg = response.id;
        const currentPosition = this.chat.nativeElement.scrollTop;
        this.conversacion.push(response.mensaje);
        this.chat.nativeElement.scrollTop = currentPosition;
        this.isDisabled = false;
        this.mensajeInp = '';
      }
    },
    errorResponse => {
      console.log(errorResponse);
    }
  );

}

enviarUbicacion(event){

  event.preventDefault();

  this.isDisabledLocation = true;

  const geo_location = 1;
  this.setCurrentLocation();
  
  let form = new FormData();
  form.append('mensaje', this.latitude+','+this.longitude);
  form.append('referencia', this.referencia.toString());
  form.append('token', this.token_conver);
  form.append('tipo', this.tipo_referencia);
  form.append('geo_location', geo_location.toString());

  this._mensajeriaService.enviarMensaje(this.token, form).subscribe(
    response => {
      if(response.status == 'success'){
        this.last_msg = response.id;
        const currentPosition = this.chat.nativeElement.scrollTop;
        this.conversacion.push(response.mensaje);
        this.chat.nativeElement.scrollTop = currentPosition;
        this.isDisabledLocation = false;
      }
    },
    errorResponse => {
      console.log(errorResponse);
    }
  );

}

// Get Current Location Coordinates
private setCurrentLocation() {
  if ('geolocation' in navigator) {
    navigator.geolocation.getCurrentPosition((position) => {
      this.latitude = position.coords.latitude;
      this.longitude = position.coords.longitude;
      this.zoom = 12;
    });
  }
}

longitudeMSG(str){
  const spl = str.split(',');
  return Number(spl[1]);
}

latitudeMSG(str){
  const spl = str.split(',');
  return Number(spl[0]);
}

onSubmit(form: NgForm){

  let formData = new FormData();
  formData.append('id_cuidador', this.referencia.toString());
  formData.append('direccion', form.value.direccion);
  formData.append('descripcion', form.value.descripcion);
  formData.append('provincia', form.value.provincia);
  formData.append('ciudad', form.value.ciudad);
  formData.append('fecha_reserva_inicio', form.value.fecha_reserva_inicio);
  formData.append('fecha_reserva_final', form.value.fecha_reserva_final);
  formData.append('atenciones', form.value.atenciones);

  this._reservaService.enviarReserva(this.token, formData).subscribe(
    response => {

      if(response.status == 'success'){
        Swal.fire('¡Muy bien!', response.message , 'success');

        let modal = UIkit.modal("#modal2");
        modal.hide();

        if(this.identity.role_user = 'CLIENTE'){
          this._router.navigate(['/perfil-cliente/reservas']);
        }
        else{
          this._router.navigate(['/perfil-cadian/reservas']);
        }

      }

    },
    errorResponse => {
      let errores = '';
      Object.keys(errorResponse.error.error).forEach(function(k){
        errores+= '- '+ errorResponse.error.error[k]+"<br>";
      });

      Swal.fire({
        title: 'UPS',
        icon: 'error',
        html: errores
      });
    }
  );
}

getDistritos($event = null, change = false){

  let provincia = 0;

  if(change){
    provincia = $event.target.selectedOptions[0].value;
  }

  this._distritosService.getDistritosByProvincia(provincia).subscribe(
    response => {

      if(response.status == 'success'){
        this.distritos = response.distritos;
      }

    },
    errorResponse => {

      let errores = '';
      Object.keys(errorResponse.error.error).forEach(function(k){
        errores+= '- '+ errorResponse.error.error[k]+"<br>";
      });

      Swal.fire({
        title: 'UPS',
        icon: 'error',
        html: errores
      });

    }
  );

}

getProvincias(){
  this._provinciasService.getProvincias().subscribe(
    response => {

      if(response.status == 'success'){
        this.provincias = response.provincias;
      }

    },
    errorResponse => {
      console.log(errorResponse);
    }
  );
}

reservar(){
  let modal = UIkit.modal("#modal2");
  modal.show();
}

}
