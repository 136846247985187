import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { MembresiasService } from 'src/app/services/membresias.service';
import { global } from '../../services/global';
import { Router } from '@angular/router';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons';
import * as crypto from 'crypto-js';
import Swal from 'sweetalert2';
import { NgForm } from '@angular/forms';
import * as UIkit from 'uikit';


@Component({
  selector: 'app-membresias',
  templateUrl: './membresias.component.html',
  styleUrls: ['./membresias.component.css']
})
export class MembresiasComponent implements OnInit {

  public identity: any = undefined;
  public token;
  public details;
  public membresias = [];
  public membresia = [];
  public url;

  public key_membresia;
  //public key_precio;

  public membresia_nombre;
  public membresia_precio;

  faCreditCard = faCreditCard;
  url_payment = "https://pagos.azul.com.do/PaymentPage/Default.aspx";
  public amount_fixed;
  public orderNumber: number;


  constructor(
    public _userService: UserService,
    public _membresiasService: MembresiasService,
    private _router: Router
  ) {
    this.loadUser();
    this.url = global.url;
  }

  loadUser() {
    this.identity = this._userService.getIdentity();
    this.token = this._userService.getToken();
    this.details = this._userService.getDetails();
  }

  ngOnInit() {
    if (this.identity != null) {
      this.identity.role_user == 'CLIENTE' ? this.getMembresias(1, 'oferta') : this.getMembresias(2, 'oferta');
    }

    this.orderNumber = this.randNumber();
    this.getMembresiaEstatus();
  }


  getMembresiaEstatus() {

    this._membresiasService.getMembresiaEstatus(this.token).subscribe(
      response => {

        if (response.status == 'success') {
          this.membresia = response.membresia;
         // console.log(response.membresia);
        }

      },
      errorResponse => {
        // console.log(errorResponse);
      }
    );

  }

  getMembresias(type_user, tipo_membresia) {

    this._membresiasService.getMembresias(type_user, tipo_membresia).subscribe(
      response => {

        if (response.status == 'success') {
          this.membresias = response.membresias;
          //console.log(response.membresias);
        }

      },
      errorResponse => {
        console.log(errorResponse);
      }
    );
  }

  obtenerMembresias(tipo_membresia) {

    this.key_membresia = 0;
    this.membresia_nombre = "";
    this.membresia_precio = 0;
    this.identity.role_user == 'CLIENTE' ? this.getMembresias(1, tipo_membresia) : this.getMembresias(2, tipo_membresia);

  }

  /*getMyStyles(tipo_membresia) {

    let cardOferta = {
       'background-color':'#1daf1c', 
       'color':'#000000',
       'padding':'5px'
    };
    
    let cardReserva = {
      'background-color':'#e64ca2',
      'color':'#000000',
      'padding':'5px'
    };
    if (tipo_membresia == 'oferta') myStyles = cardOferta;
    else myStyles = cardReserva;

    return myStyles;
  }  

  calculateAuthHash(memb_key, memb_nombre, memb_precio){
  	
    let amount = Number(memb_precio).toFixed(2);
    this.amount_fixed = amount.toString().replace(".", "");

    let hash =  "39414060012"
    +"CAD - Cuidados a Domicilio"
    +"E-Commerce"
    +"$"
    +this.orderNumber.toString()
    +this.amount_fixed
    +"1000"
    +"http://cad.cervixsoft.com/payment-approved"
    +"http://cad.cervixsoft.com/payment-declined"
    +"http://cad.cervixsoft.com/payment-canceled"
    +"1"
    +"id_membresia"
    +memb_key.toString()
    +"0"
    +""
    +""
    +"$OfgZyH0jcS7t&zqpaaWfQ59xm9pWmroxgzjzecVkHF5M1Z6jahpAxPR!UPMHew#&kK68yf0M2hp65pXUvTNkf9XV$oA6$#OLSz8bHYVuVMf$JQG#RCyyQdjQiPp80YT";
    
    return crypto.HmacSHA512(hash, "$OfgZyH0jcS7t&zqpaaWfQ59xm9pWmroxgzjzecVkHF5M1Z6jahpAxPR!UPMHew#&kK68yf0M2hp65pXUvTNkf9XV$oA6$#OLSz8bHYVuVMf$JQG#RCyyQdjQiPp80YT");

  }*/

  randNumber() {
    return Math.floor(Math.random() * 10000000);
  }

  priceFixed(precio) {
    let amount = Number(precio).toFixed(2);
    return amount.toString().replace(".", "");
  }

  setIdMembresia(id) {
    return id.toString();
  }

  pagarMembresia() {

    /* if(this.membresia != null){          
      Swal.fire({
        title: 'Error',
        icon: 'warning',
        html: 'Ya cuenta con una membresia activa, no puede adquirir una nueva.'
      });
      let modal = UIkit.modal("#modal-membresia");
      modal.hide();
      
    }
    else{ */
    let formData = new FormData();
    formData.append('key_membresia', this.key_membresia);
    console.log(this.key_membresia);
    this._membresiasService.pagarMembresia(this.token, formData).subscribe(
      response => {
        console.log(response);
        if (response.status == 'success') {
          Swal.fire({
            title: 'OK',
            icon: 'success',
            html: 'Has adquirido la membresia.'
          });
          let modal = UIkit.modal("#modal-membresia");
          modal.hide();

          this.identity.role_user == 'CLIENTE' ?
            this._router.navigate(['/perfil-cliente']) :
            this._router.navigate(['/perfil-cadian']);

        }

      },
      errorResponse => {
        Swal.fire({
          title: 'UPS',
          icon: 'error',
          html: errorResponse.error.message
        });
        let modal = UIkit.modal("#modal-membresia");
        modal.hide();
      }
    );

    /*   } */

  }

  getMembresiaNombre() {
    return this.membresia_nombre;
  }
  getMembresiaPrecio() {
    return this.membresia_precio;
  }

  onSubmitMembresia(form: NgForm) {

    this._membresiasService.getMembresia(this.key_membresia).subscribe(
      response => {

        if (response.status == 'success') {

          this.membresia_nombre = response.membresias.nombre;
          this.membresia_precio = response.membresias.precio;


          let modal = UIkit.modal("#modal-membresia");
          modal.show();
        }


      },
      errorResponse => {
        Swal.fire({
          title: 'UPS',
          icon: 'error',
          html: errorResponse.error.message
        });
      }
    );

  }
}
