import { Component, OnInit, ViewChild } from '@angular/core';
import { VacantesService } from 'src/app/services/vacantes.service';
import { UserService } from 'src/app/services/user.service';
import { ClienteService } from 'src/app/services/cliente.service';
import { IDayCalendarConfig } from 'ng2-date-picker';
import { ServiciosService } from 'src/app/services/servicios.service';
import { AtencionesService } from 'src/app/services/atenciones.service';
import { ProvinciasService } from 'src/app/services/provincias.service';
import { DistritosService } from 'src/app/services/distritos.service';
import { ReservaService } from 'src/app/services/reserva.service';
import { AptitudesService } from 'src/app/services/aptitudes.service';
import { NgForm } from '@angular/forms';
import { OfertaService } from 'src/app/services/ofertas.service';
import { CargosService } from 'src/app/services/cargos.service';
import { Router } from '@angular/router';
import { global } from '../../../services/global';
import { AddressComponent } from '../../../shared/components/address/address.component';
import { DesbloqueoCuidadorService } from 'src/app/services/desbloqueoCuidador.service';
import Swal from 'sweetalert2';
import * as UIkit from 'uikit';
declare let $: any;
import { SubastasService } from 'src/app/services/subastas.service';

@Component({
  selector: 'app-mis-vacantes',
  templateUrl: './mis-vacantes.component.html',
  styleUrls: ['./mis-vacantes.component.css']
})
export class MisVacantesComponent implements OnInit {
  @ViewChild('addressComponent', {static: false}) private addressComponent: AddressComponent;

  public addressData = {
    street: '',
    outsideNumber: '',
    sector: '',
    city: '',
    province: ''
  };

  public checkAuctionDataCode: any = '';
  public idOfferSelected: string = '';
  public identity;
  public token;
  public details;
  public resultados = [];
  public postulados = [];
  public dias;
  public oferta_id;
  public tipo = 'reserva';
  public loader: boolean = true;
  public titulo;
  public descripcion;
  public servicio;
  public habilidades = [];
  public tiempo_experiencia;
  public hora_entrada;
  public hora_salida;
  public sueldo;
  public beneficios;
  public direccion;
  public responsabilidades;
  public atenciones;
  public atenciones_selected;
  public descanso;
  public dias_trabajo = [];
  public dias_descanso = [];

  public diasList = [
    {dia: 'Lunes'},
    {dia:'Martes'},
    {dia:'Miércoles'},
    {dia:'Jueves'},
    {dia:'Viernes'},
    {dia:'Sábado'},
    {dia:'Domingo'}
  ];

  public diasSettings = {
    singleSelection: false,
    enableCheckAll: false,
    idField: 'dia',
    textField: 'dia',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    itemsShowLimit: 7,
    allowSearchFilter: true
  };

  private selectedVacant: any = null;

  public serviciosList = [];
  public actual_step : number = 2;
  public config = <IDayCalendarConfig>{locale: "es"};
  public provincias;
  public distritos;
  public servicios = [];
  public fecha_inicio;
  public fecha_final;
  public provincia;
  public municipio;
  public vacantes;
  public tarifa;
  public key_tipo_cadian;
  public edad;
  public aptitudes;
  public tipo_oferta;
  public aptitudesSettings = {};
  public selectedAptitudes = [];
  public habilidadesSettings = {};
  public selectedHabilidades = [];
  public verificados;
  public oferta: {[k: string]: any} = {};
  public cargos = [];
  public id_cadian_selected = 0;
  public oferta_selected: any;
  public url;
  public activeTab: number = 1;

  public vacanciesPaginationConfig: any = {
    current_page: 1,
    items_per_page: 0,
    total_items: 0
  };

  public vacancies: any[] = [];
  public openedVacanciesFilter: string = '1';
  public currentGroup: string = 'opened';
  public postulants: any[] = [];
  public gettingPostulants: boolean = false;
  public vacantTitle: string = '';
  public cadian_obj: any = {};
  public id_cuidador;
  public cadian_pagado = false;

  public auctionPaymentData: any = {
    cadian_name: '',
    cadian_fare: '',
    auction_hours: '',
    auction_amount: '',
    client_balance: ''
  };

  private selectedCuiador: any = null;

  constructor(
    public _userService: UserService,
    public _ofertaService: OfertaService,
    public _vacantesService: VacantesService,
    public _clienteService: ClienteService,
    public _servicioService: ServiciosService,
    public _atencionesService: AtencionesService,
    public _provinciasService: ProvinciasService,
    public _distritosService: DistritosService,
    public _reservaService: ReservaService,
    public _aptitudesService: AptitudesService,
    public _cargosService: CargosService,
    private _router: Router,
    public _desbloqueoService: DesbloqueoCuidadorService,
    private _subastasService: SubastasService
  ) {
    this.loadUser();

    this.aptitudesSettings = {
      singleSelection: false,
      enableCheckAll: false,
      idField: 'id',
      textField: 'nombre',
      selectAllText: 'Seleccionar todo',
      unSelectAllText: 'Deseleccionar todo',
      itemsShowLimit: 8,
      allowSearchFilter: true
    };

    this.habilidadesSettings = {
      singleSelection: false,
      enableCheckAll: false,
      idField: 'id_subcategoria',
      textField: 'nombre',
      selectAllText: 'Seleccionar todo',
      unSelectAllText: 'Deseleccionar todo',
      itemsShowLimit: 8,
      allowSearchFilter: true
    };

    this.url = global.url;
    let sharedStorage = this._userService.sharedStorage;

    if (
      sharedStorage != undefined && 
      sharedStorage != null && 
      sharedStorage.show_auction_in_process
    ) {
      this.activeTab = 2;
      this.openedVacanciesFilter = '2';
      this._userService.sharedStorage = null;
      this.currentGroup = 'inprocess';
      this.getAuctionVacancies('1', '1');
    }
  }

  ngOnInit() {
    this.getMisVacantes();
    this.getServicios();
    this.getProvincias();
    this.getDistritos();
    this.getAtenciones();
    this.getAptitudes();
    this.getCargos();
    // this.getRandomVacancies('1', '1');
  }

  loadUser(){
    this.identity = this._userService.getIdentity();
    this.token = this._userService.getToken();
    this.details = this._userService.getDetails();
  }

  getCargos(){
    this._cargosService.getCargos().subscribe(
      response => {
        if (response.status == 'success') {
          this.cargos = response.cargos;
        }
      },
      errorResponse => {
        //...
      }
    );
  }

  getMisVacantes() {
    this._vacantesService.getMisVacantes(this.token, this.tipo).subscribe(
      response => {
        if (response.status == 'success') {
          this.resultados = response.resultado;
          this.loader = false;
        }
      },
      errorResponse => {
        //...
      }
    );
  }

  delete(id) {
    const oferta = {oferta: id, tipo: this.tipo}

    Swal.fire({
      title: 'Un momento',
      icon: 'info',
      text: '¿Deseas eliminar esta oferta?',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Eliminar'
    }).then(result => {
      if (result.value) {
        this._vacantesService.delete(this.token, oferta).subscribe(
          response => {
            if (response.status == 'success') {
              this.getMisVacantes();
            }
          },
          errorResponse => {
            //...
          }
        );
      }
    });
  }

  modalDestacado(oferta_id, obj) {
    this.oferta_id = oferta_id;
    this.verificados = obj.verificados;
    this.key_tipo_cadian = obj.key_tipo_cadian;
    let modal = UIkit.modal("#modal-destacado");
    modal.show();
  }

  pagarDestacadoBalance() {
    if (this.oferta_id == 0){
      return;
    }
    
    let form = new FormData();
    form.append('dias', this.dias);
    form.append('id', this.oferta_id.toString());

    this._clienteService.pagarDestacadoBalance(this.token, form).subscribe(
      response => {
        if (response.status == 'success') {
          let modal = UIkit.modal("#modal-destacado");
          modal.hide();
          Swal.fire('¡Muy bien!', response.message , 'success');
          // window.location.href = "/perfil-cliente/mis-vacantes";
          this._router.navigate(['/perfil-cliente/mis-vacantes']);
        } else if (response.status == 'error') {
          Swal.fire('¡UPS!', response.message , 'error');
        }
      },
      errorResponse => {
        Swal.fire('¡UPS!', errorResponse.error.message , 'error');
      }
    );
  }

  changeTipo(tipo) {
    this.tipo = tipo;
    this.loader = true;
    this.getMisVacantes();
  }

  updateRecibirSolicitudes(value, key_oferta) {
    /* console.log('updateRecibirSolicitudes > value, key_oferta', value, key_oferta);
    return; */

    let form = new FormData();
    form.append('key_oferta', key_oferta);
    form.append('estatus_solicitud', value);
    form.append('tipo_oferta', this.tipo);

    this._ofertaService.updateRecibirSolicitudes(this.token, form).subscribe(
      response => {
        console.log('hello? resp', response);
        if (response.status == 'success') {
          switch (this.openedVacanciesFilter) {
            case '1':
              this.getRandomVacancies(this.vacanciesPaginationConfig.current_page, '1');
              break;
            case '2':
              this.getAuctionVacancies(this.vacanciesPaginationConfig.current_page, '1');
              break;
            case '3':
              this.getWorkVacancies(this.vacanciesPaginationConfig.current_page, '1');
              break;
          }
        }
      },
      errorResponse => {
        console.log('hello? err', errorResponse);
        let errores = '';

        Object.keys(errorResponse.error.error).forEach(k => {
          errores+= '- '+ errorResponse.error.error[k]+"<br>";
        });

        Swal.fire({
          title: 'UPS',
          icon: 'error',
          html: errores
        });
      }
    );
  }

  getServicios() {
    this._servicioService.getServicios().subscribe(
      response => {
        if (response.status == 'success') {
          if (response.servicios.length > 0) {
            this.serviciosList = response.servicios;
          }
        }
      },
      errorResponse => {
        let errores = '';

        Object.keys(errorResponse.error.error).forEach(k => {
          errores+= '- '+ errorResponse.error.error[k]+"<br>";
        });

        Swal.fire({
          title: 'UPS',
          icon: 'error',
          html: errores
        });
      }
    );
  }

  getHabilidades(id_servicio = 0, habilidades_oferta = null) {
    if (id_servicio != 0) {
      this._servicioService.getSubcategoriasByServicio(id_servicio).subscribe(
        response => {
          if (response.status == 'success') {
            if (response.subcategorias.length > 0) {
              this.habilidades = response.subcategorias;

              if (habilidades_oferta !== null) {
                this.selectedHabilidades = [];

                habilidades_oferta.forEach((element: any) => {
                  this.habilidades.forEach((e: any) => {
                    if (e.id_subcategoria == element) {
                      this.selectedHabilidades.push(e);
                    }
                  });
                });
              }
            }
          }
        },
        errorResponse => {
          let errores = '';

          Object.keys(errorResponse.error.error).forEach(k => {
            errores+= '- '+ errorResponse.error.error[k]+"<br>";
          });

          Swal.fire({
            title: 'UPS',
            icon: 'error',
            html: errores
          });
        }
      );
    }
  }

  getProvincias() {
    this._provinciasService.getProvincias().subscribe(
      response => {
        if (response.status == 'success') {
          this.provincias = response.provincias;
        }
      },
      errorResponse => {
        let errores = '';

        Object.keys(errorResponse.error.error).forEach(k => {
          errores+= '- '+ errorResponse.error.error[k]+"<br>";
        });

        Swal.fire({
          title: 'UPS',
          icon: 'error',
          html: errores
        });
      }
    );
  }

  getDistritos($event = null, change = false) {
    let provincia = 0;

    if (change) {
      provincia = $event.target.selectedOptions[0].value;
    }

    this._distritosService.getDistritosByProvincia(provincia).subscribe(
      response => {
        if (response.status == 'success') {
          this.distritos = response.distritos;
        }
      },
      errorResponse => {
        let errores = '';

        Object.keys(errorResponse.error.error).forEach(k => {
          errores+= '- '+ errorResponse.error.error[k]+"<br>";
        });

        Swal.fire({
          title: 'UPS',
          icon: 'error',
          html: errores
        });
      }
    );
  }

  getAtenciones() {
    this._atencionesService.getAtenciones().subscribe(
      response => {
        if (response.status == 'success') {
          this.atenciones = response.atenciones;
        }
      },
      errorResponse => {
        //...
      }
    );
  }

  getAptitudes() {
    this._aptitudesService.getAptitudes().subscribe(
      response => {
        if (response.status == 'success'){
          this.aptitudes = response.aptitudes;
        }
      },
      errorResponse => {
        //...
      }
    );
  }

  changeExperiencia($event) {
    let id_servicio = $event.target.selectedOptions[0].value;
    this.getHabilidades(id_servicio);
  }

  step(step) {
    if (step > 1 && this.tipo == undefined) {
      return;
    }

    this.actual_step = step;
  }

  public onAddressChange(addressData): void {
    this.addressData.street = addressData.street;
    this.addressData.outsideNumber = addressData.outsideNumber;
    this.addressData.sector = addressData.sector;
    this.addressData.city = addressData.city;
    this.addressData.province = addressData.province;
  }

  public editarOfertaModal(oferta_selected): void {
    this.actual_step = 2;
    this.addressData.street = oferta_selected.street;
    this.addressData.outsideNumber = oferta_selected.outside_number;
    this.addressData.sector = oferta_selected.sector;
    this.addressData.city = oferta_selected.city;
    this.addressData.province = oferta_selected.province;

    if (this.addressComponent !== undefined) {
      this.addressComponent.addressData.street = this.addressData.street;
      this.addressComponent.addressData.outsideNumber = this.addressData.outsideNumber;
      this.addressComponent.addressData.sector = this.addressData.sector;
      this.addressComponent.addressData.city = this.addressData.city;
      this.addressComponent.addressData.province = this.addressData.province;
    }

    this.getHabilidades(oferta_selected.key_servicio, oferta_selected.key_habilidades.split(','));
    this.oferta = oferta_selected;
    const aptitudes_oferta = oferta_selected.key_aptitudes.split(',');
    this.selectedAptitudes = [];

    aptitudes_oferta.forEach(element => {
      this.aptitudes.forEach(e => {
        if (e.id == element) {
          this.selectedAptitudes.push(e);
        }
      });
    });

    if (!Array.isArray(this.oferta.responsabilidades)) {
      this.oferta.responsabilidades = this.oferta.responsabilidades != null ? this.oferta.responsabilidades.split(',') : [];
    }

    if (!Array.isArray(this.oferta.descanso)) {
      this.oferta.descanso = this.oferta.descanso != null ? this.oferta.descanso.split(',') : [];
    }

    if (!Array.isArray(this.oferta.dias_trabajo)) {
      this.oferta.dias_trabajo = this.oferta.dias_trabajo != null ? this.oferta.dias_trabajo.split(',') : [];
    }

    if (!Array.isArray(this.oferta.beneficios)) {
      this.oferta.beneficios = this.oferta.beneficios != null ? this.oferta.beneficios.split(',') : [];
    }

    this.dias_trabajo = [];

    this.oferta.dias_trabajo.forEach((dayName: any) => {
      this.dias_trabajo.push({dia: dayName});
    });

    this.dias_descanso = [];

    this.oferta.descanso.forEach((dayName: any) => {
      this.dias_descanso.push({dia: dayName});
    });

    /* this.oferta.responsabilidades = this.oferta.responsabilidades != null ? this.oferta.responsabilidades : [];
    this.oferta.descanso = this.oferta.descanso != null ? this.oferta.descanso : [];
    this.oferta.dias_trabajo = this.oferta.dias_trabajo != null ? this.oferta.dias_trabajo : [];
    this.oferta.beneficios = this.oferta.beneficios != null ? this.oferta.beneficios : []; */

    let modal = UIkit.modal("#modal-editar");
    modal.show();
  }

  explode(str){
    return str.split(',');
  }

  editarReservaSubmit(form: NgForm) {
    let habilidades_select = [];

    this.selectedHabilidades.forEach(element => {
      habilidades_select.push(element.id_subcategoria);
    });

    let aptitudes_select = [];

    this.selectedAptitudes.forEach(element => {
      aptitudes_select.push(element.id);
    });

    let formData = new FormData();

    formData.append('id', this.oferta.id);
    formData.append('tipo', this.oferta.tipo);
    formData.append('titulo', this.oferta.titulo);
    formData.append('descripcion', this.oferta.descripcion);
    formData.append('tarifa', this.oferta.tarifa);
    formData.append('key_atencion', this.oferta.key_atencion);
    formData.append('key_servicio', this.oferta.key_servicio);
    formData.append('key_habilidades', habilidades_select.join(','));
    formData.append('aptitudes', aptitudes_select.join(','));
    formData.append('fecha_cuidado', this.oferta.fecha_cuidado);
    formData.append('fecha_final', this.oferta.fecha_final);
    formData.append('direccion', this.oferta.direccion);
    formData.append('key_provincia', this.oferta.key_provincia);
    formData.append('key_municipio', this.oferta.key_municipio);
    formData.append('key_tipo_cadian', this.oferta.key_tipo_cadian);
    formData.append('key_experiencia', this.oferta.key_experiencia);
    formData.append('key_edad', this.oferta.key_edad);

    this._ofertaService.editarOfertaReserva(this.token, formData).subscribe(
      response => {
        if (response.status == 'success') {
          this.oferta = {};
          this.getMisVacantes();
          Swal.fire('¡Muy bien!', response.message , 'success');
          let modal = UIkit.modal("#modal-editar");
          modal.hide();
        }
      },
      errorResponse => {       
        let errores = '';

        Object.keys(errorResponse.error.error).forEach(k => {
          errores+= '- '+ errorResponse.error.error[k]+"<br>";
        });

        Swal.fire({
          title: 'UPS',
          icon: 'error',
          html: errores
        });
      }
    );
  }

  editarOfertaSubmit(form: NgForm) {
    this.oferta.titulo = this.oferta.titulo.replace(/<.*?>/, "");
    this.oferta.descripcion = this.oferta.descripcion.replace(/<.*?>/, "");
    let habilidades_select = [];

    this.selectedHabilidades.forEach(element => {
      habilidades_select.push(element.id_subcategoria);
    });

    let aptitudes_select = [];

    this.selectedAptitudes.forEach(element => {
      aptitudes_select.push(element.id);
    });

    let formData = new FormData();

    let restDays = [];

    this.dias_descanso.forEach((element: any) => {
      restDays.push(element.dia);
    });

    let workDays = [];

    this.dias_trabajo.forEach((element: any) => {
      workDays.push(element.dia);
    });

    formData.append('id', this.oferta.id);
    formData.append('titulo', this.oferta.titulo);
    formData.append('descripcion', this.oferta.descripcion);
    formData.append('servicio', this.oferta.key_servicio);
    formData.append('habilidades', habilidades_select.join(','));
    formData.append('aptitudes', aptitudes_select.join(','));
    formData.append('tiempo_experiencia', this.oferta.tiempo_experiencia);
    formData.append('hora_entrada', this.oferta.horario_inicio);
    formData.append('hora_salida', this.oferta.horario_fin);
    formData.append('sueldo', this.oferta.sueldo);
    formData.append('beneficios', this.oferta.beneficios);
    formData.append('vacantes', this.oferta.vacantes_disponibles);
    formData.append('direccion', this.oferta.direccion);
    // formData.append('key_provincia', this.oferta.key_provincia);
    // formData.append('key_municipio', this.oferta.key_municipio);
    formData.append('responsabilidades', this.oferta.responsabilidades);
    formData.append('descanso', restDays.join(','));
    formData.append('dias_trabajo', workDays.join(','));
    formData.append('key_edad', this.oferta.key_edad);
    formData.append('key_atencion', this.oferta.key_atencion);
    formData.append('verificados', this.verificados);
    formData.append('key_tipo_cadian', this.key_tipo_cadian);
    // ---------------------------------------------------------------
    formData.append('street', this.addressData.street === null ? "" : this.addressData.street);
    formData.append('outside_number', this.addressData.outsideNumber === null ? "" : this.addressData.outsideNumber);
    formData.append('sector', this.addressData.sector === null ? "" : this.addressData.sector);
    formData.append('city', this.addressData.city === null ? "" : this.addressData.city);
    formData.append('province', this.addressData.province === null ? "" : this.addressData.province);

    this._ofertaService.editarOferta(this.token, formData).subscribe(
      response => {
        if (response.status == 'success') {
          this.oferta = {};
          this.getMisVacantes();
          Swal.fire('¡Muy bien!', response.message , 'success');
          let modal = UIkit.modal("#modal-editar");
          modal.hide();
        }
      },
      errorResponse => {
        let errores = '';

        Object.keys(errorResponse.error.error).forEach(k => {
          errores+= '- '+ errorResponse.error.error[k]+"<br>";
        });

        Swal.fire({
          title: 'UPS',
          icon: 'error',
          html: errores
        });
      }
    );
  }

  enviarReserva() {
    Swal.fire({
      title: '¿Estás seguro?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Realizar reserva!'
    }).then(result => {
      if (result.value) {
        let formData = new FormData();

        formData.append('id_cuidador', this.id_cadian_selected.toString());
        formData.append('direccion', this.oferta_selected.direccion);
        formData.append('provincia', this.oferta_selected.key_provincia);
        formData.append('ciudad', this.oferta_selected.key_municipio);
        formData.append('fecha_reserva_inicio', this.oferta_selected.fecha_cuidado);
        formData.append('fecha_reserva_final', this.oferta_selected.fecha_final);
        formData.append('atenciones', this.oferta_selected.key_atencion);
        formData.append('id_oferta', this.oferta_selected.id);
      
        this._reservaService.enviarReserva(this.token, formData).subscribe(
          response => {
            if (response.status == 'success') {
              Swal.fire('¡Muy bien!', response.message , 'success');
              let modal = UIkit.modal("#modal-postulados");
              modal.hide();

              if (this.identity.role_user = 'CLIENTE') {
                this._router.navigate(['/perfil-cliente/reservas']);
              } else {
                this._router.navigate(['/perfil-cadian/reservas']);
              }
            }
          },
          errorResponse => {
            let errores = '';

            Object.keys(errorResponse.error.error).forEach(k => {
              errores+= '- '+ errorResponse.error.error[k]+"<br>";
            });
      
            Swal.fire({
              title: 'UPS',
              icon: 'error',
              html: errores
            });
          }
        );
      }
    });
  }

  // ------------------------------------------

  public onVacanciesFilterChange(option: string, status: string): void {
    switch (option) {
      case '1':
        this.tipo = 'reserva';
        this.getRandomVacancies('1', '1');
        break;
      case '2':
        this.tipo = 'reserva';
        this.getAuctionVacancies('1', status);
        break;
      case '3':
        this.tipo = 'trabajo';
        this.getWorkVacancies('1', '1');
        break;
    }
  }

  public vacanciesPaginationChange(page: string, vacanciesFilter: any, status: string): void {
    vacanciesFilter.scrollIntoView({block: "center", behavior: "smooth"});

    switch (this.openedVacanciesFilter) {
      case '1':
        this.getRandomVacancies(page, '1');
        break;
      case '2':
        this.getAuctionVacancies(page, status);
        break;
      case '3':
        this.getWorkVacancies(page, '1');
        break;
    }
  }

  public getRandomVacancies(page: string, status: string): void {
    this._vacantesService.getUserRandomVacancies(page, status, this.currentGroup, this.token).subscribe(
      (response: any) => {
        this.vacanciesPaginationConfig.items_per_page = response.items_per_page;
        this.vacanciesPaginationConfig.total_items = response.total_items;
        this.vacancies = response.items;
      },
      (error: any) => {
        // ...
      }
    );
  }

  public getAuctionVacancies(page: string, status: string): void {
    this.vacancies = [];

    this._vacantesService.getUserAuctionVacancies(page, status, this.currentGroup, this.token).subscribe(
      (response: any) => {
        this.vacanciesPaginationConfig.items_per_page = response.items_per_page;
        this.vacanciesPaginationConfig.total_items = response.total_items;
        this.vacancies = response.items;
        console.log('this.vacancies', this.vacancies);
      },
      (error: any) => {
        // ...
      }
    );
  }

  public getWorkVacancies(page: string, status: string): void {
    this._vacantesService.getUserWorkVacancies(page, status, this.currentGroup, this.token).subscribe(
      (response: any) => {
        this.vacanciesPaginationConfig.items_per_page = response.items_per_page;
        this.vacanciesPaginationConfig.total_items = response.total_items;
        this.vacancies = response.items;
      },
      (error: any) => {
        // ...
      }
    );
  }

  public openAuctionPostulantsModal(idOffer: string, vacantTitle: string, vacant: any): void {
    this.selectedVacant = vacant;
    this.postulants = [];
    this.gettingPostulants = true;
    this.vacantTitle = vacantTitle;

    $('#auction_postulants').modal({
      backdrop: 'static',
      keyboard: false
    });

    this._vacantesService.getOfferPostulants(idOffer, this.token).subscribe(
      (response: any) => {
        this.postulants = response;
        console.log(response);
        this.gettingPostulants = false;
      },
      (error: any) => {
        this.gettingPostulants = false;
      }
    );
  }

  public openAuctionPostulantModal(idOffer: string, vacantTitle: string): void {
    this.postulants = [];
    this.gettingPostulants = true;
    this.vacantTitle = vacantTitle;

    $('#auction_postulants').modal({
      backdrop: 'static',
      keyboard: false
    });

    this._vacantesService.getSelectedOfferPostulant(idOffer, this.token).subscribe(
      (response: any) => {
        this.postulants.push(response);
        this.gettingPostulants = false;
      },
      (error: any) => {
        this.gettingPostulants = false;
      }
    );
  }

  public finishAuction(idOffer: string, vacantTitle: string, vacant: any): void {
    Swal.fire({
      icon: 'info',
      html: 'Para finalizar esta subasta selecciona un cuidador de la lista de postulantes',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      showConfirmButton: true,
      confirmButtonText: 'Ver postulantes'
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.openAuctionPostulantsModal(idOffer, vacantTitle, vacant);
      }
    });
  }

  public contactar(): void {
    let form = new FormData();
    form.append('id_cadian', this.id_cuidador);
    this.cadian_pagado = false;
    
    // CONSULTAMOS SI EL CUIDADOR A CONTACTAR YA FUE CONTACTADO ANTERIORMENTE
    this._desbloqueoService.checkDesbloqueo(this.token, form).subscribe(
      (response: any) => {
        if (response.status == 'success') {
          $('#auction_postulants').modal('hide');
          this.cadian_pagado = true;
          this._router.navigate(['/mensajeria/' + response.token_conversacion]);
        } else {
          $('#auction_postulants').modal('hide');
          let modal = UIkit.modal("#modal-desbloqueo");
          modal.show();
        }
      },
      (error: any) => {
        // ...
      }
    );
  }

  public pagarDesbloqueo(event: any): void {
    let form = new FormData();
    form.append('id_cadian', this.id_cuidador);

    Swal.fire({
      title: 'Un momento',
      icon: 'info',
      text: 'Confirmanos que quieres pagar el desbloqueo de este cuidador',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Pagar'
    }).then((result) => {
      if (result.value) {
        this._clienteService.pagarDesbloqueoBalance(this.token, form).subscribe(
          (response: any) => {
            if (response.status == 'success') {
              Swal.fire('¡Muy bien!', response.message , 'success');
            } else {
              Swal.fire('UPS', response.message , 'error');
            }
          },
          (error: any) => {
            // ...
          }
        );
      }
    });
  }

  public showConfirmCancelAuctionWind(idOffer: number): void {
    Swal.fire({
      icon: 'info',
      html: '¿Desea cancelar esta subasta?',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showCancelButton: true,
      cancelButtonText: 'No',
      showConfirmButton: true,
      confirmButtonText: 'Si'
    }).then((result: any) => {
      if (result.isConfirmed) {
        this._vacantesService.cancelAuction(idOffer.toString(), this.token).subscribe(
          (response: any) => {
            let limit = this.vacancies.length;

            for (let i = 0; i <= limit; i++) {
              if (this.vacancies[i].id == idOffer) {
                this.vacancies.splice(i, 1);
                break;
              }
            }

            Swal.fire({
              icon: 'success',
              title: 'Subasta cancelada',
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
              showCancelButton: false,
              cancelButtonText: 'No',
              showConfirmButton: true,
              confirmButtonText: 'Aceptar'
            });
          },
          (error: any) => {
            // ...
          }
        );
      }
    });
  }

  public showAuctionPaymentData(cadian: any): void {
    this.selectedCuiador = cadian;
    this.auctionPaymentData.client_balance = this.selectedVacant.client_balance;
    this.auctionPaymentData.auction_hours = this.selectedVacant.auction_hours;
    this.auctionPaymentData.cadian_name = cadian.postulant_name;
    this.auctionPaymentData.cadian_fare = parseFloat(cadian.postulant_fare).toFixed(2);
    this.auctionPaymentData.auction_amount = (parseFloat(cadian.postulant_fare) * parseFloat(this.selectedVacant.auction_hours)).toFixed(2);
    $('#auction_postulants').modal('hide');
    UIkit.modal("#modal_payment_data").show();
  }

  public selectAuctionPostulant(): void {
    if (parseFloat(this.auctionPaymentData.client_balance) < parseFloat(this.auctionPaymentData.auction_amount)) {
      Swal.fire({
        title: 'Saldo insuficiente',
        icon: 'info',
        text: 'No tienes saldo suficiente para pagar la subasta. Por favor realiza una recarga e intenta de nuevo.',
        confirmButtonText: 'Aceptar'
      });
    } else {
      this._subastasService.selectAuctionPostulant(this.selectedVacant.id_auction.toString(), this.selectedCuiador.id_cadian.toString(), this.token).subscribe(
        (response: any) => {
          Swal.fire({
            title: 'Cuidador seleccionado',
            icon: 'success',
            html: 'Puedes contactar al cuidador en el menú <span style="color: black; font-weight: bold; display: inline-block;">Mis vacantes</span>',
            confirmButtonText: 'Aceptar',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false
          });

          this.activeTab = 2;
          this.openedVacanciesFilter = '2';
          this._userService.sharedStorage = null;
          this.currentGroup = 'inprocess';
          this.getAuctionVacancies('1', '1');
        },
        (error: any) => {
          // ...
        }
      );
    }
  }

  public openCheckAuctionStartModal(vacant: any): void  {
    console.log(vacant);

    this.idOfferSelected = vacant.id;
    this.checkAuctionDataCode = '';

    UIkit.modal("#modal_check_auction", {
      escClose: false,
      bgClose: false
    }).show();
  }

  public checkAuctionStart(): void {
    this._subastasService
      .checkAuctionStart(this.idOfferSelected, this.checkAuctionDataCode, this.token)
      .subscribe(
        (response: any) => {
          UIkit.modal("#modal_check_auction").hide();

          Swal.fire({
            icon: 'success',
            title: 'Inicio marcado',
            confirmButtonText: 'Aceptar',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false
          });

          this.getAuctionVacancies('1', '1');
        },
        (error: any) => {
          if (error.error.wrong_code != undefined || error.error.code != undefined) {
            Swal.fire({
              icon: 'error',
              title: 'Código incorrecto',
              confirmButtonText: 'Aceptar',
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false
            });
          }
        }
      );
  }

  public checkAuctionEnd(): void {
    this._subastasService
      .checkAuctionEnd(this.idOfferSelected, this.checkAuctionDataCode, this.token)
      .subscribe(
        (response: any) => {
          UIkit.modal("#modal_check_auction_end").hide();

          Swal.fire({
            icon: 'success',
            title: 'Reserva finalizada',
            confirmButtonText: 'Aceptar',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false
          });

          // this.getAuctionVacancies('1', '1');

          this.activeTab = 3;
          this.openedVacanciesFilter = '2';
          this.currentGroup = 'closed';
          this.getAuctionVacancies('1', 'all');
        },
        (error: any) => {
          if (error.error.wrong_code != undefined || error.error.code != undefined) {
            Swal.fire({
              icon: 'error',
              title: 'Código incorrecto',
              confirmButtonText: 'Aceptar',
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false
            });
          }
        }
      );
  }

  public showAuctionEndModal(vacant: any): void {
    this.idOfferSelected = vacant.id;
    this.checkAuctionDataCode = '';

    UIkit.modal("#modal_check_auction_end", {
      escClose: false,
      bgClose: false
    }).show();
  }
}
