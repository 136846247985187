import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params} from '@angular/router';
import { User } from '../../models/user';
import { UserService } from '../../services/user.service';
import Swal from 'sweetalert2';
import { faUser, faLock, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { TiposCadianService } from 'src/app/services/tipos_cadian.service';

@Component({
  selector: 'app-registercadian',
  templateUrl: './registercadian.component.html',
  styleUrls: ['./registercadian.component.css'],
  // providers: [UserService, TiposCadianService]
  providers: []
})
export class RegistercadianComponent implements OnInit {
  public page_title: string;
  public user: User;
  public checkbox;
  faUser = faUser;
  faLock = faLock;
  faEnvelope = faEnvelope;
  public tipos_cadian;

  constructor(
    private _registerCadianService: UserService,
    private _tiposCadianService: TiposCadianService,
    private _router: Router,
    private _route: ActivatedRoute
  ) {
    this.page_title = 'Registrate';
    this.user = new User(1, '', '', '', '', '', 0, '', '');
    this.checkbox = false;
   }

  ngOnInit() {
    this.getTiposCadian();
    console.log('Componente de registro lanzado');
  }

  onSubmit(form){

    console.log(this.user.type_user);

    this._registerCadianService.registerCadian(this.user).subscribe(
      response => {

        if(response.status == 'success'){
          Swal.fire('Registro realizado con éxito', response.message , 'success');
          form.reset();
          this._router.navigate(['/login']);
        }

      },
      errorResponse => {

        let errores = '';
        Object.keys(errorResponse.error.error).forEach(function(k){
          errores+= '- '+ errorResponse.error.error[k]+"<br>";
        });

        Swal.fire({
          title: 'UPS',
          icon: 'error',
          html: errores
        });

      }
    );

  }

  getTiposCadian(){

    this._tiposCadianService.getTiposCadian().subscribe(
      response => {

        if(response.status == 'success'){
          this.tipos_cadian = response.tipos_cadian;
        }

      },
      errorResponse => {
        console.log(<any>errorResponse);
      }
    );

  }

}
