import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { FavoritoService } from 'src/app/services/favorito.service';
import { global } from '../../services/global';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-favoritos',
  templateUrl: './favoritos.component.html',
  styleUrls: ['./favoritos.component.css']
})
export class FavoritosComponent implements OnInit {

  public identity;
  public token;
  public details;
  public favoritos = [];
  public url;

  constructor(
    public _userService: UserService,
    public _favoritosService: FavoritoService
  ) { 
    this.loadUser();
    this.url = global.url;
  }

  loadUser(){
    this.identity = this._userService.getIdentity();
    this.token = this._userService.getToken();
    this.details = this._userService.getDetails();
  }

  ngOnInit() {
    this.getFavoritos();
  }

  getFavoritos(){

    this._favoritosService.getFavoritos(this.token).subscribe(
      response => {
        
        if(response.status == 'success'){
          this.favoritos = response.favoritos;
        }

      },
      errorResponse => {
        console.log(errorResponse);
      }
    );
  }

  changeFavorito(obj){
    this._favoritosService.favorito(this.token, obj.tipo, obj.key_referencia).subscribe(
      response => {
        if(response.status == 'success'){
          if(response.accion == 'eliminado'){
            this.getFavoritos();
          }
        }
      },
      errorResponse => {
          Swal.fire({
            title: 'UPS',
            icon: 'error',
            text: errorResponse.error.message
          });
      }
    );
  }

}
