import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { global } from './global';

@Injectable({
  providedIn: 'root'
})
export class CertificadosService {

  public url: string;

  constructor(
    private _http: HttpClient
  ) { 

    this.url = global.url;

  }

  getCertificadosByUser(token):Observable<any>{

    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                    .set('Authorization', token);

    return this._http.get(this.url+'user/certificados',{headers: headers});

  }

  deleteCertificado(token, id):Observable<any>{

    let params = new FormData();
    params.append('id', id);

    let headers = new HttpHeaders().set('Authorization', token);
                            
    return this._http.post(this.url+'user/delete/certificado', params, {headers: headers});

  }
}
