import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { global } from './global';

@Injectable({providedIn: 'root'})
export class SubastasService {
	constructor(private httpClient: HttpClient) { 
		// ...
	}

	public getSubasta(auctionToken: any, token: any): Observable<any> {
		return this.httpClient.get(
			global.url+'subasta/' + auctionToken,
			{headers: new HttpHeaders().set('Authorization', token)}
		);
	}

	public ofertar(token, formData: any): Observable<any> {
		let headers = new HttpHeaders().set('Authorization', token);
		return this.httpClient.post(global.url+'user/ofertar-subasta', formData, {headers: headers});
	}

	public selectAuctionPostulant(idAuction: string, idPostulant: string, token: any): Observable<any> {
		return this.httpClient.get(
			global.url + 'auctions/postulants/select?id_auction=' + idAuction + '&id_postulant=' + idPostulant,
			{headers: new HttpHeaders().set('Authorization', token)}
		);
	}

	public getAuctionPaymentData(idAuction: string, idCadian: string, token: any): Observable<any> {
		return this.httpClient.get(
			global.url + '...?id_auction=' + idAuction + '&id_cadian=' + idCadian,
			{headers: new HttpHeaders().set('Authorization', token)}
		);
	}

	public getAuctionStartCode(idOffer: string, token: any): Observable<any> {
		return this.httpClient.get(
			global.url + 'auctions/get-start-code?id_offer=' + idOffer,
			{headers: new HttpHeaders().set('Authorization', token)}
		);
	}

	public getAuctionEndCode(idOffer: string, token: any): Observable<any> {
		return this.httpClient.get(
			global.url + 'auctions/get-end-code?id_offer=' + idOffer,
			{headers: new HttpHeaders().set('Authorization', token)}
		);
	}

	public checkAuctionStart(idOffer: string, code: string, token: any): Observable<any> {
		return this.httpClient.get(
			global.url + 'auctions/check-start?id_offer=' + idOffer + '&code=' + code,
			{headers: new HttpHeaders().set('Authorization', token)}
		);
	}

	public checkAuctionEnd(idOffer: string, code: string, token: any): Observable<any> {
		return this.httpClient.get(
			global.url + 'auctions/check-end?id_offer=' + idOffer + '&code=' + code,
			{headers: new HttpHeaders().set('Authorization', token)}
		);
	}
}