import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { global } from 'src/app/services/global';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { NgForm } from '@angular/forms';
import { SuscripcionService } from 'src/app/services/suscripcion.service';
import { ServiciosService } from 'src/app/services/servicios.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  // providers: [UserService]
  providers: []
})
export class HomeComponent implements OnInit {

  public page_title: string;
  public identity;
  public token;
  public details;
  public url;
  public subcategorias_reserva = [];
  public servicios_reserva = [];
  public servicio_selected;
  public subcategorias_selected = [];
  public fecha_reserva;

  constructor(
    public _userService: UserService,
    public _suscripcionService: SuscripcionService,
    public _servicioService: ServiciosService,
    private router: Router
  ) { 
    this.page_title = 'Inicio';
    this.loadUser();
    this.url = global.url;
  }

  ngOnInit() {
    this.getServiciosHabilidades();
  }

  ngDoCheck(){

    this.loadUser();

  }

  loadUser(){
    this.identity = this._userService.getIdentity();
    this.token = this._userService.getToken();
    this.details = this._userService.getDetails();
  }

  getServiciosHabilidades(){

    this._servicioService.getServiciosHabilidades().subscribe(
      response => {
        
        if(response.status == 'success'){
          this.subcategorias_reserva = response.subcategorias;
          this.servicios_reserva = response.servicios;
        }

      },
      errorResponse => {
        console.log(errorResponse);
      }
    );

  }

  goToComponent(tipo){

      if(this.identity != null){

        if(this.identity.role_user == 'CADIAN' && tipo == 'cadian'){

          Swal.fire('UPS', 'Usted ya es un cuidador.' , 'error');
          return;

        }

      }

      if(tipo == 'cadian'){
        this.router.navigate(['/registro-cadian']);
      }
      else if(tipo == 'cliente'){
        if(this.identity == null || this.identity.role_user == 'CADIAN'){
          this.router.navigate(['/registro-cliente']);
        }
        else{
          window.location.href = "/buscar";
        }
      }
  }

  enviarSuscripcion(form: NgForm){

    let formData = new FormData();
    formData.append('nombre', form.value.nombre);
    formData.append('apellidos', form.value.apellidos);
    formData.append('correo', form.value.correo);
  
    this._suscripcionService.nuevaSuscripcion(formData).subscribe(
      response => {
        if(response.status == 'success'){
          Swal.fire('Muy bien',response.message,'success');
        }
      },
      errorResponse => {
        Swal.fire('UPS',errorResponse.error.message,'error');
      }
    );
  }

  busqueda(){
      window.location.href="/buscar?b=1";
  }

  busquedaEmpleo(){
    if(!this.identity){
      window.location.href="/registro";
    }
    else if(this.identity.role_user != 'CADIAN'){
      Swal.fire('UPS', 'Ingresa o registrate con una cuenta de cuidador postularse a empleos', 'error');
    }
    else{
      window.location.href="/buscar";
    }
  }

  busquedaCuidador(){
    if(!this.identity){
      Swal.fire('UPS', 'Ingresa o registrate con una cuenta de cuidador para buscar cuidadores', 'error');
    }
    else if(this.identity.role_user != 'CLIENTE'){
      Swal.fire('UPS', 'Ingresa o registrate con una cuenta de cliente para buscar cuidadores', 'error');
    }
    else{
      window.location.href="/buscar";
    }
  }

  componentOferta(){
    if(!this.identity){
      window.location.href="/login";
    }
    else if(this.identity.role_user != 'CLIENTE'){
      Swal.fire('UPS', 'Ingresa o registrate con una cuenta de cliente para publicar un empleo', 'error');
    }
    else{
      window.location.href="/nueva-oferta";
    }
  }

  buscarReserva(){
    this.selectServicio();

    window.location.href='/buscar?b=1&cad="'+this.subcategorias_selected.join(',')+'"&fec='+this.fecha_reserva;
  }

  selectServicio(){
      //LIMPIAMOS ARREGLO SUBCATEGORIAS
      this.subcategorias_selected = [];

      this.subcategorias_reserva.forEach(element => {
        if(element.id_servicio == this.servicio_selected){
          this.subcategorias_selected.push(element.id_subcategoria);
        }
      });
  }
}
