import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavComponent } from './components/nav/nav.component';
import { routing, appRoutingProviders } from '../app.routing';
import { RecaptchaModule } from 'angular-google-recaptcha';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';


import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { AddonsComponent } from './components/addons/addons.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';


@NgModule({
  declarations: [NavComponent, HeaderComponent, FooterComponent, AddonsComponent],
  exports: [NavComponent, FooterComponent, AddonsComponent],
  imports: [
    CommonModule,
    routing,
    RecaptchaModule.forRoot({
      siteKey: '6LeUys8UAAAAADJAoLwiQUhP4Km7gEWlaRy9C-LI',
    }),
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    InfiniteScrollModule
  ]
})
export class SharedModule { }
