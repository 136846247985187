import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { BlogService } from 'src/app/services/blog.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {
  
  public identity;
  public token;
  public details;
  public blogs = [];
  public blogs_busqueda;
  public busqueda;
  public loading: boolean = false;
  p: number = 1;
  public limit : number = 6;
  public offset: number = 0;
  public last_article: boolean = false;
  public msg_more = 'Ver más articulos';

  constructor(
    public _userService: UserService,
    public _blogService: BlogService
  ) { 
    this.loadUser();
  }

  ngOnInit() {
    this.getBlogs();
  }

  loadUser(){
    this.identity = this._userService.getIdentity();
    this.token = this._userService.getToken();
    this.details = this._userService.getDetails();
  }

  getBlogs(){
    let formData = new FormData();
    formData.append('offset', this.offset.toString());
    formData.append('limit', this.limit.toString());
    this._blogService.getBlogs(formData).subscribe(
      response => {

        if(response.status == 'success'){
          if(response.blogs.length > 0){
            this.blogs = response.blogs;
            this.offset += 6;
          }
          else{
            this.last_article = true;
            this.msg_more = 'Ya no hay más articulos';
          }
        }

      },
      errorResponse => {
        console.log(errorResponse);
      }
    );
  }

  moreArticles(){
    let formData = new FormData();
    formData.append('offset', this.offset.toString());
    formData.append('limit', this.limit.toString());
    this._blogService.getBlogs(formData).subscribe(
      response => {

        if(response.status == 'success'){
            if(response.blogs.length > 0){
              response.blogs.forEach(element => {
                this.blogs.push(element);
              });
              this.offset += 6;
            }
            else{
              this.last_article = true;
              this.msg_more = 'Ya no hay más articulos';
            }
        }

      },
      errorResponse => {
        console.log(errorResponse);
      }
    );
  }

  shortDesc(text){

    let text_replace = text.replace(/<(.|\n)*?>/g, "");
    let text_array = text_replace.split(' ');
    let short_text = '';

    for(let i = 0; i <= text_array.length; i++){
        if(i == 25 || text_array[i] == undefined){
          return short_text+'...';
        }

        short_text+= text_array[i]+' ';
    }
  }

  setIndex(index){
    
    let arr = [];

    for(let i = index; i < this.blogs.length; i++){
      arr.push(this.blogs[i]);
    }

    return arr;
  }

  toSeoUrl(url) {
    return url.toString()               // Convert to string
        .normalize('NFD')               // Change diacritics
        .replace(/[\u0300-\u036f]/g,'') // Remove illegal characters
        .replace(/\s+/g,'-')            // Change whitespace to dashes
        .toLowerCase()                  // Change to lowercase
        .replace(/&/g,'-and-')          // Replace ampersand
        .replace(/[^a-z0-9\-]/g,'')     // Remove anything that is not a letter, number or dash
        .replace(/-+/g,'-')             // Remove duplicate dashes
        .replace(/^-*/,'')              // Remove starting dashes
        .replace(/-*$/,'');             // Remove trailing dashes
  }

  buscar(form){

    if(this.busqueda != undefined || this.busqueda != ''){
      this.loading = true;
      let formData = new FormData();
      formData.append('busqueda', this.busqueda);

      this._blogService.buscar(formData).subscribe(
        response => {
          if(response.status == 'success'){
              this.loading = false;
              this.blogs_busqueda = response.resultados;
          }
        },
        errorResponse => {
          console.log(errorResponse);
        }
      );
  }
}

}
