import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params} from '@angular/router';
import { UserService } from '../../services/user.service';
import { faUser, faKey } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';

@Component({
	selector: 'app-soporte',
	templateUrl: './soporte.component.html',
	styleUrls: ['./soporte.component.css']
})
export class SoporteComponent implements OnInit {
	public token: string;
	public identity: any;
	public details: any;

	constructor(
		private userService: UserService,
		private activatedRoute: ActivatedRoute
	) { 
		// ...
	}

	public ngOnInit(): void {
		this.activatedRoute.params.subscribe((params: any) => {
			let tokenAcceso = params['token'];

			this.userService.accesoSoporte(tokenAcceso).subscribe(
				(response: any) => {
					this.identity = response;
					this.details = this.identity.details;

					this.userService.getSoporteTokenSesion(tokenAcceso).subscribe(
						(response: any) => {
							this.token = response;

							localStorage.removeItem('identity');
							localStorage.removeItem('token');
							localStorage.removeItem('details');

							localStorage.setItem('token', this.token);
							localStorage.setItem('identity', JSON.stringify(this.identity));
							localStorage.setItem('details', JSON.stringify(this.details));

							Swal.fire('Login exitoso...', 'Ingreso realizado con éxito', 'success').then(() => {
								if (this.identity.role_user == 'CLIENTE') {
									window.location.href = '/perfil-cliente';
								} else if (this.identity.role_user == 'CADIAN') {
									window.location.href = '/perfil-cadian';
								} else {
									window.location.href = '/';
								}
							});
						},
						(error: any) => {
							Swal.fire('Error de acceso', 'No se pudo realizar el acceso, por favor intenta de nuevo (2)', 'error');
						}
					);
				},
				(error: any) => {
					Swal.fire('Error de acceso', 'No se pudo realizar el acceso, por favor intenta de nuevo (1)', 'error');
				}
			);
		});
	}
}
