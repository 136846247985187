import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2';
import { global } from 'src/app/services/global';
import { SuscripcionService } from 'src/app/services/suscripcion.service';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-como-funciona',
  templateUrl: './como-funciona.component.html',
  styleUrls: ['./como-funciona.component.css']
})
export class ComoFuncionaComponent implements OnInit {

  public identity;
  public token;
  public details;
  public url;

  constructor(
    public _userService: UserService,
    public _suscripcionService: SuscripcionService,
    private router: Router
  ) { 
    this.loadUser();
    this.url = global.url;
  }

  ngOnInit() {
  }

  loadUser(){
    this.identity = this._userService.getIdentity();
    this.token = this._userService.getToken();
    this.details = this._userService.getDetails();
  }

  enviarSuscripcion(form: NgForm){

    let formData = new FormData();
    formData.append('nombre', form.value.nombre);
    formData.append('apellidos', form.value.apellidos);
    formData.append('correo', form.value.correo);
  
    this._suscripcionService.nuevaSuscripcion(formData).subscribe(
      response => {
        if(response.status == 'success'){
          Swal.fire('Muy bien',response.message,'success');
        }
      },
      errorResponse => {
        Swal.fire('UPS',errorResponse.error.message,'error');
      }
    );
  }

}

