import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { global } from './global';

@Injectable({
  providedIn: 'root'
})
export class CuentasBancariasService {

    public url: string;
  
    constructor(
      private _http: HttpClient
    ) { 
  
      this.url = global.url;
  
    }

    getCuentas(token):Observable<any>{
        let headers = new HttpHeaders().set('Authorization', token);
        return this._http.get(this.url+'user/cuentas-bancarias',{headers: headers});
    }

    updateCuenta(token, form):Observable<any>{
        let headers = new HttpHeaders().set('Authorization', token);
        return this._http.post(this.url+'user/update-cuenta-bancaria', form, {headers: headers});
    }

  }