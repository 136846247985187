import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { global } from './global';

@Injectable({
  providedIn: 'root'
})
export class FeedService {
    public url: string;

    constructor(private _http: HttpClient) {
      this.url = global.url;
    }

    getCuidadores(token, cuidador, edad, experiencia, tipo, ciudad, categoria): Observable<any> {
      let headers = new HttpHeaders().set('Authorization', token);
      let city = ciudad == '' ? '-1' : encodeURIComponent(ciudad);
      return this._http.get(this.url+'user/cuidadores-feed2/'+cuidador+'/'+edad+'/'+experiencia+'/'+tipo+'/'+city+'/'+categoria,{headers: headers});
    }

    getOfertas(token, oferta, edad, experiencia, ciudad, categoria): Observable<any> {
      let headers = new HttpHeaders().set('Authorization', token);
      let city = ciudad == '' ? '-1' : encodeURIComponent(ciudad);
      return this._http.get(this.url+'user/ofertas-feed/'+oferta+'/'+edad+'/'+experiencia+'/'+city+'/'+categoria,{headers: headers});
    }

    enviarInvitacion(token_cuidador, token_vacante, token): Observable<any> {
      let params = new FormData();
      params.append('token_cuidador', token_cuidador);
      params.append('token_vacante', token_vacante);
      let headers = new HttpHeaders().set('Authorization', token);
      return this._http.post(this.url+'user/enviar-invitacion', params, {headers: headers});
    }

    /* getOfertas2(token): Observable<any>{
      let headers = new HttpHeaders().set('Authorization', token);
      return this._http.get(this.url+'user/ofertas-feed/todos/todos/0/todos/todos', {headers: headers});
    } */
  }