export class Cadian{
    constructor(
        public id: number,
        public nombre: string,
        public apellidos: string,
        public key_provincia: number,
        public key_municipio: number,
        public key_sector: number,
        public key_servicios: string,
        public numero: number,
        public direccion: string,
        public key_tipo_cadian: number,
        public key_genero: number,
        public correo: string,
        public telefono: string,
        public foto: string,
        public key_dni: string,
        public foto_dni: string,
        public foto_dni_comprobacion: string,
        public key_formacion: string,
        public key_idiomas: string,
        public key_uso_pc: number,
        public key_forma_pago: number,
        public estatus: number,
        public certificados: any[],
        public servicios: any[],
        public acerca_de_mi: string,
        public puesto: string,
        public dias: any[],
        public horarios: any[],
        public check_autorizacion: number,
        public habilidades: any[],
        public carta_no_antecedentes: string,
        public comprobante_factura: string,
        public tarifa_hora: number,
        public tarifa_hora2: number,
        public tarifa_hora3: number,
        public fecha_nacimiento: string,
        public key_aptitudes: any[],
        public key_experiencia: string,
        public facebook: string,
        public instagram: string,
        public tarifa_mensual: number,
        public codigo_postal: number,
        public ser_autonomo?: number
    ){}
}