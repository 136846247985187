import { Component, OnInit, ViewEncapsulation, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { AddressComponent } from '../../../shared/components/address/address.component';
import { Cadian } from 'src/app/models/cadian';
import { CadianService } from 'src/app/services/cadian.service';
import { PerfilCadianComponent } from '../perfil-cadian.component';
import { TiposCadianService } from 'src/app/services/tipos_cadian.service';
import { global } from '../../../services/global';
import { FileUploadControl, FileUploadValidators } from '@iplab/ngx-file-upload';
import { faDotCircle } from '@fortawesome/free-solid-svg-icons';
import { IDayCalendarConfig } from 'ng2-date-picker';
import { ScriptService } from 'src/app/services/script.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import * as UIkit from 'uikit';
declare let $: any;

@Component({
  selector: 'app-mi-cuenta2',
  templateUrl: './mi-cuenta.component.html',
  styleUrls: ['../perfil-cadian.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MiCuentaComponent2 implements OnInit, AfterViewInit, OnDestroy {
  public cadian : Cadian;
  public identity;
  public token;
  public details;
  public status: string;
  public municipios;
  public provincias;
  public distritos;
  public fotos_dni = [];
  public dropdownList: any[];
  public selectedItems = [];
  public horarios = [];
  public dropdownSettings: any;
  public dia_selected;
  public percent_progress;
  public sectores;
  public tipos_cadian;
  public password;
  public password2;
  public currentPassword;
  public uploadedFiles: Array<File> = [];
  public faDotCircle = faDotCircle;
  public years_card = [];

  public config = <IDayCalendarConfig>{
    locale: "es",
    format: "DD-MM-YYYY",
    showSeconds: false
  };
  
  public config2 = <IDayCalendarConfig>{
    locale: "es",
    format: "HH:mm",
    showSeconds: false
  };
  public new_card = {
    nombre_titular : '',
    numero_tarjeta: '',
    mes_vencimiento: '',
    ano_vencimiento: ''
  }

  // Cadian required fields to get 100 percent profile progress
  private requiredFields: any = {
    // Cadian data
    profile_img: {field: 'cadian.foto', filled: false},
    gender: {
      field: 'cadian.key_genero',
      filled: false,
      popover_content: '#gender_and_birthdate_required_popover_content',
      popover_to: '#gender_and_birthdate_field'
    },
    birth_date: {
      field: 'cadian.fecha_nacimiento',
      filled: false,
      popover_content: '#gender_and_birthdate_required_popover_content',
      popover_to: '#gender_and_birthdate_field'
    },
    fare1: {
      field: 'cadian.tarifa_hora',
      filled: false,
      popover_content: '#fares_required_popover_content',
      popover_to: '#fares_fields'
    },
    fare2: {
      field: 'cadian.tarifa_hora2',
      filled: false,
      popover_content: '#fares_required_popover_content',
      popover_to: '#fares_fields'
    },
    fare3: {
      field: 'cadian.tarifa_hora3',
      filled: false,
      popover_content: '#fares_required_popover_content',
      popover_to: '#fares_fields'
    },
    fare4: {
      field: 'cadian.tarifa_mensual',
      filled: false,
      popover_content: '#fares_required_popover_content',
      popover_to: '#fares_fields'
    },
    about_me: {
      field: 'cadian.acerca_de_mi',
      filled: false,
      popover_content: '#aboutme_required_popover_content',
      popover_to: '#msg_about_me2'
    },

    // Cadian address
    street: {
      field: 'addressData.street',
      filled: false,
      popover_content: '#address_required_popover_content',
      popover_to: '#google_maps_address2'
    },
    outside_number: {
      field: 'addressData.outsideNumber',
      filled: false,
      popover_content: '#address_required_popover_content',
      popover_to: '#google_maps_address2'
    },
    sector: {
      field: 'addressData.sector',
      filled: false,
      popover_content: '#address_required_popover_content',
      popover_to: '#google_maps_address2'
    },
    city: {
      field: 'addressData.city',
      filled: false,
      popover_content: '#address_required_popover_content',
      popover_to: '#google_maps_address2'
    },
    province: {
      field: 'addressData.province',
      filled: false,
      popover_content: '#address_required_popover_content',
      popover_to: '#google_maps_address2'
    },
    telephone: {
      field: 'cadian.telefono',
      filled: false,
      popover_content: '#address_required_popover_content',
      popover_to: '#google_maps_address2'
    },

    // Cadian documents
    dni_img: {field: 'cadian.foto_dni', filled: false},
    dni_img_verify: {field: 'cadian.foto_dni_comprobacion', filled: false}
  };

  @ViewChild('addressComponent', { static: false }) private addressComponent: AddressComponent;

  constructor(
    public _userService: UserService,
    public _cadianService: CadianService,
    public perfilCadianComponent: PerfilCadianComponent,
    public _tiposCadianService: TiposCadianService,
    public script: ScriptService,
    private _router: Router
  ) { 
    this.cadian = new Cadian(1,'','',1,1,1,'',1,'',1,1,'','','','','','','','',1,1,1,[],[],'','',[],[],0,[],'','',0,0,0,'',[],'','','',0,0);
    this.loadUser();

    this.dropdownSettings = {
      singleSelection: false,
      enableCheckAll: false,
      idField: 'dia',
      textField: 'dia_text',
      selectAllText: 'Seleccionar todo',
      unSelectAllText: 'Deseleccionar todo',
      itemsShowLimit: 5,
      allowSearchFilter: true
    };

    this.dropdownList = [
      {dia: 1, dia_text: 'Lunes'},
      {dia: 2, dia_text: 'Martes'},
      {dia: 3, dia_text: 'Miercoles'},
      {dia: 4, dia_text: 'Jueves'},
      {dia: 5, dia_text: 'Viernes'},
      {dia: 6, dia_text: 'Sabado'},
      {dia: 7, dia_text: 'Domingo'}
    ];
  }

  public ngOnInit(): void {
    this.getTiposCadian();
    this.getHorarios();
    this.checkModals();
  }

  public ngOnDestroy(): void {
    $('.element-with-popover2').popover('dispose');
  }

  private showRequieredFieldsPopovers(): void {
    for (let property in this.requiredFields) {
      if (property != 'profile_img' && property != 'dni_img' && property != 'dni_img_verify') {
        if (!this.requiredFields[property].filled) {
          $(this.requiredFields[property].popover_to).popover({
            html: true,
            container: 'body',
            content: $(this.requiredFields[property].popover_content).html(),
            placement: 'top',
            fallbackPlacement: ['top'],
            trigger: 'manual'
          });

          $(this.requiredFields[property].popover_to).popover('show');
        } else {
          $(this.requiredFields[property].popover_to).popover('dispose');
        }
      }
    }
  }

  public solicitarVerificacion(): void {
    this.refreshProgressBarPercent();

    if (this.details.check_autorizacion == 0 && this.percent_progress == 100 && this.details.verificacion_pagada == 1) {
      Swal.fire({
        title: 'Un momento',
        icon: 'info',
        text: 'Al aceptar estas afirmando que los datos ingresados son reales y que estos serán utilizados para procesar su depuración. Se te realizará un cobro de RD$1060.00 para iniciar el proceso de verificación.',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aceptar'
      }).then((result) => {
        if (result.value) {
          this.cadian.check_autorizacion = 1;
          this.updateCadian();
        }
      });
    } else if (this.details.verificacion_pagada == 0 && this.percent_progress == 100) {
      let modal = UIkit.modal("#modal-autorizacion");
      modal.show();
    } else if (this.percent_progress < 100) {
      Swal.fire('Un momento', 'Para avanzar en su proceso de verificación es necesario completar al 100% los datos de perfil y subir los documentos que se requieren.' , 'error');
    }
  }

  public updateCadian(): void {
    if (this.cadian.acerca_de_mi) {
      this.cadian.acerca_de_mi = this.cadian.acerca_de_mi.replace(/<.*?>/, "");
    }

    if (this.selectedItems.length > 0) {
      this.cadian.dias = this.selectedItems;
      this.cadian.horarios = this.horarios;
    }

    this._cadianService.update(this.token, this.getMergedCadian(this.cadian)).subscribe(
      (response: any) => {
        if (response.status == 'success') {
          Swal.fire('Datos actualizados con éxito', response.message , 'success');
          this.details = response.changes;
          localStorage.setItem('details', JSON.stringify(this.details));
          this._userService.detailsChangeEvent.emit();
          this.loadExtraUserData();
        }
      },
      (errorResponse: any) => {
        let errores = '';

        Object.keys(errorResponse.error.error).forEach((k: any) => {
          errores+= '- '+ errorResponse.error.error[k]+"<br>";
        });

        Swal.fire({
          title: 'UPS',
          icon: 'error',
          html: errores
        });
      }
    );
  }
  public updateContrasena() {
    if (this.currentPassword == undefined) {
      Swal.fire({
        title: 'UPS',
        icon: 'error',
        html: 'Si desea cambiar la contraseña, debe escribir su contraseña actual.'
      });
      return;
    } 

    if (this.password == undefined || this.password2 == undefined) {
      Swal.fire({
        title: 'UPS',
        icon: 'error',
        html: 'Si desea cambiar la contraseña, debe crear una nueva contraseña y confirmarla.'
      });
      return;
    } 

    if (this.password.length < 8) {  
      Swal.fire({
        title: 'UPS',
        icon: 'error',
        html: 'Error, la contraseña no debe ser menor a 8 caracteres.'
      });
      return;
    } 
    
    if (this.password != this.password2) {
      Swal.fire({
        title: 'UPS',
        icon: 'error',
        html: 'Error, las contraseñas no coinciden.'
      });
      return;
    } 

    if (this.password == this.password2) {
      Swal.fire({
        title: 'Cambiar contraseña',
        icon: 'info',
        text: '¿Deseas cambiar la contraseña de tu cuenta de usuario?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aceptar'
      }).then((result) => {
        if (result.value) {

          this._cadianService.updateContrasena(this.token, this.password, this.currentPassword).subscribe(
            response => {
              if (response.error == false) {
                Swal.fire('La constraseña se actualizo con éxito', response.message, 'success');
              }
            },
            error => {
              if (error.error.error_code == 1) {
                Swal.fire({
                  title: 'UPS',
                  icon: 'error',
                  html: 'Error, no se pudo cambiar la contraseña.<br>Su contraseña actual no es correcta'
                });
              } else {
                Swal.fire({
                  title: 'UPS',
                  icon: 'error',
                  html: 'Error, no se pudo cambiar la contraseña.<br>Intente de nuevo o verifique con su administrador'
                });
              }
            }
          );
        }
      });
    }
  }

  private getMergedCadian(currentCadian): any {
    return {
      acerca_de_mi: currentCadian.acerca_de_mi,
      apellidos: currentCadian.apellidos,
      carta_no_antecedentes: currentCadian.carta_no_antecedentes,
      certificados: currentCadian.certificados,
      check_autorizacion: currentCadian.check_autorizacion,
      comprobante_factura: currentCadian.comprobante_factura,
      correo: currentCadian.correo,
      dias: currentCadian.dias,
      estatus: currentCadian.estatus,
      facebook: currentCadian.facebook,
      fecha_nacimiento: currentCadian.fecha_nacimiento,
      foto: currentCadian.foto,
      fotos_dni: currentCadian.fotos_dni,
      foto_dni_comprobacion: currentCadian.foto_dni_comprobacion,
      habilidades: currentCadian.habilidades,
      horarios: currentCadian.horarios,
      id: currentCadian.id,
      instagram: currentCadian.instagram,
      key_aptitudes: currentCadian.key_aptitudes,
      key_dni: currentCadian.key_dni,
      key_experiencia: currentCadian.key_experiencia,
      key_forma_pago: currentCadian.key_forma_pago,
      key_formacion: currentCadian.key_formacion,
      key_genero: currentCadian.key_genero,
      key_idiomas: currentCadian.key_idiomas,
      key_servicios: currentCadian.key_servicios,
      key_tipo_cadian: currentCadian.key_tipo_cadian,
      key_uso_pc: currentCadian.key_uso_pc,
      nombre: currentCadian.nombre,
      numero: currentCadian.numero,
      puesto: currentCadian.puesto,
      servicios: currentCadian.servicios,
      tarifa_hora: currentCadian.tarifa_hora,
      tarifa_hora2: currentCadian.tarifa_hora2,
      tarifa_hora3: currentCadian.tarifa_hora3,
      tarifa_mensual: currentCadian.tarifa_mensual,
      telefono: currentCadian.telefono,
      street: this.addressComponent.addressData.street,
      outside_number: this.addressComponent.addressData.outsideNumber,
      sector: this.addressComponent.addressData.sector,
      city: this.addressComponent.addressData.city,
      province: this.addressComponent.addressData.province
    };
  }

  public ngAfterViewInit(): void {
    this.loadExtraUserData();
  }

  private loadUser(): void {
    this.identity = this._userService.getIdentity();
    this.token = this._userService.getToken();
  }

  private loadExtraUserData(): void {
    this._userService.getUserData(this._userService.getToken()).subscribe(
      (response: any) => {
        localStorage.setItem('details', JSON.stringify(response));
        this.details = this._userService.getDetails();

        this.cadian = new Cadian(
          this.details.id_cadian, 
          this.details.nombre, 
          this.details.apellidos,
          this.details.key_provincia,
          this.details.key_municipio,
          this.details.key_sector,
          this.details.key_servicios,
          this.details.numero,
          this.details.direccion,
          this.details.key_tipo_cadian,
          this.details.key_genero,
          this.details.correo,
          this.details.telefono,
          this.details.foto,
          this.details.key_dni,
          this.details.foto_dni,
          this.details.foto_dni_comprobacion,
          this.details.key_formacion,
          this.details.key_idiomas,
          this.details.key_uso_pc,
          this.details.key_forma_pago,
          this.details.estatus,
          [],
          [],
          this.details.acerca_de_mi,
          this.details.puesto,
          [],
          [],
          this.details.check_autorizacion,
          [],
          this.details.carta_no_antecedentes,
          this.details.comprobante_factura,
          this.details.tarifa_hora,
          this.details.tarifa_hora2,
          this.details.tarifa_hora3,
          this.details.fecha_nacimiento,
          this.details.key_aptitudes,
          this.details.key_experiencia,
          this.details.facebook,
          this.details.instagram,
          this.details.tarifa_mensual,
          this.details.codigo_postal,
          this.details.ser_autonomo
        );

        this.addressComponent.addressData.street = response.street;
        this.addressComponent.addressData.outsideNumber = response.outside_number;
        this.addressComponent.addressData.sector = response.sector;
        this.addressComponent.addressData.city = response.city;
        this.addressComponent.addressData.province = response.province;

        if (this.addressComponent.addressData.street != '') this.requiredFields.street.filled = true;
        if (this.addressComponent.addressData.outsideNumber != '') this.requiredFields.outside_number.filled = true;
        if (this.addressComponent.addressData.sector != '') this.requiredFields.sector.filled = true;
        if (this.addressComponent.addressData.city != '') this.requiredFields.city.filled = true;
        if (this.addressComponent.addressData.province != '') this.requiredFields.province.filled = true;

        if (this.cadian.foto != null) this.requiredFields.profile_img.filled = true;
        if (this.cadian.key_genero != null) this.requiredFields.gender.filled = true;
        if (this.cadian.fecha_nacimiento != null) this.requiredFields.birth_date.filled = true;
        if (this.cadian.tarifa_hora != null) this.requiredFields.fare1.filled = true;
        if (this.cadian.tarifa_hora2 != null) this.requiredFields.fare2.filled = true;
        if (this.cadian.tarifa_hora3 != null) this.requiredFields.fare3.filled = true;
        if (this.cadian.acerca_de_mi != null) this.requiredFields.about_me.filled = true;
        if (this.cadian.tarifa_mensual != null) this.requiredFields.fare4.filled = true;
        if (this.cadian.telefono != null) this.requiredFields.telephone.filled = true;
        if (this.cadian.foto_dni != null) this.requiredFields.dni_img.filled = true;
        if (this.cadian.foto_dni_comprobacion != null) this.requiredFields.dni_img_verify.filled = true;
        this.refreshProgressBarPercent();
      },
      (errorResponse: any) => {
        //...
      }
    );
  }

  public getHorarios(): void {
    this._cadianService.getHorarios(this.token).subscribe(
      (response: any) => {
        if (response.status == 'success') {
          this.selectedItems = JSON.parse(response.dias);
          this.horarios = JSON.parse(response.horario)

          if (this.selectedItems.length > 0) {
            this.dia_selected = this.selectedItems[0].dia;
          }
        }
      },
      (errorResponse: any) => {
        let errores = '';

        Object.keys(errorResponse.error.error).forEach((k: any) => {
          errores+= '- '+ errorResponse.error.error[k]+"<br>";
        });

        Swal.fire({
          title: 'UPS',
          icon: 'error',
          html: errores
        });
      }
    );
  }

  public onItemSelect($event: any): void {
    this.cadian.dias = this.selectedItems;
    
    this.horarios.push({
      'dia' : $event.dia,
      'horario': [['', '']]
    });

    this.dia_selected = $event.dia;
  }

  public onItemDeSelect($event: any): void {
    var index = this.horarios.map((x: any) => {return x.dia; }).indexOf($event.dia);

    if (index >= 0) {
      this.horarios.splice( index, 1 );
    }
  }

  public changeHorario(dia: any): void {
    this.dia_selected = dia;
  }

  public nuevoHorario(index: any): void {
    this.horarios[index].horario.push(['', '']);
  }

  public eliminarHorario(i: any, j: any): void {
    this.horarios[i].horario.splice(j, 1);
  }

  private refreshProgressBarPercent(): void {
    let fieldsFilled = 0;

    for (let property in this.requiredFields) {
      if (this.requiredFields[property].filled) {
        fieldsFilled++;
      }
    }

    let percent = (100 / Object.keys(this.requiredFields).length) * fieldsFilled;
    this.percent_progress = percent >= 100 ? 100 : Math.ceil(percent);
    this.showRequieredFieldsPopovers();
  }

  public getTiposCadian(): void {
    this._tiposCadianService.getTiposCadian().subscribe(
      (response: any) => {
        if (response.status == 'success') {
          this.tipos_cadian = response.tipos_cadian;
        }
      },
      (errorResponse: any) => {
        //...
      }
    );
  }

  public serAutonomo(): void {
    if (this.cadian.key_tipo_cadian == 1) {
      Swal.fire('UPS', 'Usted ya es un Cuidador Autónomo' , 'error');
      return;
    }

    this._cadianService.serAutonomo(this.token).subscribe(
      (response: any) => {
        if (response.status == 'success') {
          this.details.ser_autonomo = 1;
          localStorage.setItem('details', JSON.stringify(this.details));

          if (this.details.fecha_autorizacion == null || this.details.fecha_autorizacion == '') {
            Swal.fire('¡Muy bien!', 'Su cuenta de Cuidador sera revisada en un lapso de 24-48 horas para verificar que esta cumpla con los requisitos.' , 'success');
          } else {
            Swal.fire('¡Muy bien!', 'Su cuenta de cuidador ha cambiado a Autónomo.' , 'success');
          }

          this.cadian.key_tipo_cadian = 1;
          this.details = response.changes;
          localStorage.setItem('details', JSON.stringify(this.details));
        }
      },
      (errorResponse: any) => {
        if (errorResponse.error.message == 'documentos') {
          this.details.ser_autonomo = 1;
          localStorage.setItem('details', JSON.stringify(this.details));

          this._router.navigate(['/perfil-cadian/documentos']);
          Swal.fire('Un momento', 'Necesitas subir tu comprobante de facturas y carta no antecedentes penales' , 'info');
          let modal = UIkit.modal("#modal-cambio");
          modal.hide();
        } else {
          Swal.fire('¡UPS!', errorResponse.error.message , 'error');
        }
      }
    );
  }

  public pagarVerificacionBalance(): void {
    this._cadianService.pagarVerificacionBalance(this.token, this.cadian).subscribe(
      (response: any) => {
        if (response.status == 'success') {
          this.details = response.changes;
          localStorage.setItem('details', JSON.stringify(this.details));
          let modal = UIkit.modal("#modal-autorizacion");
          modal.hide();
          this.solicitarVerificacion();
        } else {
          Swal.fire('UPS', response.message , 'error');
        }
      },
      (errorResponse: any) => {
        //...
      }
    );
  }

  public agregarMetodoPago(): void {
    this._userService.agregarTarjeta(this.token, this.new_card).subscribe(
      (response: any) => {
        if (response.status == 'success') {
          this.script.loadScriptCapture('https://lab.cardnet.com.do/servicios/tokens/v1/Capture', response.UniqueID);
        }
      },
      (errorResponse: any) => {
        //...
      }
    );
  }

  public checkModals(): void {
    if ((localStorage.getItem('modal_vacantes') == '0' || localStorage.getItem('modal_vacantes') == undefined)) {
      let modal = UIkit.modal("#modal-vacantes");
      modal.show();
    }
  }

  public setlocalStorage(name: any, value: any): void {
    localStorage.setItem(name, value);
  }

  public checkAutonomo($event: any): void {
    const checked = $event.target.checked;

    if (checked && this.identity.role_user == 'CADIAN' && this.cadian.key_tipo_cadian != 1) {
      let modal = UIkit.modal("#modal-cambio");
      modal.show();
    } else {
      this.cadian.key_tipo_cadian = 2;
    }
  }

  public AddressChange(address: any) {
    this.cadian.direccion = address.formatted_address;
  }
}
