import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentService } from 'src/app/services/payment.service';
import { UserService } from 'src/app/services/user.service';
import Swal from 'sweetalert2';

@Component({
	selector: 'app-approved',
	templateUrl: './approved.component.html',
	styleUrls: ['./approved.component.css']
})
export class ApprovedComponent implements OnInit {
	public identity;
	public token;
	public details;

	constructor(
		private activatedRoute: ActivatedRoute,
		public _userService: UserService,
		public _paymentService: PaymentService,
		private _router: Router
	) {
		this.loadUser();

		this.activatedRoute.queryParams.subscribe((params: any) => {
			this.checkParams(params);
		});
	}

	public ngOnInit(): void {
		// ...
	}

	public loadUser(): void {
		this.identity = this._userService.getIdentity();
		this.token = this._userService.getToken();
		this.details = this._userService.getDetails();
	}

	public checkParams(params: any): void {
		this._paymentService.verifyTransaction(this.token, params).subscribe(
			(response: any) => {
				// ...
			},
			(error: any) => {
				// this._router.navigate(['/']);
			}
		);
	}

	public irAFeed(): void {
		if (this.identity.role_user == 'CADIAN') {
			this._router.navigate(['/perfil-cadian']);
		} else if (this.identity.role_user == 'CLIENTE') {
			this._router.navigate(['/perfil-cliente']);
		}
	}
}
