import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UserService } from '../../services/user.service';
import { Cadian } from '../../models/cadian';
import Swal from 'sweetalert2';
import * as UIkit from 'uikit';
import { HttpClient } from '@angular/common/http';
import { global } from '../../services/global';
import { ServiciosService } from 'src/app/services/servicios.service';
import { Router, ActivatedRoute } from '@angular/router';
import { faSearch, faStar, faHeart } from '@fortawesome/free-solid-svg-icons';
import { BalanceService } from 'src/app/services/balance.service';
import { ClienteService } from 'src/app/services/cliente.service';
import { DesbloqueoCuidadorService } from 'src/app/services/desbloqueoCuidador.service';
import { IDayCalendarConfig } from 'ng2-date-picker';
import * as moment from 'moment';
import { DistritosService } from 'src/app/services/distritos.service';
import { ProvinciasService } from 'src/app/services/provincias.service';
import { NgForm } from '@angular/forms';
import { ReservaService } from 'src/app/services/reserva.service';
import { AtencionesService } from 'src/app/services/atenciones.service';
import { AptitudesService } from 'src/app/services/aptitudes.service';
import { MunicipiosService } from 'src/app/services/municipios.service';
import { BuscarService } from 'src/app/services/buscar.service';
import { CadianService } from 'src/app/services/cadian.service';
import { FavoritoService } from 'src/app/services/favorito.service';
import { PostulacionesService } from 'src/app/services/postulaciones.service';
import { CargosService } from 'src/app/services/cargos.service';
import { RandomService } from 'src/app/services/random.service';

@Component({
  selector: 'app-buscar',
  templateUrl: './buscar.component.html',
  styleUrls: ['./buscar.component.css']
  // providers: [UserService, ServiciosService, BalanceService, ClienteService]
  // encapsulation: ViewEncapsulation.None
})

export class BuscarComponent implements OnInit {
  public identity;
  public token = null;
  public details;
  public status: string;
  public url;
  public resultados = [];
  public provincias;
  public distritos;
  p: number = 1;
  faSearch = faSearch;
  faHeart = faHeart;
  faStar = faStar;
  public children;
  public items = [];
  public selectedItems = [];
  public servicios = [];
  public habilidades = [];
  public balance;
  public aptitudes;
  public cadian_seleccionado: any = {};
  public cadian_pagado: boolean = false;
  public config = <IDayCalendarConfig>{
    locale: "es",
    min: moment(new Date()).format('DD-MM-YYYY'),
    format: "DD-MM-YYYY HH:mm",
    showSeconds: false
  };
  public atenciones;
  public collapse1: boolean = false;
  public select_experiencia: string;
  public select_aptitudes = [];
  public select_academico: string;
  public select_edad: string;
  public tipo_oferta = 1;
  public tipo_cuidador;
  public view: string = 'all';
  public fecha_cuidado;
  public fecha_cuidado_per;
  public municipios = [];
  public municipiosSettings;
  public municipio_selected;
  public carta_presentacion: boolean = false;
  public documentos: Array<File> = [];
  public documentos_subidos;
  public oferta_selected;
  public id;
  public busqueda: number = 1;
  public cadian_obj: any = {};
  public fechai;
  public fechaf;
  public total_estimado = 0;
  public datos_reserva = {
    atenciones: 0,
    direccion: '',
    descripcion: '',
    provincia: 0,
    municipio: 0
  };
  public cargos = [];
  public random;
  public tarifa_ofrecida: number = 0;
  public city: string = '';

  constructor(
    public _userService: UserService,
    public _buscarService: BuscarService,
    public _clienteService: ClienteService,
    public _servicioService: ServiciosService,
    public _balanceService: BalanceService,
    public _desbloqueoService: DesbloqueoCuidadorService,
    public _atencionesService: AtencionesService,
    public _provinciasService: ProvinciasService,
    public _distritosService: DistritosService,
    public _reservaService: ReservaService,
    public _aptitudesService: AptitudesService,
    public _municipiosService: MunicipiosService,
    public _cadianService: CadianService,
    public _router: Router,
    private _route: ActivatedRoute,
    public _favoritoService: FavoritoService,
    public _postulacionService: PostulacionesService,
    public _cargosService: CargosService,
    public _randomService: RandomService
  ) {
    this.mainInit();
  }

  ngOnInit() {
    this.getQuery();
  }

  mainInit() {
    this.token = null;
    this.resultados = [];
    this.p = 1;
    this.faSearch = faSearch;
    this.faHeart = faHeart;
    this.faStar = faStar;
    this.items = [];
    this.selectedItems = [];
    this.servicios = [];
    this.habilidades = [];
    this.cadian_seleccionado = {};
    this.cadian_pagado = false;
    this.config = <IDayCalendarConfig> {
      locale: "es",
      min: moment(new Date()).format('DD-MM-YYYY'),
      format: "DD-MM-YYYY HH:mm",
      showSeconds: false
    };
    this.collapse1 = false;
    this.select_aptitudes = [];
    this.tipo_oferta = 1;
    this.view = 'all';
    this.municipios = [];
    this.carta_presentacion = false;
    this.documentos = [];
    this.busqueda = 1;
    this.cadian_obj = {};
    this.total_estimado = 0;
    this.datos_reserva = {
      atenciones: 0,
      direccion: '',
      descripcion: '',
      provincia: 0,
      municipio: 0
    };
    this.cargos = [];
    this.tarifa_ofrecida = 0;
    this.url = global.url;
    this.loadDatosReserva();
    this.getBalance();

    this.municipiosSettings = {
      singleSelection: true,
      enableCheckAll: false,
      idField: 'id_municipio',
      textField: 'nombre',
      selectAllText: 'Seleccionar todo',
      unSelectAllText: 'Deseleccionar todo',
      itemsShowLimit: 1,
      allowSearchFilter: true
    };

    this.oferta_selected = {
      id: '',
      titulo: '',
      descripcion: '',
      key_servicio: '',
      key_habilidades: '',
      experiencia: '',
      horario_inicio: '',
      horario_fin: '',
      sueldo: '',
      tarifa: '',
      beneficios: '',
      direccion: '',
      responsabilidades: '',
      descanso: '',
      nombre_servicio: '',
      habilidades: '',
      tipo: '',
      foto: null,
      nombre: '',
      dias_trabajo: '',
      fecha_cuidado: '',
      fecha_registro: '',
      favorito: '',
      aptitudes: ''
    }

    this.loadUser();
    this.getServiciosHabilidades();
    this.getProvincias();
    this.getDistritos();
    this.getAtenciones();
    this.getAptitudes();
    this.getMunicipios();
    this.getCargos();
  }

  ngDoCheck() {
    //...
  }

  loadUser() {
    this.identity = this._userService.getIdentity();
    this.token = this._userService.getToken();
    this.details = this._userService.getDetails();
  }

  loadDatosReserva() {
    const datos = this._buscarService.getDatosReserva();
    if (datos != null) {
      this.datos_reserva = this._buscarService.getDatosReserva();
      this.getDistritos();
    }
  }

  getAptitudes() {
    this._aptitudesService.getAptitudes().subscribe(
      response => {

        if (response.status == 'success') {
          this.aptitudes = response.aptitudes;
        }

      },
      errorResponse => {
        // ...
      }
    );
  }

  getCargos() {
    this._cargosService.getCargos().subscribe(
      response => {
        if (response.status == 'success') {
          this.cargos = response.cargos;
        }
      },
      errorResponse => {
        // ...
      }
    );
  }

  getServiciosHabilidades() {

    this._servicioService.getServiciosHabilidades().subscribe(
      response => {

        if (response.status == 'success') {
          this.habilidades = response.subcategorias;
        }

      },
      errorResponse => {
        let errores = '';
        Object.keys(errorResponse.error.error).forEach(function (k) {
          errores += '- ' + errorResponse.error.error[k] + "<br>";
        });

        Swal.fire({
          title: 'UPS',
          icon: 'error',
          html: errores
        });
      }
    );

  }

  public buscar(): void {
    let params = [];
    this.selectedItems.forEach((obj: any) => { params.push(JSON.stringify(+obj)); });
    let queryString = params.join(",");
    let parameters_string = '';

    if (params.length > 0) {
      parameters_string = (parameters_string != '') ?
        parameters_string += "&cad=" + JSON.stringify(queryString) :
        parameters_string += "?cad=" + JSON.stringify(queryString);
    }

    if (this.random != undefined) {
      parameters_string = (parameters_string != '') ?
        parameters_string += "&random=" + this.random :
        parameters_string += "?random=" + this.random;
    }

    if (this.busqueda != 0 && this.busqueda != undefined) {
      parameters_string = (parameters_string != '') ?
        parameters_string += "&b=" + this.busqueda :
        parameters_string += "?b=" + this.busqueda;
    }

    if (this.select_experiencia != '' && this.select_experiencia != undefined) {
      parameters_string = (parameters_string != '') ?
        parameters_string += "&exp=" + this.select_experiencia :
        parameters_string += "?exp=" + this.select_experiencia;
    }

    if (this.select_academico != '' && this.select_academico != undefined) {
      parameters_string = (parameters_string != '') ?
        parameters_string += "&acd=" + this.select_academico :
        parameters_string += "?acd=" + this.select_academico;
    }

    if (this.select_edad != '' && this.select_edad != undefined) {
      parameters_string = (parameters_string != '') ?
        parameters_string += "&edad=" + this.select_edad :
        parameters_string += "?edad=" + this.select_edad;
    }

    if (this.select_aptitudes.length > 0 && this.select_aptitudes != undefined) {
      parameters_string = (parameters_string != '') ?
        parameters_string += "&apt=" + this.select_aptitudes.join() :
        parameters_string += "?apt=" + this.select_aptitudes.join();
    }

    if (this.tipo_cuidador != undefined) {
      parameters_string = (parameters_string != '') ?
        parameters_string += "&tc=" + this.tipo_cuidador :
        parameters_string += "?tc=" + this.tipo_cuidador;
    }

    if (this.tipo_oferta != undefined) {
      parameters_string = (parameters_string != '') ?
        parameters_string += "&to=" + this.tipo_oferta :
        parameters_string += "?to=" + this.tipo_oferta;
    }

    if (this.view != undefined) {
      parameters_string = (parameters_string != '') ?
        parameters_string += "&view=" + this.view :
        parameters_string += "?view=" + this.view;
    }

    if (this.fecha_cuidado != undefined && (this.fecha_cuidado_per == undefined || this.fecha_cuidado_per == '')) {
      parameters_string = (parameters_string != '') ?
        parameters_string += "&fec=" + this.fecha_cuidado :
        parameters_string += "?fec=" + this.fecha_cuidado;
    }
    else if (this.fecha_cuidado_per != undefined && this.fecha_cuidado_per != '') {
      parameters_string = (parameters_string != '') ?
        parameters_string += "&fec=" + this.fecha_cuidado_per :
        parameters_string += "?fec=" + this.fecha_cuidado_per;
    }

    /* if (this.municipio_selected != undefined) {
      parameters_string = (parameters_string != '') ?
        parameters_string += "&cit=" + this.municipio_selected[0].id_municipio :
        parameters_string += "?cit=" + this.municipio_selected[0].id_municipio;
    } */

    if (this.city != '') {
      parameters_string = (parameters_string != '') ?
        parameters_string += "&cit=" + encodeURIComponent(this.city) :
        parameters_string += "?cit=" + encodeURIComponent(this.city);
    }

    if (this.id != undefined) {
      parameters_string = (parameters_string != '') ?
        parameters_string += "&id=" + this.id :
        parameters_string += "?id=" + this.id;
    }

    window.location.href = "/buscar" + parameters_string;

    // this._router.navigate(['/buscar'], {queryParams: {x: 'hello', a: true}});
  }

  isChecked($event) {

    if ($event.target.checked && !this.selectedItems.includes(+$event.target.value)) {
      this.selectedItems.push(+$event.target.value);
    }
    else {
      let index = this.selectedItems.indexOf(+$event.target.value);
      if (index !== -1) {
        this.selectedItems.splice(index, 1);
      }
    }

  }

  getQuery() {
    let query = [];

    this._route.queryParams.subscribe((p: any) => {
      // This make a reset to all main variables when the url in the browser changes
      // for example when a new query params have been set.
      this.mainInit();

      if (p.cad) {
        let cad = JSON.parse(p.cad);
        query['cad'] = [cad];
      }

      if (p.exp) {
        query['exp'] = [p.exp];
        this.select_experiencia = p.exp;
      }

      if (p.acd) {
        query['acd'] = [p.acd];
        this.select_academico = p.acd;
      }

      if (p.apt) {
        query['apt'] = [p.apt];
        this.select_aptitudes = p.apt.split(',');
      }

      if (p.edad) {
        query['edad'] = [p.edad];
        this.select_edad = p.edad;
      }

      if (p.id) {
        query['id'] = [p.id];
        this.id = p.id;
      }

      if (p.tc) {
        query['tc'] = [p.tc];
        this.tipo_cuidador = p.tc;
      }

      if (p.to) {
        query['to'] = [p.to];
        this.tipo_oferta = p.to;
      }

      if (p.view) {
        query['view'] = [p.view];
        this.view = p.view;
      }

      if (p.cit) {
        // query['cit'] = [p.cit];
        // this.municipio_selected = p.cit;
        query['cit'] = p.cit;
        this.city = decodeURIComponent(p.cit);
      }

      if (p.b) {
        query['b'] = [p.b];
        this.busqueda = p.b;
      }

      if (p.random) {
        query['random'] = [p.random];
        this.random = p.random;
      }

      if (p.fec) {
        query['fec'] = [p.fec];
        if (p.fec == 'hoy' || p.fec == 'mañana') {
          this.fecha_cuidado = p.fec;
        } else {
          this.fecha_cuidado_per = p.fec;
        }
      }

      let formData = new FormData();
      formData.append('cad', query['cad']);
      formData.append('exp', query['exp']);
      formData.append('acd', query['acd']);
      formData.append('apt', query['apt']);
      formData.append('edad', query['edad']);
      formData.append('to', query['to']);
      formData.append('tc', query['tc']);
      formData.append('view', query['view']);
      formData.append('fecha', query['fec']);
      formData.append('cit', query['cit']);
      formData.append('id', query['id']);
      formData.append('busqueda', query['b']);
      formData.append('random', query['random']);

      if (this.busqueda == 1) {
        this._buscarService.buscarCadians(formData).subscribe(
          response => {
            if (response.status == 'success') {
              this.resultados = response.resultado;
              this.servicios = response.servicios_selected;

              if (query['cad']) {
                let value_query = query['cad'].toString();
                this.selectedItems = value_query.split(',').map(Number);
              }

              Object.values(this.servicios).forEach((k) => {
                this.items.push({
                  id: k.id_servicio,
                  text: k.nombre,
                  children: response.subcategorias[k.id_servicio]
                });
              });
            }
          },
          errorResponse => {
            //...
          }
        );
      } else if (this.busqueda == 2) {
        this._buscarService.buscarClientes(formData).subscribe(
          response => {
            if (response.status == 'success') {
              this.resultados = response.resultado;
              this.servicios = response.servicios_selected;

              if (query['cad']) {
                let value_query = query['cad'].toString();
                this.selectedItems = value_query.split(',').map(Number);
              }

              Object.values(this.servicios).forEach((k) => {
                this.items.push({
                  id: k.id_servicio,
                  text: k.nombre,
                  children: response.subcategorias[k.id_servicio]
                });
              });
            }
          },
          errorResponse => {
            // ...
          }
        );
      }
    });
  }

  resultsPageChange(page, element) {
    element.scrollIntoView({behavior: 'smooth', block: 'center'});
    this.p = page;
  }

  public confirmAcceptRandomReserva(randomReservaId: number): void {
    Swal.fire({
      title: '¿Aceptar oferta?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.acceptRandomReserva(randomReservaId);
      }
    });
  }

  public acceptRandomReserva(randomReservaId: number): void {
    let jsonData = {
      random_reserva_id: randomReservaId,
      option: 1
    };

    this._reservaService.acceptOrDenyRandomReserva(this.token, jsonData).subscribe(
      (response: any) => {
        Swal.fire({
          icon: 'success',
          title: 'Oferta aceptada',
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          confirmButtonText: 'Aceptar',
        });

        this._router.navigate(['/']);
      },
      (error: any) => {
        Swal.fire({
          icon: 'error',
          title: '¡UPS! Algo salio mal',
          html: 'Intenta de nuevo y si el problema sigue contacte a soporte'
        });
      }
    );
  }

  public confirmDenyRandomReserva(randomReservaId: number): void {
    Swal.fire({
      title: '¿Rechazar oferta?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.denyRandomReserva(randomReservaId);
      }
    });
  }

  public denyRandomReserva(randomReservaId: number): void {
    let jsonData = {
      random_reserva_id: randomReservaId,
      option: 0
    };

    this._reservaService.acceptOrDenyRandomReserva(this.token, jsonData).subscribe(
      (response: any) => {
        Swal.fire({
          icon: 'info',
          title: 'Oferta rechazada',
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          confirmButtonText: 'Aceptar',
        });

        this._router.navigate(['/']);
      },
      (error: any) => {
        Swal.fire({
          icon: 'error',
          title: '¡UPS! Algo salio mal',
          html: 'Intenta de nuevo y si el problema sigue contacte a soporte'
        });
      }
    );
  }

  limpiar() {
    this.selectedItems = [];
    this.city = '';
  }

  explode(string) {
    return string.split(",");
  }

  checked(id) {

    if (this.selectedItems.includes(id)) {
      return true;
    }
    else {
      return false;
    }

  }

  getBalance() {

    this._balanceService.getBalanceByUser(this.token).subscribe(
      response => {

        if (response.status == 'success') {
          this.balance = response.balance;
        }

      },
      errorResponse => {
        // ...
      }
    );

  }

  reservar() {
    if (this.identity != null) {
      let modal = UIkit.modal("#modal2");
      modal.show();
    }
  }

  pagarDesbloqueo(event: any) {

    //DESHABILITAMOS EL BOTON
    event.target.disabled = true;

    //VERIFICAMOS SI PUBLICO UNA OFERTA
    if (this.cadian_seleccionado == 0) {
      return;
    }

    let form = new FormData();
    form.append('id_cadian', this.cadian_seleccionado);

    Swal.fire({
      title: 'Un momento',
      icon: 'info',
      text: 'Confirmanos que quieres pagar el desbloqueo de este cuidador',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Pagar'
    }).then((result) => {
      if (result.value) {
        this._clienteService.pagarDesbloqueoBalance(this.token, form).subscribe(
          response => {

            if (response.status == 'success') {
              Swal.fire('¡Muy bien!', response.message, 'success');
              this._router.navigate(['/mensajeria/' + response.token_conversacion]);
            }
            else {
              Swal.fire('UPS', response.message, 'error');
            }

          },
          errorResponse => {
            // ...
          }
        );
      }
    });

  }

  contactar($event) {

    let form = new FormData();
    form.append('id_cadian', this.cadian_seleccionado);

    //CONSULTAMOS SI EL CUIDADOR A CONTACTAR YA FUE CONTACTADO ANTERIORMENTE
    this._desbloqueoService.checkDesbloqueo(this.token, form).subscribe(
      response => {

        if (response.status == 'success') {
          this.cadian_pagado = true;
          this._router.navigate(['/mensajeria/' + response.token_conversacion]);
        }
        else {
          this.cadian_pagado = false;
          let modal = UIkit.modal("#modal1");
          modal.show();
        }

      },
      errorResponse => {
        // ...
      }
    );
  }

  isOnline(user): boolean {

    let flag = false;

    this._userService.isOnline(user, this.token).subscribe(
      response => {
        if (response.message == 'online') {
          flag = true;
        }
      },
      errorResponse => {
        // ...
      }
    );

    return flag;


  }

  getProvincias() {
    this._provinciasService.getProvincias().subscribe(
      response => {

        if (response.status == 'success') {
          this.provincias = response.provincias;
        }

      },
      errorResponse => {
        // ...
      }
    );
  }

  getMunicipios() {
    this._municipiosService.getMunicipios().subscribe(
      response => {

        if (response.status == 'success') {
          this.municipios = response.municipios;

          if (this.municipio_selected != undefined) {
            this.municipio_selected = this.municipios.filter(e => e.id_municipio == this.municipio_selected);
          }
        }

      },
      errorResponse => {
        // ...
      }
    );
  }

  getDistritos($event = null, change = false) {

    let provincia = 0;

    if (change) {
      provincia = $event.target.selectedOptions[0].value;
    }

    this._distritosService.getDistritosByProvincia(provincia).subscribe(
      response => {

        if (response.status == 'success') {
          this.distritos = response.distritos;
        }

      },
      errorResponse => {

        let errores = '';
        Object.keys(errorResponse.error.error).forEach(function (k) {
          errores += '- ' + errorResponse.error.error[k] + "<br>";
        });

        Swal.fire({
          title: 'UPS',
          icon: 'error',
          html: errores
        });

      }
    );

  }

  getAtenciones() {
    this._atencionesService.getAtenciones().subscribe(
      response => {

        if (response.status == 'success') {
          this.atenciones = response.atenciones;
        }

      },
      errorResponse => {
        // ...
      }
    );
  }

  onSubmit(form: NgForm) {

    let formData = new FormData();
    formData.append('id_cuidador', this.cadian_seleccionado);
    formData.append('direccion', form.value.direccion);
    formData.append('descripcion', form.value.descripcion);
    formData.append('provincia', form.value.provincia);
    formData.append('ciudad', form.value.ciudad);
    formData.append('fecha_reserva_inicio', form.value.fecha_reserva_inicio);
    formData.append('fecha_reserva_final', form.value.fecha_reserva_final);
    formData.append('atenciones', form.value.atenciones);

    this._reservaService.enviarReserva(this.token, formData).subscribe(
      response => {

        if (response.status == 'success') {
          Swal.fire('¡Muy bien!', response.message, 'success');

          this.datos_reserva.atenciones = form.value.atenciones;
          this.datos_reserva.direccion = form.value.direccion;
          this.datos_reserva.descripcion = form.value.descripcion;
          this.datos_reserva.municipio = form.value.ciudad;
          this.datos_reserva.provincia = form.value.provincia;

          localStorage.setItem('datos_reserva', JSON.stringify(this.datos_reserva));

          let modal = UIkit.modal("#modal2");
          modal.hide();

          if (this.identity.role_user = 'CLIENTE') {
            this._router.navigate(['/perfil-cliente/reservas']);
          }
          else {
            this._router.navigate(['/perfil-cadian/reservas']);
          }

        }

      },
      errorResponse => {
        let errores = '';
        Object.keys(errorResponse.error.error).forEach(function (k) {
          errores += '- ' + errorResponse.error.error[k] + "<br>";
        });

        Swal.fire({
          title: 'UPS',
          icon: 'error',
          html: errores
        });
      }
    );
  }

  selectExperiencia(experiencia) {
    this.select_experiencia = experiencia;
  }

  selectAcademico(academico) {
    this.select_academico = academico;
  }

  selectEdad(edad) {
    this.select_edad = edad;
  }

  selectAptitudes(aptitud) {
    if (this.select_aptitudes.includes(aptitud)) {
      let index = this.select_aptitudes.indexOf(aptitud);
      this.select_aptitudes.splice(index);
    }
    else {
      this.select_aptitudes.push(aptitud);
    }
  }

  dia(numero) {
    numero--;
    let dias = ['Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo'];
    return dias[numero];
  }

  parseHorario(str) {
    return JSON.parse(str);
  }

  shortDesc(text) {

    let text_replace = text.replace(/<(.|\n)*?>/g, "");
    let text_array = text_replace.split(' ');
    let short_text = '';

    if (text_array.length == 0) {
      return text;
    }

    for (let i = 0; i <= text_array.length; i++) {
      if (i == 35 || text_array[i] == undefined) {
        return short_text + ' ';
      }

      short_text += text_array[i] + ' ';
    }
  }

  diasTrabajo(str) {

    let explode = str.split(',');
    let length = explode.length;

    return explode[0] + '-' + explode[length - 1];

  }

  verOferta(obj) {

    this.carta_presentacion = false;
    this.oferta_selected = obj;

    let modal = UIkit.modal("#modal-oferta");
    modal.show();
  }

  onSubmitPostulacion() {
    let cargo = 0;
    let tipo = "";
    let string_title;

    if (this.oferta_selected.tipo == 'RESERVA') {
      cargo = this.oferta_selected.verificados == 0 ? this.cargos[27].costo : this.cargos[29].costo;
      tipo = "reserva";
    } else {
      if (this.oferta_selected.key_tipo_cadian == 2) {
        cargo = this.oferta_selected.verificados == 0 ? this.cargos[27].costo : this.cargos[29].costo;
      } else {
        cargo = this.oferta_selected.verificados == 0 ? this.cargos[28].costo : this.cargos[30].costo;
      }

      tipo = "oferta";
    }

    let formData = new FormData();
    // formData.append('carta', form.value.carta);
    formData.append('oferta', this.oferta_selected.id);
    formData.append('tipo', this.oferta_selected.tipo);
    formData.append('verificados', this.oferta_selected.verificados);
    formData.append('documentos', this.documentos_subidos);

    Swal.fire({
      title: 'Un momento',
      icon: 'info',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Estoy de acuerdo',
      onOpen: () => { Swal.showLoading(); }
    }).then((result) => {
      if (result.value) {
        this.sendPostulation(formData);
      }
    });

    this._cadianService.getPrice(this.token, this.oferta_selected.id, tipo).then(
      (response: any) => {
        setTimeout(() => {
          string_title = 'Se te realizara un cargo de RD$' + cargo + ' para postularte a esta oferta. ';
          Swal.hideLoading();

          Swal.update({
            showConfirmButton: true,
            showCancelButton: true,
            text: string_title
          });
        }, 500);
      },
      (error: any) => {
        setTimeout(() => {
          string_title = '¿Desea postularse en esta oferta? ';
          Swal.hideLoading();

          Swal.update({
            showConfirmButton: true,
            showCancelButton: true,
            text: string_title
          });
        }, 500);
      }
    );
  }

  private sendPostulation(formData: FormData): void {
    this._cadianService.enviarPostulacion(this.token, formData).subscribe(
      (response: any) => {
        if (response.status == 'success') {
          Swal.fire({
            title: '¡Muy bien!',
            text: response.message,
            icon: 'success'
          }).then((result) => {
            if (result.value) {
              window.location.href = '/buscar?b=' + this.busqueda;
            }
          })
        }
      },
      (error: any) => {
        Swal.fire({
          title: 'UPS',
          icon: 'error',
          text: error.error.message
        });
      }
    );
  }

  subirDocumentos() {

    if (this.documentos.length == 0) {
      Swal.fire('¡UPS!', 'Sube minimo 1 documento para la postulación.', 'error');
      return;
    }

    let formData = new FormData();

    for (let i = 0; i < this.documentos.length; i++) {
      formData.append('documentos[' + i + ']', this.documentos[i], this.documentos[i].name);
    }
    formData.append('oferta', this.oferta_selected.id);
    formData.append('tipo', this.oferta_selected.tipo);

    Swal.fire({
      title: 'Subiendo documentos...',
      icon: 'info',
      text: '',
      showCloseButton: false,
      showCancelButton: false,
      showConfirmButton: false
    });

    this._postulacionService.subirDocumentos(this.token, formData).subscribe(
      response => {
        if (response.status == 'success') {
          Swal.fire('¡Muy bien!', 'Documentos subidos con éxito.', 'success');
          this.documentos_subidos = response.documentos;
        }
      },
      errorResponse => {
        Swal.fire({
          title: 'UPS',
          icon: 'error',
          text: errorResponse.error.message
        });
      }
    );

  }

  favorito(obj) {
    this._favoritoService.favorito(this.token, obj.tipo, obj.id).subscribe(
      response => {
        if (response.status == 'success') {
          if (response.accion == 'agregado') {
            obj.favorito = obj.id;
          }
          else if (response.accion == 'eliminado') {
            obj.favorito = null;
          }
        }
      },
      errorResponse => {
        Swal.fire({
          title: 'UPS',
          icon: 'error',
          text: errorResponse.error.message
        });
      }
    );
  }

  postulacionModal(oferta) {
    if (oferta.tipo == 'RESERVA' && (this.details.fecha_autorizacion == null || this.details.fecha_autorizacion == '')) {
      Swal.fire('¡UPS!', 'Para postularse a reservas por horas necesitas ser Cuidador Autónomo y tener cuenta verificada', 'error');
      return;
    }

    this.oferta_selected = oferta;
    if (this.identity != null) {
      // let modal = UIkit.modal("#modal-postularse");
      // modal.show();
      this.onSubmitPostulacion()
    }
    else {
      Swal.fire('¡UPS!', 'Ingresa como Cuidador para postularte a esta oferta', 'error');
    }
  }

  calculoTotal(tipo, $event) {
    if (tipo == 'fechai') {
      this.fechai = $event;
    }
    else if (tipo == 'fechaf') {
      this.fechaf = $event;
    }

    if (this.fechai != undefined && this.fechaf != undefined) {
      var a = moment(this.fechai, 'DD-MM-YYYY HH:mm:ss');//now
      var b = moment(this.fechaf, 'DD-MM-YYYY HH:mm:ss');
      var duration = moment.duration(b.diff(a));
      const hours = duration.asHours();

      this.total_estimado = parseFloat(this.cadian_obj.tarifa_hora) * parseInt(hours.toString());
    }
  }

  horarioFormat(str) {
    const horas = str.split(':');
    if (horas.length > 1) {
      return horas[0] + ':' + horas[1];
    }
    else {
      return 'Sin definir';
    }
  }

  ofertarRandom(key_oferta) {

    let form = new FormData();
    form.append('key_oferta', key_oferta);
    form.append('tarifa', this.tarifa_ofrecida.toString());

    this._randomService.ofertar(this.token, form).subscribe(
      response => {
        if (response.status == 'success') {
          Swal.fire('¡Muy bien!', response.message, 'success');
          this._router.navigate(['/perfil-cadian/reservas']);
        }
      },
      errorResponse => {
        Swal.fire({
          title: 'UPS',
          icon: 'error',
          text: errorResponse.error.message
        });
      }
    );
  }

  guest() {
    if (this.identity == null) {
      this._router.navigate(['/login']);
      let modal = UIkit.modal("#modal2");
      modal.hide();
    }
  }

  verHorarios(cadian) {
    this.cadian_seleccionado = cadian;
    let modal = UIkit.modal("#modal-horarios");
    modal.show();
  }

  verHorarios2(oferta) {
    this.oferta_selected = oferta;
    let modal = UIkit.modal("#modal-horarios2");
    modal.show();
  }
}