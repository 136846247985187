import { Component, OnInit, ViewEncapsulation, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import Swal from 'sweetalert2';
import { Cliente } from 'src/app/models/cliente';
import { ClienteService } from 'src/app/services/cliente.service';
import { UserService } from 'src/app/services/user.service';
import { ServiciosService } from 'src/app/services/servicios.service';
import { NgForm } from '@angular/forms';
import * as UIkit from 'uikit';
import * as moment from 'moment';
import { AddressComponent } from '../../../shared/components/address/address.component';
import { IDayCalendarConfig } from 'ng2-date-picker';
import { faBriefcase, faCalendar } from '@fortawesome/free-solid-svg-icons';
import { AtencionesService } from 'src/app/services/atenciones.service';
import { ProvinciasService } from 'src/app/services/provincias.service';
import { DistritosService } from 'src/app/services/distritos.service';
import { ReservaService } from 'src/app/services/reserva.service';
import { AptitudesService } from 'src/app/services/aptitudes.service';
import { CargosService } from 'src/app/services/cargos.service';
import { MunicipiosService } from 'src/app/services/municipios.service';
import { MembresiasService } from 'src/app/services/membresias.service';
import { global } from '../../../services/global';
import { Router } from '@angular/router';
declare let $: any;

@Component({
  selector: 'app-nueva-oferta',
  templateUrl: './nueva-oferta.component.html',
  styleUrls: ['./nueva-oferta.component.css']
  // providers: [UserService, ClienteService, ServiciosService]
})
export class NuevaOfertaComponent implements OnInit, AfterViewInit {
  @ViewChild('addressComponent', { static: false }) private addressComponent: AddressComponent;
  public faBriefcase = faBriefcase;
  public faCalendar = faCalendar;
  public cliente: Cliente;
  public identity;
  public token;
  public details;
  public titulo;
  public descripcion;
  public servicio;
  public habilidades;
  public tiempo_experiencia;
  public hora_entrada;
  public hora_salida;
  public sueldo;
  public beneficios;
  public direccion;
  public responsabilidades;
  public atenciones;
  public atenciones_selected;
  public descanso = [];
  public dias_trabajo = [];
  public serviciosList = [];
  public actual_step: number = 1;
  public dias;
  private oferta_id: number = 0;
  public apiUrl = global.url;
  private auctionToken: string = '';

  public randomReservaCadianData = {
    fullName: '',
    image: '',
    age: '',
    province: '',
    city: '',
    studies: '',
    aptitudes: []
  };


  public randomReservaData = {
    id: 0,
    startAt: '',
    endAt: '',
    farePerHour: '',
    hoursTotal: '',
    amountTotal: '',
    userBalance: ''
  };

  public config = <IDayCalendarConfig>{
    locale: "es",
    format: "DD-MM-YYYY HH:mm",
    min: moment(new Date()).format('DD-MM-YYYY'),
    showSeconds: false
  };

  public config2 = <IDayCalendarConfig>{
    locale: "es",
    format: "DD-MM-YYYY HH:mm",
    min: moment(new Date()).format('DD-MM-YYYY'),
    showSeconds: false
  };

  public configHorario = <IDayCalendarConfig>{
    locale: "es",
    format: "HH:mm",
    min: moment(new Date()).format('DD-MM-YYYY'),
    showSeconds: false
  };

  public addressData = {
    street: '',
    outsideNumber: '',
    sector: '',
    city: '',
    province: ''
  };

  public tipo;
  public provincias;
  public distritos;
  public servicios = [];
  public fecha_inicio;
  public fecha_final;
  public provincia;
  public municipio;
  public vacantes;
  public tarifa;
  public costo_estimado: number = 0;
  public key_tipo_cadian;
  public edad;
  public aptitudes;
  public tipo_oferta;
  public aptitudesSettings = {};
  public selectedAptitudes = [];
  public habilidadesSettings = {};
  public selectedHabilidades = [];

  public descansoList = [
    { dia: 'Lunes' },
    { dia: 'Martes' },
    { dia: 'Miercoles' },
    { dia: 'Jueves' },
    { dia: 'Viernes' },
    { dia: 'Sabado' },
    { dia: 'Domingo' }
  ];

  public diasTrabajoList = [
    { dia: 'Lunes' },
    { dia: 'Martes' },
    { dia: 'Miercoles' },
    { dia: 'Jueves' },
    { dia: 'Viernes' },
    { dia: 'Sabado' },
    { dia: 'Domingo' }
  ];

  public verificados;
  public cargos = [];
  public membresia = [];
  public duracion;
  public municipios;
  public disable_btn = false;
  public destacarOferta;
  public descansoSettings;
  public diasTrabajoSettings;

  options = {
    componentRestrictions: {
      country: ["DO"]
    }
  }

  formattedaddress = " ";

  constructor(
    public _userService: UserService,
    public _clienteService: ClienteService,
    public _servicioService: ServiciosService,
    public _atencionesService: AtencionesService,
    public _provinciasService: ProvinciasService,
    public _municipiosService: MunicipiosService,
    public _reservaService: ReservaService,
    public _aptitudesService: AptitudesService,
    public _membresiasService: MembresiasService,
    public _cargosService: CargosService,
    public _router: Router,
  ) {
    // this.cliente = new Cliente(1,'','','1', '1', '1',1,'','',1,'','','','','',1,'','','',0,'',0,'','',0);
    this.cliente = new Cliente(1, '', '', '1', '1', '1', 1, '', '', 1, '', '', '', '', '', 1, '', '', '', 0, '', 0, '', '', 0, 0);
    this.loadUser();
    this.aptitudesSettings = {
      singleSelection: false,
      enableCheckAll: false,
      idField: 'id',
      textField: 'nombre',
      selectAllText: 'Seleccionar todo',
      unSelectAllText: 'Deseleccionar todo',
      itemsShowLimit: 8,
      allowSearchFilter: true
    };
    this.habilidadesSettings = {
      singleSelection: false,
      enableCheckAll: false,
      idField: 'id_subcategoria',
      textField: 'nombre',
      selectAllText: 'Seleccionar todo',
      unSelectAllText: 'Deseleccionar todo',
      itemsShowLimit: 8,
      allowSearchFilter: true
    };
    this.descansoSettings = {
      singleSelection: false,
      enableCheckAll: false,
      idField: 'dia',
      textField: 'dia',
      selectAllText: 'Seleccionar todo',
      unSelectAllText: 'Deseleccionar todo',
      itemsShowLimit: 8,
      allowSearchFilter: true
    };
    this.diasTrabajoSettings = {
      singleSelection: false,
      enableCheckAll: false,
      idField: 'dia',
      textField: 'dia',
      selectAllText: 'Seleccionar todo',
      unSelectAllText: 'Deseleccionar todo',
      itemsShowLimit: 8,
      allowSearchFilter: true
    };

    /* setTimeout(() => {
      let modal = UIkit.modal("#modal-destacado-trabajo");
      modal.show();
      $('#randomReservaCadianData').modal({backdrop: 'static', keyboard: false}).show();
    }, 2000); */
  }

  public confirmRandomReservaPayment(randomReservaId: number, userBalance: string, paymentTotal: string): void {
    let htmlContent = `
      <p class="text-justify" style="font-size: 11pt; color: black;">
        Usa tu balance para pagarle al cadian la reserva random. Una vez hecho el pago ambos podrán ponerse en contacto.
      </p>
      <div class="card bg-light mt-4" style="box-shadow: 0px 5px 10px 0px #888888;">
        <div class="card-header">
          <h4 class="m-0 p-0">` + this.details.nombre + ' ' + this.details.apellidos + `</h4>
        </div>
        <div class="card-body">
          <table class="table ml-3 mr-3">
            <tbody>
              <tr>
                <th scope="row" class="border-0">Balance</th>
                <td class="border-0" style="color: black;">RD $` + userBalance + `</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <h4>Total a pagar RD $` + paymentTotal + `</h4>
    `;

    Swal.fire({
      icon: 'info',
      html: htmlContent,
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCancelButton: true,
      cancelButtonColor: '',
      showConfirmButton: true,
      confirmButtonText: 'Pagar',
      reverseButtons: true
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.payRandomReserva(randomReservaId);
      }
    });
  }

  public payRandomReserva(randomReservaId: number): void {
    let jsonData = { random_reserva_id: randomReservaId };

    this._reservaService.payRandomReserva(this.token, jsonData).subscribe(
      (reponse: any) => {
        setTimeout(() => {
          Swal.hideLoading();
          Swal.update({
            icon: 'success',
            title: 'Pago realizado correctamente',
            allowOutsideClick: false,
            allowEscapeKey: false,
            showConfirmButton: true,
            confirmButtonText: 'Aceptar'
          });
          $('#randomReservaCadianData').modal('hide');
          this._router.navigate(['/']);
        }, 1000);
      },
      (error: any) => {
        setTimeout(() => {
          Swal.hideLoading();
          Swal.update({
            icon: 'error',
            title: 'Algo salio mal',
            html: 'Por favor intente de nuevo y si el problema continúa contacte a soporte',
            allowOutsideClick: false,
            allowEscapeKey: false,
            showConfirmButton: true,
            confirmButtonText: 'Aceptar'
          });
        }, 1000);
      }
    );

    Swal.fire({
      icon: 'info',
      title: 'Pago en proceso...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      onOpen: () => {
        Swal.showLoading();
      }
    });
  }

  public confirmCancelRandomReserva(): void {
    Swal.fire({
      icon: 'warning',
      title: '¿Cancelar reserva random?',
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    }).then((result: any) => {
      if (result.isConfirmed) {
        $('#randomReservaCadianData').modal('hide');
      }
    });
  }

  ngOnInit() {
    this.getServicios();
    this.getProvincias();
    this.getMunicipios();
    this.getAtenciones();
    this.getAptitudes();
    this.getCargos();
    this.getMembresiaEstatus();

  }
  public ngAfterViewInit(): void {

  }

  getMembresiaEstatus() {

    this._membresiasService.getMembresiaEstatus(this.token).subscribe(
      response => {

        if (response.status == 'success') {
          this.membresia = response.membresia;
        }

      },
      errorResponse => {
        // ...
      }
    );

  }

  validarBeneficioMembresia() {

    this.destacarOferta = true;

    // CLASIFICAMOS EL BENEFICIO DE LA MEMBRESIA
    let clave_beneficio = '';
    if (this.key_tipo_cadian == 2) {
      if (this.verificados == 0) {
        clave_beneficio = 'PEII';
      }
      else {
        clave_beneficio = 'PVII';
      }
    }
    else {
      if (this.key_tipo_cadian == 3) {
        if (this.verificados == 0) {
          clave_beneficio = 'PEEI';
        }
        else {
          clave_beneficio = 'PVEI';
        }
      }
    }

    // En caso de no contar con el beneficio, se muestra la ventana para destacar
    let destacar = true;
    let membresia_detalles = this.membresia['detalles'];
    for (let detalle of membresia_detalles) {
      if (detalle.clave_beneficio == clave_beneficio) this.destacarOferta = false;
    }
  }

  public getCargos(): void {
    this._cargosService.getCargos().subscribe(
      response => {
        if (response.status == 'success') {
          this.cargos = response.cargos;
        }
      },
      errorResponse => {
        //...
      }
    );
  }

  public onSubmitOferta(form: NgForm): void {
    this.disable_btn = true;

    // SANITIZAMOS
    this.titulo = this.titulo.replace(/<.*?>/, "");
    this.descripcion = this.descripcion.replace(/<.*?>/, "");
    this.tiempo_experiencia = this.tiempo_experiencia.replace(/<.*?>/, "");
    this.direccion = this.direccion != undefined ? this.direccion.replace(/<.*?>/, "") : '';

    let habilidades_select = [];

    this.selectedHabilidades.forEach(element => {
      habilidades_select.push(element.id_subcategoria);
    });

    let aptitudes_select = [];

    this.selectedAptitudes.forEach(element => {
      aptitudes_select.push(element.id);
    });

    let trabajo_dias = [];
    this.dias_trabajo.forEach(element => {
      trabajo_dias.push(element.dia);
    });

    let descanso_selected = [];
    this.descanso.forEach(element => {
      descanso_selected.push(element.dia);
    });

    let formData = new FormData();
    formData.append('titulo', this.titulo);
    formData.append('verificados', this.verificados);
    formData.append('key_tipo_cadian', this.key_tipo_cadian);
    formData.append('descripcion', this.descripcion);
    formData.append('servicio', this.servicio);
    formData.append('habilidades', habilidades_select.join(','));
    formData.append('aptitudes', aptitudes_select.join(','));
    formData.append('tiempo_experiencia', this.tiempo_experiencia);
    formData.append('hora_entrada', this.hora_entrada);
    formData.append('hora_salida', this.hora_salida);
    formData.append('sueldo', this.sueldo);
    formData.append('beneficios', this.beneficios);
    formData.append('vacantes', this.vacantes);
    formData.append('direccion', this.direccion);
    // formData.append('key_provincia', this.provincia);
    // formData.append('key_municipio', this.municipio);
    formData.append('responsabilidades', this.responsabilidades);
    formData.append('descanso', descanso_selected.join(','));
    formData.append('dias_trabajo', trabajo_dias.join(','));
    formData.append('key_edad', this.edad);
    formData.append('key_atencion', this.atenciones_selected);
    //----------
    formData.append('street', this.addressData.street);
    formData.append('outside_number', this.addressData.outsideNumber);
    formData.append('sector', this.addressData.sector);
    formData.append('city', this.addressData.city);
    formData.append('province', this.addressData.province);

    this._clienteService.nuevaOferta(this.token, formData).subscribe(
      response => {
        if (response.status == 'success') {
          this.oferta_id = response.id;
          this.clean();
          this.actual_step = 1;

          this.destacarOferta = true;
          if (this.membresia != null) this.validarBeneficioMembresia();

          if (this.destacarOferta == true) {
            let modal = UIkit.modal("#modal-destacado-trabajo");
            modal.show();
          }
          else {
            Swal.fire('¡Muy bien!', response.message, 'success');
            window.location.href = "/perfil-cliente/mis-vacantes";
          }
        }

        this.disable_btn = false;

      },
      error => {
        Swal.fire({
          title: 'UPS',
          icon: 'error',
          html: error.error.message
        });
        this.disable_btn = false;
      }
    );

  }

  clean() {
    this.titulo = '';
    this.descripcion = '';
    this.servicio = '';
    this.tiempo_experiencia = '';
    this.hora_entrada = '';
    this.hora_salida = '';
    this.sueldo = '';
    this.beneficios = '';
    this.vacantes = '';
    this.direccion = '';
    this.provincia = '';
    this.municipio = '';
    this.responsabilidades = '';
    this.descanso = [];
    this.dias_trabajo = [];
    this.costo_estimado = 0;
  }

  public onSubmitReserva(form: NgForm): void {
    this.disable_btn = true;
    let habilidades_select = [];

    this.selectedHabilidades.forEach((element: any) => {
      habilidades_select.push(element.id_subcategoria);
    });

    let aptitudes_select = [];

    this.selectedAptitudes.forEach((element: any) => {
      aptitudes_select.push(element.id);
    });

    let formData = new FormData();
    formData.append('tipo', this.tipo_oferta);
    formData.append('verificados', this.verificados);
    formData.append('titulo', this.titulo);
    formData.append('descripcion', this.descripcion);
    formData.append('tarifa', this.tarifa);
    formData.append('key_atencion', this.atenciones_selected);
    formData.append('key_servicio', this.servicio);
    formData.append('key_habilidades', habilidades_select.join(','));
    formData.append('aptitudes', aptitudes_select.join(','));
    formData.append('fecha_cuidado', this.fecha_inicio);
    formData.append('fecha_final', this.fecha_final);
    formData.append('direccion', this.direccion);
    formData.append('key_experiencia', this.tiempo_experiencia);
    formData.append('key_edad', this.edad);
    formData.append('duracion', this.duracion);
    formData.append('street', this.addressData.street);
    formData.append('outside_number', this.addressData.outsideNumber);
    formData.append('sector', this.addressData.sector);
    formData.append('city', this.addressData.city);
    formData.append('province', this.addressData.province);

    this._reservaService.enviarOfertaReserva(this.token, formData).subscribe(
      (response: any) => {
        console.log('subasta', response);

        // 1 = random | 2 = subasta | 3 = manual
        if (this.tipo_oferta == 1) {
          setTimeout(() => {
            this.clean();
            this.actual_step = 1;
            this.disable_btn = false;

            Swal.update({
              icon: 'info',
              title: 'Cadian encontrado',
              html: 'Enviamos tu reserva a un cadian, espera un momento mientras la acepta. Este proceso puede tardar hasta 5 minutos.'
            });

            Swal.showLoading();
            this.checkRandomReserva(response.reserva_token);
          }, 1000);
        } else {
          this.auctionToken = response.reserva_token;
          this.clean();
          this.actual_step = 1;
          this.disable_btn = false;
          this.oferta_id = response.id;
          Swal.fire('¡Muy bien!', response.message , 'success');

          UIkit.modal("#modal-destacado", {
            escClose: false,
            bgClose: false
          }).show();
        }
      },
      (error: any) => {
        Swal.fire({
          title: 'UPS',
          icon: 'error',
          html: error.error.message
        });

        this.disable_btn = false;
      }
    );

    if (this.tipo_oferta == 1) {
      Swal.fire({
        title: 'Buscando cadians disponibles...',
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => { Swal.showLoading(); }
      });
    }
  }

  public goToAuctionDetail(): void {
    if (this.auctionToken != '') {
      this._router.navigate(['/subastas/', this.auctionToken]);
    } else {
      window.location.href = "/perfil-cliente/mis-vacantes";
    }
  }

  private checkRandomReserva(tokenRandomReserva: string): void {
    this._reservaService
      .checkRandomReserva(this.token, tokenRandomReserva)
      .subscribe(
        (response: any) => {
          if (response.reservation_accepted == 1) {
            this.loadRandomReservaCadianData(response.cadian_data, response.reserva_data);
            Swal.close();
            $('#randomReservaCadianData').modal({ backdrop: 'static', keyboard: false }).show();
          } else if (response.reservation_accepted == 0) {
            this.searchAnotherCadian(tokenRandomReserva);
          } else if (response.reservation_accepted == -1) {
            if (response.expiration_seconds > 0) {
              setTimeout(() => {
                this.checkRandomReserva(tokenRandomReserva);
              }, 50000);
            } else {
              this.searchAnotherCadian(tokenRandomReserva);
            }
          }
        },
        (error: any) => {
          setTimeout(() => {
            this.checkRandomReserva(tokenRandomReserva);
          }, 20000);
        }
      );
  }

  private loadRandomReservaCadianData(cadianData: any, reservaData: any): void {
    this.randomReservaCadianData.fullName = cadianData.full_name;
    this.randomReservaCadianData.image = cadianData.image;
    this.randomReservaCadianData.age = cadianData.age;
    this.randomReservaCadianData.province = cadianData.province;
    this.randomReservaCadianData.city = cadianData.city;
    this.randomReservaCadianData.studies = cadianData.studies;
    this.randomReservaCadianData.aptitudes = cadianData.aptitudes;
    this.randomReservaData.startAt = reservaData.start_at;
    this.randomReservaData.endAt = reservaData.end_at;
    this.randomReservaData.farePerHour = reservaData.fare_per_hour;
    this.randomReservaData.hoursTotal = reservaData.hours_total;
    this.randomReservaData.amountTotal = reservaData.amount_total;
    this.randomReservaData.userBalance = reservaData.user_balance;
    this.randomReservaData.id = reservaData.id;
  }

  private searchAnotherCadian(tokenRandomReserva: string): void {
    this._reservaService
      .searchAnotherCadian(this.token, tokenRandomReserva)
      .subscribe(
        (response: any) => {
          if (response.found_cadian) {
            this.checkRandomReserva(tokenRandomReserva);
          } else {
            Swal.hideLoading();
            Swal.update({
              icon: 'error',
              title: 'Reserva no aceptada',
              html: 'El cadian no aceptó tu reserva y ya no hay cadians disponibles. Intenta de nuevo más tarde.',
              confirmButtonText: 'Aceptar'
            });
          }
        },
        (error: any) => {
          Swal.hideLoading();
          Swal.update({
            icon: 'error',
            title: 'Reserva no aceptada',
            html: 'El cadian no aceptó tu reserva y ya no hay cadians disponibles. Intenta de nuevo más tarde.',
            confirmButtonText: 'Aceptar'
          });
        }
      );
  }

  loadUser() {
    this.identity = this._userService.getIdentity();
    this.token = this._userService.getToken();
    this.details = this._userService.getDetails();

    //rellenar objeto cliente
    this.cliente = new Cliente(
      this.identity.sub,
      this.details.nombre,
      this.details.apellidos,
      this.details.key_provincia,
      this.details.key_municipio,
      this.details.key_sector,
      this.details.numero,
      this.details.direccion,
      this.details.tipo_cliente,
      this.details.key_genero,
      this.details.correo,
      this.details.telefono,
      this.details.foto,
      this.details.cedula,
      this.details.foto_cedula,
      this.details.forma_pago,
      this.details.estatus,
      this.details.acerca_de_mi,
      this.details.puesto,
      this.details.check_autorizacion,
      this.details.foto_dni_comprobacion,
      this.details.solicitudes_autonomas,
      this.details.facebook,
      this.details.twitter,
      this.details.codigo_postal,
      this.details.key_provincia
    );
  }

  getServicios() {

    this._servicioService.getServicios().subscribe(
      response => {

        if (response.status == 'success') {

          if (response.servicios.length > 0) {

            this.serviciosList = response.servicios;

          }

        }

      },
      errorResponse => {
        let errores = '';
        Object.keys(errorResponse.error.error).forEach(function (k) {
          errores += '- ' + errorResponse.error.error[k] + "<br>";
        });

        Swal.fire({
          title: 'UPS',
          icon: 'error',
          html: errores
        });
      }
    );

  }

  getHabilidades(id_servicio = 0) {
    if (id_servicio != 0) {
      this._servicioService.getSubcategoriasByServicio(id_servicio).subscribe(
        response => {

          if (response.status == 'success') {

            if (response.subcategorias.length > 0) {

              this.habilidades = response.subcategorias;
              /*for(var i in response.subcategorias){
                this.habilidadesList.push([i, response.subcategorias[i]]);
              }*/

            }

          }

        },
        errorResponse => {
          let errores = '';
          Object.keys(errorResponse.error.error).forEach(function (k) {
            errores += '- ' + errorResponse.error.error[k] + "<br>";
          });

          Swal.fire({
            title: 'UPS',
            icon: 'error',
            html: errores
          });
        }
      );
    }
  }

  getProvincias() {
    this._provinciasService.getProvincias().subscribe(
      response => {

        if (response.status == 'success') {
          this.provincias = response.provincias;
        }

      },
      errorResponse => {

        let errores = '';
        Object.keys(errorResponse.error.error).forEach(function (k) {
          errores += '- ' + errorResponse.error.error[k] + "<br>";
        });

        Swal.fire({
          title: 'UPS',
          icon: 'error',
          html: errores
        });

      }
    );
  }

  getMunicipios($event = null, change = false) {

    let provincia = 0;

    if (change) {
      provincia = $event.target.selectedOptions[0].value;
    }

    this._municipiosService.getMunicipiosByProvincia(provincia).subscribe(
      response => {

        if (response.status == 'success') {
          this.municipios = response.municipios;
        }

      },
      errorResponse => {

        let errores = '';
        Object.keys(errorResponse.error.error).forEach(function (k) {
          errores += '- ' + errorResponse.error.error[k] + "<br>";
        });

        Swal.fire({
          title: 'UPS',
          icon: 'error',
          html: errores
        });

      }
    );

  }

  getAtenciones() {
    this._atencionesService.getAtenciones().subscribe(
      response => {

        if (response.status == 'success') {
          this.atenciones = response.atenciones;
        }

      },
      errorResponse => {
        // ...
      }
    );
  }

  getAptitudes() {
    this._aptitudesService.getAptitudes().subscribe(
      response => {

        if (response.status == 'success') {
          this.aptitudes = response.aptitudes;
        }

      },
      errorResponse => {
        // ...
      }
    );
  }

  changeExperiencia($event) {
    let id_servicio = $event.target.selectedOptions[0].value;
    this.getHabilidades(id_servicio);
  }

  pagarDestacadoBalance() {

    //VERIFICAMOS SI PUBLICO UNA OFERTA
    if (this.oferta_id == 0) {
      return;
    }

    let form = new FormData();
    form.append('dias', this.dias);
    form.append('id', this.oferta_id.toString());
    form.append('tipo', this.tipo);

    this._clienteService.pagarDestacadoBalance(this.token, form).subscribe(
      response => {

        if (response.status == 'success') {

          let modal = UIkit.modal("#modal-destacado-trabajo");
          modal.hide();

          let modal2 = UIkit.modal("#modal-destacado");
          modal2.hide();

          Swal.fire('¡Muy bien!', response.message, 'success');

          this.goToAuctionDetail();
        }
        else if (response.status == 'error') {
          Swal.fire('¡UPS!', response.message, 'error');
        }

      },
      errorResponse => {
        // ...
      }
    );

  }

  public step(step): void {
    if (
      (this.tipo == 'trabajo' && this.actual_step == 2 && this.actual_step != step) ||
      (this.tipo == 'reserva' && this.actual_step == 5 && this.actual_step != step)
    ) {
      this.addressData.street = this.addressComponent.addressData.street;
      this.addressData.outsideNumber = this.addressComponent.addressData.outsideNumber;
      this.addressData.sector = this.addressComponent.addressData.sector;
      this.addressData.city = this.addressComponent.addressData.city;
      this.addressData.province = this.addressComponent.addressData.province;
    }

    if (step > 1 && this.tipo == undefined) {
      return;
    }

    if (step == 5) {
      let form = new FormData();
      form.append('fecha_inicio', this.fecha_inicio);
      form.append('fecha_final', this.fecha_final);
      form.append('tarifa', this.tarifa);

      this.costo_estimado = 0;

      if (this.tipo_oferta == 3 && this.fecha_inicio != undefined && this.fecha_final != undefined && this.tarifa != undefined) {
        this._reservaService.getCostoEstimadoReserva(this.token, form).subscribe(
          response => {

            if (response.status == 'success') {
              this.costo_estimado = response.monto_total_estimado;
            }
            else if (response.status == 'error') {
              this.costo_estimado = 0;
            }

          },
          errorResponse => {
            // ...
          }
        );
      }

    }

    this.actual_step = step;

    if (step == 2 || step == 3) {
      this.reInitPopover();
    }
  }

  validatorsChanged() {
    this.config2.min = moment(new Date(this.fecha_inicio)).format('DD-MM-YYYY');
  }

  public AddressChange(address: any) {
    //setting address from API to local variable 
    this.formattedaddress = address.formatted_address
    this.direccion = this.formattedaddress;
  }

  private reInitAddressData(): void {
    this.addressData.street = '';
    this.addressData.outsideNumber = '';
    this.addressData.sector = '';
    this.addressData.city = '';
    this.addressData.province = '';

  }

  public initCreateReserve(): void {
    this.tipo = 'reserva';
    this.actual_step = 2;
    this.reInitAddressData();
  }

  public initCreateOffer(): void {
    this.tipo = 'trabajo';
    this.actual_step = 2;
    this.reInitAddressData();
    this.reInitPopover();
  }

  private reInitPopover(): void {
    $('.verified-key').popover('dispose');

    setTimeout(() => {
      $('.verified-key').popover({
        html: true,
        container: 'body',
        content: function () { return $('#dirigido_popover').html(); },
        placement: 'top',
        fallbackPlacement: ['top'],
        trigger: 'focus'
      });
    }, 1200);
  }

  public changeAddressData(addressData): void {
    this.addressData.street = addressData.street;
    this.addressData.outsideNumber = addressData.outsideNumber;
    this.addressData.sector = addressData.sector;
    this.addressData.city = addressData.city;
    this.addressData.province = addressData.province;
  }

}
