import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { global } from './global';

@Injectable({
  providedIn: 'root'
})
export class ReservaService {
  public url: string;

  constructor(private _http: HttpClient) {
    this.url = global.url;
  }

  enviarReserva(token, formData: any): Observable<any> {
    let headers = new HttpHeaders().set('Authorization', token);
    return this._http.post(this.url+'user/enviar-reserva', formData, {headers: headers});
  }

  enviarOfertaReserva(token, formData: any): Observable<any>{
    let headers = new HttpHeaders().set('Authorization', token);
    return this._http.post(this.url+'user/enviar-oferta-reserva', formData, {headers: headers});
  }

  public checkRandomReserva(token: string, tokenRandomReserva: string): Observable<any> {
    let headers = new HttpHeaders().set('Authorization', token);
    return this._http.get(this.url + 'user/random-reserva/check?reservation_token=' + tokenRandomReserva, {headers: headers});
  }

  public searchAnotherCadian(token: string, tokenRandomReserva: string): Observable<any> {
    let headers = new HttpHeaders().set('Authorization', token);
    return this._http.get(this.url + 'user/random-reserva/search-again?reservation_token=' + tokenRandomReserva, {headers: headers});
  }

  public acceptOrDenyRandomReserva(token: string, jsonData: object): Observable<any> {
    let headers = new HttpHeaders().set('Authorization', token);
    return this._http.put(this.url + 'user/random-reserva/accept-or-deny', jsonData, {headers: headers});
  }

  public payRandomReserva(token: string, jsonData: object): Observable<any> {
    let headers = new HttpHeaders().set('Authorization', token);
    return this._http.post(this.url + 'user/random-reserva/pay', jsonData, {headers: headers});
  }

  getAllReservasByUser(token):Observable<any>{
    let headers = new HttpHeaders().set('Authorization', token);
    return this._http.get(this.url+'user/reservas',{headers: headers});
  }

  getReservasByUser(token, estatus):Observable<any>{
    let headers = new HttpHeaders().set('Authorization', token);
    return this._http.get(this.url+'user/reservas/'+estatus, {headers: headers});
  }

  getReservasActivas(token):Observable<any>{
    let headers = new HttpHeaders().set('Authorization', token);
    return this._http.get(this.url+'user/reservas-activas', {headers: headers});
  }

  checkReserva(token, formData: any): Observable<any>{
    let headers = new HttpHeaders().set('Authorization', token);
    return this._http.post(this.url+'user/check-reserva', formData, {headers: headers});
  }

  checkConfirmacion(formData: any): Observable<any>{
    return this._http.post(this.url+'user/check-confirmacion', formData);
  }

  aceptarReserva(token, formData: any): Observable<any>{
    let headers = new HttpHeaders().set('Authorization', token);
    return this._http.post(this.url+'user/aceptar-reserva', formData, {headers: headers});
  }

  pagarReserva(token, formData: any): Observable<any>{
    let headers = new HttpHeaders().set('Authorization', token);
    return this._http.post(this.url+'user/pagar-reserva', formData, {headers: headers});
  }

  TokenQR(token, formData: any): Observable<any>{
    let headers = new HttpHeaders().set('Authorization', token);
    return this._http.post(this.url+'user/qr-reserva', formData, {headers: headers});
  }

  confirmarReserva(token, formData: any): Observable<any>{
    let headers = new HttpHeaders().set('Authorization', token);
    return this._http.post(this.url+'user/confirmar-reserva', formData, {headers: headers});
  }

  cancelarReserva(token, formData: any): Observable<any>{
    let headers = new HttpHeaders().set('Authorization', token);
    return this._http.post(this.url+'user/cancelar-reserva', formData, {headers: headers});
  }

  opinar(token, formData: any): Observable<any>{
    let headers = new HttpHeaders().set('Authorization', token);
    return this._http.post(this.url+'user/opinar-cuidador', formData, {headers: headers});
  }
  
  getCostoEstimadoReserva(token, formData: any): Observable<any>{
    let headers = new HttpHeaders().set('Authorization', token);
    return this._http.post(this.url+'user/get-costo-estimado', formData, {headers: headers});
  }

  buscarOtroCuidador(token, formData: any): Observable<any>{
    let headers = new HttpHeaders().set('Authorization', token);
    return this._http.post(this.url+'user/reserva-buscar-otro', formData, {headers: headers});
  }
}
