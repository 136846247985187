import { Injectable } from '@angular/core';
import {io} from 'socket.io-client/build/index';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  public url : any;
  public server = 'http://localhost:3000';
  public socket: any;

  constructor(
    private _http: HttpClient
  ) { 

    //this.url = global.url;
    this.socket = io(this.server);

  }

  listen(eventName: String){
    return new Observable((Subscriber) => {
      this.socket.on(eventName, (data) => {
        Subscriber.next(data);
      })
    });
  }

  emit(eventName: String, data: any){
    this.socket.emit(eventName,data);
  }

  checkToken(token, token_conversacion):Observable<any>{
    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                    .set('Authorization', token);
    return this._http.get(this.url+'user/mensajeria/'+token_conversacion,{headers: headers});
  }

  enviarMensaje(token, form): Observable<any>{
    let headers = new HttpHeaders().set('Authorization', token);
    
    return this._http.post(this.url+'enviar-mensaje', form, {headers: headers});
  }
}