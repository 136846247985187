export class User{
    constructor(
        public id: number,
        public name: string,
        public surname: string,
        public email: string,
        public password: string,
        public password_repeat: string,
        public type_user: number,
        public role_user: string,
        public telephone: string
    ){}
}