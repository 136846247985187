import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { global } from './global';

@Injectable({
  providedIn: 'root'
})
export class MembresiasService {

  public url: string;

  constructor(
    private _http: HttpClient
  ) {
    this.url = global.url;
  }

  getMembresias(type_user, tipo_membresia): Observable<any> {
    return this._http.get(this.url + 'membresias/' + type_user + '/' + tipo_membresia);
  }

  getMembresia(id_membresia): Observable<any> {
    return this._http.get(this.url + 'membresia/' + id_membresia);
  }

  pagarMembresia(token, formData: any): Observable<any> {
    let headers = new HttpHeaders().set('Authorization', token);
    return this._http.post(this.url + 'user/membresias/pagar', formData, { headers: headers });
  }

  getMembresiaEstatus(token): Observable<any> {
    let headers = new HttpHeaders().set('Authorization', token);
    return this._http.get(this.url + 'user/membresia-estatus', { headers: headers });
  }

}
