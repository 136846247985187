import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { global } from './global';

@Injectable({
  providedIn: 'root'
})
export class ServiciosService {

    public url: string;
  
    constructor(
      private _http: HttpClient
    ) { 
  
      this.url = global.url;
  
    }
  
    getServiciosByUser(token):Observable<any>{
  
      let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                      .set('Authorization', token);
  
      return this._http.get(this.url+'user/servicios',{headers: headers});
  
    }
  
    getServicios():Observable<any>{
  
        let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

        return this._http.get(this.url+'servicios',{headers: headers});
  
    }

    getServiciosByHabilidad(token, habilidad):Observable<any>{
  
      let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                      .set('Authorization', token);
  
      return this._http.get(this.url+'user/habilidad-servicio/'+habilidad,{headers: headers});
  
    }

    getServiciosHabilidades():Observable<any>{
  
      let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

      return this._http.get(this.url+'servicios-habilidades',{headers: headers});

  }


    getSubcategorias():Observable<any>{
  
      let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

      return this._http.get(this.url+'subcategorias',{headers: headers});

    }

    getSubcategoriasByServicio(id_servicio):Observable<any>{

      let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
  
      return this._http.get(this.url+'subcategorias/'+id_servicio, {
        headers: headers
      });
  
    }

    getSubcategoriasByUser(token):Observable<any>{

      let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                      .set('Authorization', token);
  
      return this._http.get(this.url+'user/subcategorias',{headers: headers});
  
    }

    deleteServicio(token, id):Observable<any>{

        let params = new FormData();
        params.append('id', id);
    
        let headers = new HttpHeaders().set('Authorization', token);
                                
        return this._http.post(this.url+'user/delete/servicio', params, {headers: headers});
    
      }
  }