import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ReservaService } from 'src/app/services/reserva.service';
import Swal from 'sweetalert2';
import { global } from '../../services/global';

@Component({
  selector: 'app-confirmacion-reserva',
  templateUrl: './confirmacion-reserva.component.html',
  styleUrls: ['./confirmacion-reserva.component.css']
})
export class ConfirmacionReservaComponent implements OnInit {

  public reserva: any = [];
  public perfil: any = [];
  public url;

  constructor(
    public _reservaService: ReservaService,
    private _router: Router,
    private _route: ActivatedRoute
  ) { 
    this.url = global.url;
  }

  ngOnInit() {
    this.checkConfirmacion();
  }

  checkConfirmacion(){

    this._route.params.subscribe(params => {

      let token = params['token'];

      if(token != undefined || token != ''){

          let form = new FormData();
          form.append('token', token);

          this._reservaService.checkConfirmacion(form).subscribe(
            response => {

              if(response.status == 'success'){
                  this.perfil = response.perfil;
                  this.reserva = response.reserva;
              }

            },
            errorResponse => {
              Swal.fire('¡UPS!', errorResponse.error.message , 'error');
              this._router.navigate(['/']);
            }
          );
      }
      else{
        this._router.navigate(['/']);
      }

    });
}

}
