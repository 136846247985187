import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { Cadian } from 'src/app/models/cadian';
import Swal from 'sweetalert2';
import { global } from '../../../services/global';
import { CertificadosService } from 'src/app/services/certificados.service';
import { ServiciosService } from 'src/app/services/servicios.service';
import { CadianService } from 'src/app/services/cadian.service';
import { AptitudesService } from 'src/app/services/aptitudes.service';
import { IdiomasService } from 'src/app/services/idiomas.service';

@Component({
  selector: 'app-curriculum',
  templateUrl: './curriculum.component.html',
  styleUrls: ['../perfil-cadian.component.css'],
  // providers: [UserService, CertificadosService, ServiciosService, CadianService],
  providers: [],
  encapsulation: ViewEncapsulation.None
})
export class CurriculumComponent implements OnInit {

  public cadian : Cadian;
  public identity;
  public token;
  public details;
  public status: string;
  public certificados: any[] = [];
  public percent_progress;
  public habilidadesSettings = {};
  public selectedHabilidades = [];
  public habilidadesList = [];
  public habilidadesListString = [];
  public aptitudesSettings = {};
  public selectedAptitudes = [];
  public aptitudesList = [];
  public experiencia_servicio = [];
  public serviciosList = [];
  public idiomasList = [];
  public selectedIdiomas = [];
  public idiomasSettings = {};

  public afuConfig = {
    multiple: false,
    formatsAllowed: ".jpg,.png,.pdf,.jpeg",
    maxSize: "5",
    uploadAPI:  {
      url: global.url + 'user/upload-certificado',
      headers: {
     "Authorization" : this._userService.getToken()
      }
    },
    theme: "attachPin",
    hideProgressBar: true,
    hideResetBtn: true,
    hideSelectBtn: false,
    attachPinText: 'Subir certificado',
    replaceTexts: {
      selectFileBtn: 'Select Files',
      resetBtn: 'Reset',
      uploadBtn: 'Upload',
      dragNDropBox: 'Drag N Drop',
      attachPinBtn: 'Subir certificado',
      afterUploadMsg_success: 'Certificado subido !',
      afterUploadMsg_error: 'Fallo al subir el certificado !'
    }
};

  objectKeys = Object.keys;

  constructor(
    public _userService: UserService,
    public _cadianService: CadianService,
    public _certificadoService: CertificadosService,
    public _servicioService: ServiciosService,
    public _aptitudesService: AptitudesService,
    public _idiomasService: IdiomasService
  ) { 
    this.cadian = new Cadian(1,'','',1,1,1,'',1,'',1,1,'','','','','','','','',1,1,1,[],[],'','',[],[],0,[],'','',0,0,0,'',[],'','','',0,0);
    this.loadUser();
  }

  ngOnInit() {
    this.getIdiomas();
    this.getIdiomasByUser();
    this.getServicios();
    this.getServiciosByUser();
    this.getCertificados();
    this.getHabilidadesUser();
    this.getHabilidades();
    this.getAptitudes();
    this.getAptitudesUser();

    this.habilidadesSettings = {
      singleSelection: false,
      enableCheckAll: false,
      idField: 'id_subcategoria',
      textField: 'nombre',
      selectAllText: 'Seleccionar todo',
      unSelectAllText: 'Deseleccionar todo',
      itemsShowLimit: 5,
      allowSearchFilter: true
    };

    this.idiomasSettings = {
      singleSelection: false,
      enableCheckAll: false,
      idField: 'id_idioma',
      textField: 'nombre',
      selectAllText: 'Seleccionar todo',
      unSelectAllText: 'Deseleccionar todo',
      itemsShowLimit: 5,
      allowSearchFilter: true
    };

    this.aptitudesSettings = {
      singleSelection: false,
      enableCheckAll: false,
      idField: 'id',
      textField: 'nombre',
      selectAllText: 'Seleccionar todo',
      unSelectAllText: 'Deseleccionar todo',
      itemsShowLimit: 5,
      allowSearchFilter: true
    };
    
  }

  ngDoCheck(){
    
  }

  onSubmit(form){

    let flagCertificado = false;

    //VERIFICAMOS SI EXISTEN CERTIFICADOS

    if(this.certificados.length > 0){
      this.certificados.forEach(obj =>{
        if((obj.nombre == null || obj.nombre == '') || obj.file == null){
          Swal.fire('UPS...', 'Añade correctamente el certificado.' , 'error');
          flagCertificado = true;
        }
      });
    }

    if(flagCertificado){
      return;
    }
    else{
      this.cadian.certificados = this.certificados;
    }

    //VERIFICAMOS SI EXISTEN HABILIDADES
    if(this.selectedHabilidades.length > 0){
      this.cadian.habilidades = this.selectedHabilidades;
    }

    //VERIFICAMOS SI EXISTEN APTITUDES
    if(this.selectedAptitudes.length > 0){
      this.cadian.key_aptitudes = this.selectedAptitudes;
    }

    if(this.experiencia_servicio.length > 0){
      this.cadian.servicios = this.experiencia_servicio;
    }

    if(this.selectedIdiomas.length > 0){
      let array_idiomas = [];

      this.selectedIdiomas.forEach(element => {
        array_idiomas.push(element.id_idioma);
      });

      this.cadian.key_idiomas = array_idiomas.join(',');
    }

    this.calcularBarra();

    if(this.cadian.check_autorizacion == 0 && this.percent_progress == 100){
          Swal.fire({
            title: 'Un momento',
            icon: 'info',
            text: 'Al aceptar estas afirmando que los datos ingresados son reales y que estos serán utilizados para procesar su depuración.',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Aceptar'
          }).then((result) => {
            if (result.value) {
              this.cadian.check_autorizacion = 1;
              this.updateCadian();
            }
          });
      }
      else{
        this.updateCadian();
      }
  }

  public updateCadian(){

    this._cadianService.updateCurriculum(this.token, this.cadian).subscribe(
      response => {
        
        if(response.status == 'success'){
          Swal.fire('Datos actualizados con éxito', response.message , 'success');

          //ACTUALIZAMOS LOCALSTORAGE
          this.details = response.changes;
          localStorage.setItem('details', JSON.stringify(this.details));

          //ACTUALIZAMOS CERTIFICADOS
          this.getCertificados();
        }

      },
      errorResponse => {
        
        let errores = '';
        Object.keys(errorResponse.error.error).forEach(function(k){
          errores+= '- '+ errorResponse.error.error[k]+"<br>";
        });

        Swal.fire({
          title: 'UPS',
          icon: 'error',
          html: errores
        });

      }
    );
  }

  loadUser(){
    this.identity = this._userService.getIdentity();
    this.token = this._userService.getToken();
    this.details = this._userService.getDetails();

    //rellenar objeto cadian
    this.cadian = new Cadian(
      this.details.id_cadian, 
      this.details.nombre, 
      this.details.apellidos,
      this.details.key_provincia,
      this.details.key_municipio,
      this.details.key_sector,
      this.details.key_servicios,
      this.details.numero,
      this.details.direccion,
      this.details.key_tipo_cadian,
      this.details.key_genero,
      this.details.correo,
      this.details.telefono,
      this.details.foto,
      this.details.key_dni,
      this.details.foto_dni,
      this.details.foto_dni_comprobacion,
      this.details.key_formacion,
      this.details.key_idiomas,
      this.details.key_uso_pc,
      this.details.key_forma_pago,
      this.details.estatus,
      this.certificados,
      [],
      this.details.acerca_de_mi,
      this.details.puesto,
      [],
      [],
      this.details.check_autorizacion,
      [],
      this.details.carta_no_antecedentes,
      this.details.comprobante_factura,
      this.details.tarifa_hora,
      this.details.tarifa_hora2,
      this.details.tarifa_hora3,
      this.details.fecha_nacimiento,
      this.details.key_aptitudes,
      this.details.key_experiencia,
      this.details.facebook,
      this.details.instagram,
      this.details.tarifa_mensual,
      this.details.codigo_postal
      );
  }

  addCertificado() {
    this.certificados.push({
      id:0,
      nombre: null,
      file: null,
      aprobado: 0
    });
  }


  removeCertificado(i: number) {

    Swal.fire({
      title: '¿Estás seguro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Eliminar!'
    }).then((result) => {
      if (result.value) {
        if(this.certificados[i].id != 0){

          let id = this.certificados[i].id;

          this._certificadoService.deleteCertificado(this.token, id).subscribe(
            response => {
              if(response.status == 'success'){
                this.certificados.splice(i, 1);
                this.cadian.certificados.splice(i, 1);

                Swal.fire('Certificado eliminado', response.message , 'success');
              }
            },
            errorResponse => {
              let errores = '';
              Object.keys(errorResponse.error.error).forEach(function(k){
                errores+= '- '+ errorResponse.error.error[k]+"<br>";
              });

              Swal.fire({
                title: 'UPS',
                icon: 'error',
                html: errores
              });
            }
          );
        }
        else{
          this.certificados.splice(i, 1);
        }
      }
    });

  }

  DocUpload($event, i){
    let data = JSON.parse($event.response);

    if(data.status == 'success'){
      this.certificados[i].file = data.file;
    }
  }

  getCertificados(){

    //OBTENER CERTIFICADOS DEL CADIAN
    this._certificadoService.getCertificadosByUser(this.token).subscribe(
      response => {
        
        if(response.status == 'success'){
          if(response.certificados.length > 0){
            this.certificados = response.certificados;
            this.cadian.certificados = response.certificados;
          }
        }

      },
      errorResponse => {
        let errores = '';
        Object.keys(errorResponse.error.error).forEach(function(k){
          errores+= '- '+ errorResponse.error.error[k]+"<br>";
        });

        Swal.fire({
          title: 'UPS',
          icon: 'error',
          html: errores
        });
      }
    );

  }

  getHabilidadesUser(){

    this._servicioService.getSubcategoriasByUser(this.token).subscribe(
      response => {
        
        if(response.status == 'success'){

          if(response.habilidades.length > 0){

            this.selectedHabilidades = response.habilidades;
            this.cadian.habilidades =  this.selectedHabilidades;
            // console.log(this.selectedHabilidades);
            
          }

          }

      },
      errorResponse => {
        let errores = '';
        Object.keys(errorResponse.error.error).forEach(function(k){
          errores+= '- '+ errorResponse.error.error[k]+"<br>";
        });

        Swal.fire({
          title: 'UPS',
          icon: 'error',
          html: errores
        });
      }
    );

  }

  getHabilidades(){

    //OBTENER CERTIFICADOS DEL CADIAN
    this._servicioService.getSubcategorias().subscribe(
      response => {
        
        if(response.status == 'success'){

          if(response.subcategorias.length > 0){
            
            response.subcategorias.forEach(element => {
              this.habilidadesListString.push(element.nombre);
            });

            // console.log(this.habilidadesListString);
            
            this.habilidadesList = response.subcategorias;
            
          }

          }

      },
      errorResponse => {
        let errores = '';
        Object.keys(errorResponse.error.error).forEach(function(k){
          errores+= '- '+ errorResponse.error.error[k]+"<br>";
        });

        Swal.fire({
          title: 'UPS',
          icon: 'error',
          html: errores
        });
      }
    );

  }

  getAptitudes(){

    this._aptitudesService.getAptitudes().subscribe(
      response => {
        
        if(response.status == 'success'){

          if(response.aptitudes.length > 0){
            this.aptitudesList = response.aptitudes;
          }

        }

      },
      errorResponse => {
        // console.log(errorResponse);
      }
    );

  }
  

  getAptitudesUser(){

    this._aptitudesService.getAptitudesByUser(this.token).subscribe(
      response => {
        
        if(response.status == 'success'){

          if(response.aptitudes.length > 0){

            this.selectedAptitudes = response.aptitudes;
            this.cadian.key_aptitudes =  this.selectedAptitudes;
            
          }

          }

      },
      errorResponse => {
        let errores = '';
        Object.keys(errorResponse.error.error).forEach(function(k){
          errores+= '- '+ errorResponse.error.error[k]+"<br>";
        });

        Swal.fire({
          title: 'UPS',
          icon: 'error',
          html: errores
        });
      }
    );

  }

  getIdiomas(){

    this._idiomasService.getIdiomas().subscribe(
      response => {
        
        if(response.status == 'success'){
          if(response.idiomas.length > 0){
            this.idiomasList = response.idiomas;
            // console.log(this.idiomasList);
          }
        }

      },
      errorResponse => {
        // console.log(errorResponse);
      }
    );

  }

  getIdiomasByUser(){
    this._idiomasService.getIdiomasByUser(this.token).subscribe(
      response => {
        
        if(response.status == 'success'){
          if(response.idiomas.length > 0){
            this.selectedIdiomas = response.idiomas;
          }
        }

      },
      errorResponse => {
        // console.log(errorResponse);
      }
    );
  }

  public calcularBarra(){
    let count = Object.keys(this.cadian).length;
    let inputs_vacios = 0;
    let noRequeridos = ['id', 'key_forma_pago', 'nombre','apellidos','correo','estatus','dias','horarios','servicios','certificados','key_servicios', 'check_autorizacion', 'key_tipo_cadian','key_aptitudes','puesto','key_uso_pc','key_experiencia','key_idiomas','key_formacion', 'facebook', 'instagram', 'tarifa_mensual', 'key_forma_pago', 'key_sector', 'key_dni'];
    if(this.cadian.estatus != 1){
      noRequeridos.push('carta_no_antecedentes', 'comprobante_factura');
    }
    Object.entries(this.cadian).forEach(([key, value]) => {
      if(noRequeridos.includes(key)){
        count--;
        return;
      }

      if((value == '' || value == null || value == undefined) && !Array.isArray(value)){
          inputs_vacios++;
      }
    });

    let porcentaje = 100/count;
    this.percent_progress = Math.ceil(porcentaje * (count - inputs_vacios)) >= 100 ? 100 : Math.ceil(porcentaje * (count - inputs_vacios));

  }

  onHabilidadSelect($event){
    const id_subcategoria = $event.id_subcategoria;
    let id_servicio = 0;
    
    this.habilidadesList.forEach(element => {
      if(id_subcategoria == element.id_subcategoria){
        id_servicio = element.id_servicio;
      }
    });

    let count = 0;
    this.selectedHabilidades.forEach(element => {
      this.habilidadesList.forEach(element2 => {
        if(element.id_subcategoria == element2.id_subcategoria){
          if(element2.id_servicio == id_servicio){
            count++;
          }
        }
      });
    });

    if(count == 1){
      this.experiencia_servicio[id_servicio] = this.serviciosList[id_servicio];
      this.experiencia_servicio[id_servicio].key_experiencia = 0;
    }

    // console.log(this.experiencia_servicio);
  }

  onHabilidadUnselect($event){
    const id_subcategoria = $event.id_subcategoria;
    let id_servicio = 0;
    
    this.habilidadesList.forEach(element => {
      if(id_subcategoria == element.id_subcategoria){
        id_servicio = element.id_servicio;
      }
    });

    // console.log(id_servicio)

    let count = 0;
    this.selectedHabilidades.forEach(element => {
      this.habilidadesList.forEach(element2 => {
        if(element.id_subcategoria == element2.id_subcategoria){
          if(element2.id_servicio == id_servicio){
            count++;
          }
        }
      });
    });


    if(count == 0){
      Object.keys(this.experiencia_servicio).forEach(key => {
        // console.log(this.experiencia_servicio[key].id_servicio);
        if(this.experiencia_servicio[key].id_servicio == id_servicio){
          this.experiencia_servicio.splice(parseInt(key), 1 );
        }
      });
    }

  }

  getServicios(){
    this._servicioService.getServicios().subscribe(
      response => {
        
        if(response.status == 'success'){
          response.servicios.forEach(element => {
            this.serviciosList[element.id_servicio] = {
              id_servicio : element.id_servicio,
              nombre: element.nombre
            }
          });
        }
      },
      errorResponse => {
        // console.log(errorResponse);
      }
    );
  }

  getServiciosByUser(){
    this._servicioService.getServiciosByUser(this.token).subscribe(
      response => {
        
        if(response.status == 'success'){
          response.servicios.forEach(element => {
            this.experiencia_servicio[element.id_servicio] = {
              id_servicio : element.id_servicio,
              nombre: element.nombre,
              key_experiencia: element.key_experiencia
            }
          });
        }
      },
      errorResponse => {
        // console.log(errorResponse);
      }
    );

    // console.log(this.experiencia_servicio);
  }

  }
