//FUNCIONES Y VARIABLES GLOBALES

import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class AppGlobals {
    
FirstChartUpper(string){
    return string.replace( /(^|\s)([a-z])/g , function(m,p1,p2){ return p1+p2.toUpperCase(); });
}

}