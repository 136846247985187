import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PerfilPublicoService } from 'src/app/services/perfil-publico.service';
import { global } from '../../services/global';
import { faCalendar, faStar } from '@fortawesome/free-solid-svg-icons';
import { DesbloqueoCuidadorService } from 'src/app/services/desbloqueoCuidador.service';
import * as UIkit from 'uikit';
import { ClienteService } from 'src/app/services/cliente.service';
import Swal from 'sweetalert2';
import { CargosService } from 'src/app/services/cargos.service';
import { MembresiasService } from 'src/app/services/membresias.service';



@Component({
  selector: 'app-perfil-publico',
  templateUrl: './perfil-publico.component.html',
  styleUrls: ['./perfil-publico.component.css']
})
export class PerfilPublicoComponent implements OnInit {

  public identity;
  public token;
  public details;
  public perfil: any = {};
  public url;
  public horario: any[] = [];
  public opiniones = [];
  faCalendar = faCalendar;
  faStar = faStar;
  p: number = 1;
  public cargos = [];
  public membresia = [];

  constructor(
    public _userService: UserService,
    public _perfilPublicoService: PerfilPublicoService,
    private _router: Router,
    private _route: ActivatedRoute,
    public _clienteService: ClienteService,
    public _desbloqueoService: DesbloqueoCuidadorService,
    public _membresiasService: MembresiasService,
    public _cargosService: CargosService
    
  ) { 
    this.loadUser();
    this.checkPerfil();
    this.getCargos();
    this.getMembresiaEstatus();
    this.url = global.url;
  }

  ngOnInit() {
  }

  getMembresiaEstatus(){

    this._membresiasService.getMembresiaEstatus(this.token).subscribe(
      response => {
        
        if(response.status == 'success'){
          this.membresia = response.membresia;
        }

      },
      errorResponse => {
        // ...
      }
    );

  }

  loadUser(){
    this.identity = this._userService.getIdentity();
    this.token = this._userService.getToken();
    this.details = this._userService.getDetails();
  }

  getCargos(){
    this._cargosService.getCargos().subscribe(
      response => {
        if(response.status == 'success'){
          this.cargos = response.cargos;
        }
      },
      errorResponse => {
        // ...
      }
    );
  }

  checkPerfil(){

    this._route.params.subscribe(params => {

      let token_perfil = params['token'];

      if(token_perfil != undefined || token_perfil != ''){

          this._perfilPublicoService.checkPerfilPublico(this.token, token_perfil).subscribe(
            response => {

              if(response.status == 'success'){
                this.perfil = response.perfil;
                console.log('this.perfil', this.perfil);

                if (response.perfil.horario != null) {
                  this.horario = JSON.parse(response.perfil.horario.horario);
                }

                this.opiniones = response.perfil.opiniones;
              }

            },
            errorResponse => {
              this._router.navigate(['/']);
            }
          );
      }
      else{
        this._router.navigate(['/']);
      }

    });
}

dia(numero){
  numero--;
  let dias = ['Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo'];
  return dias[numero];
}

public explode(str): any[] {
  let response = [];

  if (str != '' && str != null && str != undefined) {
    response = str.split(',');
  }

  return response;
}

rating(puntos){

  let arr = [false, false, false, false, false];
  for(let i = 0; i < arr.length ; i++){
    if(i+1 <= puntos){
      arr[i] = true;
    }
    else if(i+1 > puntos){
      arr[i] = false;
    }
  }

  return arr;
}

contactar($event){

  let form = new FormData();
  form.append('id_cadian', this.perfil.id_cadian);
  
  //CONSULTAMOS SI EL CUIDADOR A CONTACTAR YA FUE CONTACTADO ANTERIORMENTE
  this._desbloqueoService.checkDesbloqueo(this.token, form).subscribe(
    response => {
      
      if(response.status == 'success'){
        this._router.navigate(['/mensajeria/'+response.token_conversacion]);
      } else {
        let modal = UIkit.modal("#modal-desbloqueo");
        modal.show();
      }

    },
    errorResponse => {
      // ...
    }
  );
}

pagarDesbloqueo(event: any) {

  //DESHABILITAMOS EL BOTON
  event.target.disabled = true;

  let form = new FormData();
  form.append('id_cadian', this.perfil.id_cadian);

  Swal.fire({
    title: 'Un momento',
    icon: 'info',
    text: 'Confirmanos que quieres pagar el desbloqueo de este cuidador',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Pagar'
  }).then((result) => {
    if (result.value) {
      this._clienteService.pagarDesbloqueoBalance(this.token, form).subscribe(
        response => {
  
          if(response.status == 'success'){
            Swal.fire('¡Muy bien!', response.message , 'success');
  
            let modal = UIkit.modal("#modal2");
            modal.show();
          }
          else{
            Swal.fire('UPS', response.message , 'error');
          }
  
        },
        errorResponse => {
          // ...
        }
      );
    }
  });

}

habilidades(str){
  let response = [];
  if(str != '' || str != null){
    response = str.split(',');
  }

  return response;
}


public aptitudes(str): any[] {

  let response = [];

  if (str != '' && str != null && str != undefined) {
    response = str.split(',');
  }

  return response;
}

explodeHorario(str){
  const horario = str.split(' ');
  return horario[1];
}

}
