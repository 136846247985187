import { Component, OnInit, ViewEncapsulation, AfterViewInit, OnDestroy } from '@angular/core';
import { UserService } from '../../services/user.service';
import { Cadian } from '../../models/cadian';
import { global } from '../../services/global';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { trigger } from '@angular/animations';
import { AppGlobals } from 'src/app/app.global';
import { BalanceService } from 'src/app/services/balance.service';
import { CadianService } from 'src/app/services/cadian.service';
import { Router, NavigationEnd } from '@angular/router';
import Swal from 'sweetalert2';
import * as UIkit from 'uikit';
declare let $: any;

@Component({
	selector: 'app-perfil-cadian',
	templateUrl: './perfil-cadian.component.html',
	styleUrls: ['./perfil-cadian.component.css'],
	encapsulation: ViewEncapsulation.None
})
export class PerfilCadianComponent implements OnInit, AfterViewInit, OnDestroy {
	public cadian: Cadian;
	public identity: any;
	public token: any;
	public details: any;
	public status: string;
	public url: string;
	public imageChangedEvent: any = '';
	public croppedImage: any = '';
	public loadingImage: any;
	public provincia: any;
	public municipio: any;
	public distrito: any;
	public percent_progress: number = 0;
	public balance: any;
	public ingreso: any;
	public stats: any;
	private detailsChangeEventSubs: any;
	private routerEventsSubs: any;
	private ngAfterViewInitLaunched: boolean = false;

	// This variable store the adress data of the cadian
	private addressData: any = {
		street: '',
		outsideNumber: '',
		sector: '',
		city: '',
		province: ''
	};

	// The following variable ("requiredFields") is used to verify the profile
	// progress percent of the cadian. The "requiredFields.property.field" is
	// just an indentifier and it can be any string value.
	private requiredFields: any = {
		// Cadian data
		profile_img: {
			field: 'cadian.foto',
			filled: false,
			popover_content: '#profile_img_popover_content',
			popover_to: '#profile_img'
		},
		gender: {
			field: 'cadian.key_genero',
			filled: false,
			popover_content: '#cadian_gender_and_birth_date_popover_content2',
			popover_to: '#menu_options'
		},
		birth_date: {
			field: 'cadian.fecha_nacimiento',
			filled: false,
			popover_content: '#cadian_gender_and_birth_date_popover_content2',
			popover_to: '#menu_options'
		},
		fare1: {
			field: 'cadian.tarifa_hora',
			filled: false,
			popover_content: '#cadian_fares_popover_content2',
			popover_to: '#menu_options'
		},
		fare2: {
			field: 'cadian.tarifa_hora2',
			filled: false,
			popover_content: '#cadian_fares_popover_content2',
			popover_to: '#menu_options'
		},
		fare3: {
			field: 'cadian.tarifa_hora3',
			filled: false,
			popover_content: '#cadian_fares_popover_content2',
			popover_to: '#menu_options'
		},
		fare4: {
			field: 'cadian.tarifa_mensual',
			filled: false,
			popover_content: '#cadian_fares_popover_content2',
			popover_to: '#menu_options'
		},
		about_me: {
			field: 'cadian.acerca_de_mi',
			filled: false,
			popover_content: '#cadian_about_me_popover_content2',
			popover_to: '#menu_options'
		},

		// Cadian address
		street: {
			field: 'addressData.street',
			filled: false,
			popover_content: '#cadian_address_popover_content2',
			popover_to: '#menu_options'
		},
		outside_number: {
			field: 'addressData.outsideNumber',
			filled: false,
			popover_content: '#cadian_address_popover_content2',
			popover_to: '#menu_options'
		},
		sector: {
			field: 'addressData.sector',
			filled: false,
			popover_content: '#cadian_address_popover_content2',
			popover_to: '#menu_options'
		},
		city: {
			field: 'addressData.city',
			filled: false,
			popover_content: '#cadian_address_popover_content2',
			popover_to: '#menu_options'
		},
		province: {
			field: 'addressData.province',
			filled: false,
			popover_content: '#cadian_address_popover_content2',
			popover_to: '#menu_options'
		},
		telephone: {
			field: 'cadian.telefono',
			filled: false,
			popover_content: '#cadian_address_popover_content2',
			popover_to: '#menu_options'
		},

		// Cadian documents
		dni_img: {
			field: 'cadian.foto_dni',
			filled: false,
			popover_content: '#cadian_documents_popover_content2',
			popover_to: '#menu_options'
		},
		dni_img_verify: {
			field: 'cadian.foto_dni_comprobacion',
			filled: false,
			popover_content: '#cadian_documents_popover_content2',
			popover_to: '#menu_options'
		}
	};

	constructor(
		public userService: UserService,
		public cadianService: CadianService,
		public balanceService: BalanceService,
		public appGlobals: AppGlobals,
		private router: Router
	) { 
		this.url = global.url;
		this.cadian = new Cadian(1,'','',1,1,1,'',1,'',1,1,'','','','','','','','',1,1,1,[],[],'','',[],[],0,[],'','',0,0,0,'',[],'','','',0,0);
		this.stats = {reservas: 0, puntos: 0};

		this.routerEventsSubs = this.router.events.subscribe((event: any) => {
			if (event instanceof NavigationEnd) {
				this.requiredFields.gender.popover_content = '#cadian_gender_and_birth_date_popover_content2';
				this.requiredFields.gender.popover_to = '#menu_options';
				this.requiredFields.birth_date.popover_content = '#cadian_gender_and_birth_date_popover_content2';
				this.requiredFields.birth_date.popover_to = '#menu_options';
				this.requiredFields.fare1.popover_content = '#cadian_fares_popover_content2';
				this.requiredFields.fare1.popover_to = '#menu_options';
				this.requiredFields.fare2.popover_content = '#cadian_fares_popover_content2';
				this.requiredFields.fare2.popover_to = '#menu_options';
				this.requiredFields.fare3.popover_content = '#cadian_fares_popover_content2';
				this.requiredFields.fare3.popover_to = '#menu_options';
				this.requiredFields.fare4.popover_content = '#cadian_fares_popover_content2';
				this.requiredFields.fare4.popover_to = '#menu_options';
				this.requiredFields.about_me.popover_content = '#cadian_about_me_popover_content2';
				this.requiredFields.about_me.popover_to = '#menu_options';
				this.requiredFields.street.popover_content = '#cadian_address_popover_content2';
				this.requiredFields.street.popover_to = '#menu_options';
				this.requiredFields.outside_number.popover_content = '#cadian_address_popover_content2';
				this.requiredFields.outside_number.popover_to = '#menu_options';
				this.requiredFields.sector.popover_content = '#cadian_address_popover_content2';
				this.requiredFields.sector.popover_to = '#menu_options';
				this.requiredFields.city.popover_content = '#cadian_address_popover_content2';
				this.requiredFields.city.popover_to = '#menu_options';
				this.requiredFields.province.popover_content = '#cadian_address_popover_content2';
				this.requiredFields.province.popover_to = '#menu_options';

				if (event.url == '/perfil-cadian/mi-cuenta') {
					this.requiredFields.gender.popover_content = '#cadian_gender_and_birth_date_popover_content';
					this.requiredFields.gender.popover_to = '#menu_content';
					this.requiredFields.birth_date.popover_content = '#cadian_gender_and_birth_date_popover_content';
					this.requiredFields.birth_date.popover_to = '#menu_content';
					this.requiredFields.fare1.popover_content = '#cadian_fares_popover_content';
					this.requiredFields.fare1.popover_to = '#menu_content';
					this.requiredFields.fare2.popover_content = '#cadian_fares_popover_content';
					this.requiredFields.fare2.popover_to = '#menu_content';
					this.requiredFields.fare3.popover_content = '#cadian_fares_popover_content';
					this.requiredFields.fare3.popover_to = '#menu_content';
					this.requiredFields.fare4.popover_content = '#cadian_fares_popover_content';
					this.requiredFields.fare4.popover_to = '#menu_content';
					this.requiredFields.about_me.popover_content = '#cadian_about_me_popover_content';
					this.requiredFields.about_me.popover_to = '#menu_content';
					this.requiredFields.street.popover_content = '#cadian_address_popover_content';
					this.requiredFields.street.popover_to = '#menu_content';
					this.requiredFields.outside_number.popover_content = '#cadian_address_popover_content';
					this.requiredFields.outside_number.popover_to = '#menu_content';
					this.requiredFields.sector.popover_content = '#cadian_address_popover_content';
					this.requiredFields.sector.popover_to = '#menu_content';
					this.requiredFields.city.popover_content = '#cadian_address_popover_content';
					this.requiredFields.city.popover_to = '#menu_content';
					this.requiredFields.province.popover_content = '#cadian_address_popover_content';
					this.requiredFields.province.popover_to = '#menu_content';
				}

				this.requiredFields.dni_img.popover_content = '#cadian_documents_popover_content2';
				this.requiredFields.dni_img.popover_to = '#menu_options';
				this.requiredFields.dni_img_verify.popover_content = '#cadian_documents_popover_content2';
				this.requiredFields.dni_img_verify.popover_to = '#menu_options';

				if (event.url == '/perfil-cadian/documentos') {
					this.requiredFields.dni_img.popover_content = '#cadian_documents_popover_content';
					this.requiredFields.dni_img.popover_to = '#menu_content';
					this.requiredFields.dni_img_verify.popover_content = '#cadian_documents_popover_content';
					this.requiredFields.dni_img_verify.popover_to = '#menu_content';
				}

				this.showPopoverHelp();
			}
		});
	}

	public ngOnInit(): void {
		this.loadUser();
		this.getStats();
	}

	public ngAfterViewInit(): void {
		this.getCadianAddress();

		this.detailsChangeEventSubs = this.userService.detailsChangeEvent.subscribe(() => {
			this.loadUser();
			this.getCadianAddress();
		});

		this.ngAfterViewInitLaunched = true;
	}

	private refreshProgressBarPercent(): void {
		let fieldsFilled = 0;

		for (let property in this.requiredFields) {
			if (this.requiredFields[property].filled) {
				fieldsFilled++;
			}
		}

		let percent = (100 / Object.keys(this.requiredFields).length) * fieldsFilled;
		this.percent_progress = percent >= 100 ? 100 : Math.ceil(percent);
		this.showPopoverHelp();
	}

	private showPopoverHelp(): void {
		if (this.ngAfterViewInitLaunched) {
			$('.element-with-popover').popover('dispose');

			for (let property in this.requiredFields) {
				if (!this.requiredFields[property].filled) {
					$(this.requiredFields[property].popover_to).popover({
						html: true,
						container: 'body',
						content: $(this.requiredFields[property].popover_content).html(),
						placement: 'top',
						fallbackPlacement: ['top'],
						trigger: 'manual'
					});

					$(this.requiredFields[property].popover_to).popover('show');
					break;
				}
			}
		}
	}

	public ngOnDestroy(): void {
		$('.element-with-popover').popover('dispose');
		this.detailsChangeEventSubs.unsubscribe();
		this.routerEventsSubs.unsubscribe();
	}

	public loadUser(): void {
		this.identity = this.userService.getIdentity();
		this.token = this.userService.getToken();
		this.details = this.userService.getDetails();

		this.cadian = new Cadian(
			this.identity.sub,
			this.details.nombre,
			this.details.apellidos,
			this.details.key_provincia,
			this.details.key_municipio,
			this.details.key_sector,
			this.details.key_servicios,
			this.details.numero,
			this.details.direccion,
			this.details.key_tipo_cadian,
			this.details.key_genero,
			this.details.correo,
			this.details.telefono,
			this.details.foto,
			this.details.key_dni,
			this.details.foto_dni,
			this.details.foto_dni_comprobacion,
			this.details.key_formacion,
			this.details.key_idiomas,
			this.details.key_uso_pc,
			this.details.key_forma_pago,
			this.details.estatus,
			[],
			[],
			this.details.acerca_de_mi,
			this.details.puesto,
			[],
			[],
			this.details.check_autorizacion,
			[],
			this.details.carta_no_antecedentes,
			this.details.comprobante_factura,
			this.details.tarifa_hora,
			this.details.tarifa_hora2,
			this.details.tarifa_hora3,
			this.details.fecha_nacimiento,
			this.details.key_aptitudes,
			this.details.key_experiencia,
			this.details.facebook,
			this.details.instagram,
			this.details.tarifa_mensual,
			this.details.codigo_postal,
			this.details.ser_autonomo
		);

		if (this.cadian.foto != null) this.requiredFields.profile_img.filled = true;
		if (this.cadian.key_genero != null) this.requiredFields.gender.filled = true;
		if (this.cadian.fecha_nacimiento != null) this.requiredFields.birth_date.filled = true;
		if (this.cadian.tarifa_hora != null) this.requiredFields.fare1.filled = true;
		if (this.cadian.tarifa_hora2 != null) this.requiredFields.fare2.filled = true;
		if (this.cadian.tarifa_hora3 != null) this.requiredFields.fare3.filled = true;
		if (this.cadian.acerca_de_mi != null) this.requiredFields.about_me.filled = true;
		if (this.cadian.tarifa_mensual != null) this.requiredFields.fare4.filled = true;
		if (this.cadian.telefono != null) this.requiredFields.telephone.filled = true;
		if (this.cadian.foto_dni != null) this.requiredFields.dni_img.filled = true;
		if (this.cadian.foto_dni_comprobacion != null) this.requiredFields.dni_img_verify.filled = true;

		this.getBalance();
		this.getIngreso();
	}

	private getCadianAddress(): void {
		this.userService.getUserData(this.userService.getToken()).subscribe(
			(response: any) => {
				this.addressData.street = response.street;
				this.addressData.outsideNumber = response.outside_number;
				this.addressData.sector = response.sector;
				this.addressData.city = response.city;
				this.addressData.province = response.province;
				if (this.addressData.street != '') this.requiredFields.street.filled = true;
				if (this.addressData.outsideNumber != '') this.requiredFields.outside_number.filled = true;
				if (this.addressData.sector != '') this.requiredFields.sector.filled = true;
				if (this.addressData.city != '') this.requiredFields.city.filled = true;
				if (this.addressData.province != '') this.requiredFields.province.filled = true;
				this.refreshProgressBarPercent();
			},
			(error: any) => {
				this.refreshProgressBarPercent();
			}
		);
	}

	public getBalance(): void {
		this.balanceService.getBalanceByUser(this.token).subscribe(
			(response: any) => {
				if (response.status == 'success') {
					this.balance = response.balance;
				}
			},
			(error: any) => {
				// ...
			}
		);
	}

	public getIngreso(): void {
		this.balanceService.getIngresoByUser(this.token).subscribe(
			(response: any) => {
				if (response.status == 'success') {
					this.ingreso = response.ingresos;
				}
			},
			(error: any) => {
				// ...
			}
		);
	}

	public getStats(): void {
		this.cadianService.stats(this.token).subscribe(
			(response: any) => {
				if (response.status == 'success') {
					this.stats.reservas = response.reservas.count;
					this.stats.puntos = response.puntos;
				}
			},
			(error: any) => {
				// ...
			}
		);
	}

	public submitImage(): void {
		const formData = new FormData();
		formData.append('profile', this.croppedImage);

		Swal.fire({
			title: "Subiendo imagen",
			text: "Espera unos segundos...",
			imageUrl: "../../../assets/images/loading.gif",
			showConfirmButton: false,
			allowOutsideClick: false
		});

		this.userService.updateImageProfile(this.token, formData).subscribe(
			(response: any) => {
				if (response.status == 'success') {
					Swal.fire('Imagen actualizada', response.message , 'success');
					this.details = response.changes;
					localStorage.setItem('details', JSON.stringify(this.details));
					let modal = UIkit.modal(".cropper");
					modal.hide();
					$('#profile_img').popover('dispose');
					this.userService.detailsChangeEvent.emit();
				}
			},
			(error: any) => {
				let errores = '';

				Object.keys(error.error.error).forEach(k => {
					errores+= '- '+ error.error.error[k]+"<br>";
				});

				Swal.fire({
					title: 'UPS',
					icon: 'error',
					html: errores
				});
			}
		);
	}

	public changeImage(event: any): void {
		if (event.target.files.length > 0) {
			this.imageChangedEvent = event;
			this.loadingImage = true;
			let modal = UIkit.modal(".cropper");
			modal.show();
		}
	}

	public imageCropped(event: ImageCroppedEvent): void {
		const imageName = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15) + '.png';
		const imageBlob = this.dataURItoBlob(event.base64);
		const imageFile = new File([imageBlob], imageName, { type: 'image/png' });
		this.croppedImage = imageFile;
	}

	public imageLoaded(): void {
		this.loadingImage = false;
	}

	public startCropImage(): void {
		//...
	}

	public cropperReady(): void {
		//...
	}

	public loadImageFailed(): void {
		//...
	}

	public dataURItoBlob(dataURI): any {
		let base64 = dataURI.replace(/^[^,]+,/, '');
		const byteString = atob(base64);
		const arrayBuffer = new ArrayBuffer(byteString.length);
		const int8Array = new Uint8Array(arrayBuffer);

		for (let i = 0; i < byteString.length; i++) {
			int8Array[i] = byteString.charCodeAt(i);
		}

		const blob = new Blob([int8Array], { type: 'image/png' });
		return blob;
	}
}
