import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../models/user';
import { global } from './global';

@Injectable({
    providedIn: 'root'
})

export class PaymentService{

    public url: string;
    public identity;
    public token;
    public details;
    public cadianStorage;

    constructor(
        public _http: HttpClient
    ){
        this.url = global.url;
    }

    verifyTransaction(token,date): Observable<any>{
        let json = JSON.stringify(date);
        let params = 'json='+json;

        let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                       .set('Authorization', token);

        return this._http.post(this.url+'user/verify-transaction', params, {headers: headers});
    }
}