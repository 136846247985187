import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { global } from './global';

@Injectable({
    providedIn: 'root'
})
export class CadianService {
    url: string;
    identity;
    token;
    details;
    cadianStorage;

    constructor(public _http: HttpClient) {
        this.url = global.url;
    }

    update(token,user): Observable<any> {
        let json = JSON.stringify(user);
        let params = 'json='+json;

        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
        headers = headers.set('Authorization', token);

        return this._http.put(this.url+'user/update-cadian', params, {headers: headers});
    }
    
    updateContrasena(token, password, currentPassword): Observable<any> {
        let obj = {"new_password":password, "current_password":currentPassword}; // Crear objeto
        let headers = new HttpHeaders().set('Authorization', token);
        return this._http.post(this.url+'user/password/change', obj, {headers: headers});
    }

    updateCurriculum(token,user): Observable<any> {
        let json = JSON.stringify(user);
        let params = 'json='+json;

        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
        headers = headers.set('Authorization', token);

        return this._http.put(this.url+'user/update-cadian-curriculum', params, {headers: headers});
    }

    updateReferencias(token,referencias_personales, referencias_laborales): Observable<any> {
        let refp_json = JSON.stringify(referencias_personales);
        let refl_json = JSON.stringify(referencias_laborales);
        let params = 'referencias_personales='+refp_json+'&referencias_laborales='+refl_json;

        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
        headers = headers.set('Authorization', token);

        return this._http.put(this.url+'user/update-cadian-referencias', params, {headers: headers});
    }

    getHorarios(token):Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set('Authorization', token);
        return this._http.get(this.url+'user/horarios',{headers: headers});
    }

    getReferencias(token): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set('Authorization', token);
        return this._http.get(this.url+'user/referencias', {headers: headers});
    }

    getCadianStorage() {
        let cadian = JSON.parse(localStorage.getItem('cadian'));

        if (cadian && cadian != 'undefined') {
            this.cadianStorage = cadian;
        } else {
            this.cadianStorage = null;
        }

        return this.cadianStorage;
    }

    updateImageFotoComprobacion(token, formData: any): Observable<any> {
        let headers = new HttpHeaders().set('Authorization', token);
        return this._http.post(this.url+'user/upload-foto-comprobacion', formData, {headers: headers});
    }

    updateFacturaComprobacion(token, formData: any): Observable<any> {
        let headers = new HttpHeaders().set('Authorization', token);
        return this._http.post(this.url+'user/upload-factura-comprobacion', formData, {headers: headers});
    }

    updateCartaNoAntecedentes(token, formData: any): Observable<any> {
        let headers = new HttpHeaders().set('Authorization', token);
        return this._http.post(this.url+'user/upload-carta-antecedentes', formData, {headers: headers});
    }

    serAutonomo(token): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
        headers = headers.set('Authorization', token);
        return this._http.put(this.url+'user/change-cadian', null, {headers: headers});
    }

    pagarVerificacionBalance(token, cadian): Observable<any> {
        let json = JSON.stringify(cadian);
        let params = 'json='+json;
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
        headers = headers.set('Authorization', token);
        return this._http.post(this.url+'user/pagar-verificacion-balance', params, {headers: headers});
    }

    enviarPostulacion(token, formData: any): Observable<any> {
        let headers = new HttpHeaders().set('Authorization', token);
        return this._http.post(this.url+'user/enviar-postulacion', formData, {headers: headers});
    }

    stats(token):Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set('Authorization', token);
        return this._http.get(this.url+'user/cadian-stats',{headers: headers});
    }

    getPrice(token, oferta,tipo): Promise<any> {
        let headers = new HttpHeaders();
        headers = headers.set('Authorization', token);
        return this._http.get(this.url+'user/checkPrice/'+oferta+'/'+tipo,{headers: headers}).toPromise();
    }
}