import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { global } from './global';

@Injectable({
  providedIn: 'root'
})
export class BalanceService {
  url: string;

  constructor(private _http: HttpClient) {
    this.url = global.url;
  }

  getBalanceByUser(token): Observable<any> {
    let headers = new HttpHeaders().set('Authorization', token);
    return this._http.get(this.url + 'user/balance', {headers: headers});
  }

  getIngresoByUser(token): Observable<any> {
    let headers = new HttpHeaders().set('Authorization', token);
    return this._http.get(this.url+'user/ingreso',{headers: headers});
  }
}
