import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { global } from './global';

@Injectable({
  providedIn: 'root'
})
export class FavoritoService {

  public url: string;

  constructor(
    private _http: HttpClient
  ) { 
    this.url = global.url;
  }

  favorito(token, tipo, referencia):Observable<any>{
    let params = new FormData();
    params.append('referencia', referencia);
    params.append('tipo', tipo);

    let headers = new HttpHeaders().set('Authorization', token);
                            
    return this._http.post(this.url+'user/favorito', params, {headers: headers});
  }

  getFavoritos(token):Observable<any>{
    let headers = new HttpHeaders().set('Authorization', token);
    return this._http.get(this.url+'user/favoritos',{headers: headers});
  }
}
