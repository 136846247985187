import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { Cliente } from 'src/app/models/cliente';
import { ClienteService } from 'src/app/services/cliente.service';
import Swal from 'sweetalert2';

@Component({
	selector: 'app-documentos2',
	templateUrl: './documentos.component.html',
	styleUrls: ['./documentos.component.css']
})
export class DocumentosComponent2 implements OnInit {
	public cliente : Cliente;
	public identity: any;
	public token: any;
	public details: any;
	public uploadedFiles: Array<File> = [];
	public fotoComprobacionFile: Array<File> = [];
	public facturaComprobacionFile: Array<File> = [];
	public cartaFile: Array<File> = [];
	public percent_progress: any;
	public error_upload: any;

	// The following variable ("requiredFields") is used to verify the profile
	// progress percent of the client. The "requiredFields.property.field" is
	// just an indentifier and it can be any string value.
	private requiredFields: any = {
		// Client data
		profile_img: {field: 'cliente.foto', filled: false},
		gender: {field: 'cliente.key_genero', filled: false},
		about_me: {field: 'cliente.acerca_de_mi', filled: false},

		// Client address
		street: {field: 'addressData.street', filled: false},
		outside_number: {field: 'addressData.outsideNumber', filled: false},
		sector: {field: 'addressData.sector', filled: false},
		city: {field: 'addressData.city', filled: false},
		province: {field: 'addressData.province', filled: false},
		telephone: {field: 'cliente.telefono', filled: false},

		// Client documents
		dni_img: {field: 'cliente.foto_dni', filled: false},
		dni_img_verify: {field: 'cliente.foto_dni_comprobacion', filled: false}
	};

	constructor(
		public _userService: UserService,
		public _clienteService: ClienteService,
	) { 
		this.cliente = new Cliente(1, '', '', '1', '1', '1', 1, '', '', 1, '', '', '', '', '', 1, '', '', '', 0, '', 0, '', '', 0, 0);
		this.loadUser();
	}

	public ngOnInit(): void {
		this.refreshDocumentsNames();
	}

	private refreshDocumentsNames(): void {
		this._userService.getClientDocumentsNames(this.token).subscribe(
			(response: any) => {
				this.details.foto_dni = response.foto_dni;
				this.details.foto_dni_comprobacion = response.foto_dni_comprobacion;
				localStorage.setItem('details', JSON.stringify(this.details));
				this._userService.detailsChangeEvent.emit();
			},
			(error: any) => {
				// ...
			}
		);
	}

	private refreshProgressBarPercent(): void {
		let fieldsFilled = 0;

		for (let property in this.requiredFields) {
			if (this.requiredFields[property].filled) {
				fieldsFilled++;
			}
		}

		let percent = (100 / Object.keys(this.requiredFields).length) * fieldsFilled;
		this.percent_progress = percent >= 100 ? 100 : Math.ceil(percent);
	}

	public onSubmit(form: any): void {
		this.refreshProgressBarPercent();

		if (this.cliente.check_autorizacion == 0 && this.percent_progress == 100) {
			Swal.fire({
				title: 'Un momento',
				icon: 'info',
				text: 'Al aceptar estas afirmando que los datos ingresados son reales y que estos serán utilizados para procesar su depuración.',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Aceptar'
			}).then((result) => {
				if (result.value) {
					this.cliente.check_autorizacion = 1;
					this.updateCliente();
				}
			});
		} else {
			this.updateCliente();
		}
	}

	public updateCliente() {
		this.error_upload = false;

		if (this.uploadedFiles.length > 0) {
			this.submitImageCedula();
		}

		if (this.fotoComprobacionFile.length > 0) {
			this.submitFotoComprobacionFile();
		}

		let promise = new Promise((resolve, reject) => {
			setTimeout(() => {
				if (!this.error_upload) {
					resolve('Todo bien');
				} else {
					reject('Algo estuvo mal');
				}
			}, 1000);
		});

		promise.then(() => {
			Swal.fire('Documentos actualizados', 'Tus documentos han sido actualizados con éxito.' , 'success');
		});
	}

	public loadUser(): void {
		this.identity = this._userService.getIdentity();
		this.token = this._userService.getToken();
		this.details = this._userService.getDetails();

		this.cliente = new Cliente(
			this.identity.sub, 
			this.details.nombre, 
			this.details.apellidos,
			this.details.key_provincia,
			this.details.key_municipio,
			this.details.key_sector,
			this.details.numero,
			this.details.direccion,
			this.details.tipo_cliente,
			this.details.key_genero,
			this.details.correo,
			this.details.telefono,
			this.details.foto,
			this.details.key_dni,
			this.details.foto_dni,
			this.details.forma_pago,
			this.details.estatus,
			this.details.acerca_de_mi,
			this.details.puesto,
			this.details.check_autorizacion,
			this.details.foto_dni_comprobacion,
			this.details.solicitudes_autonomas,
			this.details.facebook,
			this.details.instagram,
			this.details.codigo_postal,
			this.details.key_provincia
		);

		if (this.cliente.foto != null) this.requiredFields.profile_img.filled = true;
		if (this.cliente.key_genero != null) this.requiredFields.gender.filled = true;
		if (this.cliente.acerca_de_mi != null) this.requiredFields.about_me.filled = true;
		if (this.cliente.telefono != null) this.requiredFields.telephone.filled = true;
		if (this.cliente.foto_dni != null) this.requiredFields.dni_img.filled = true;
		if (this.cliente.foto_dni_comprobacion != null) this.requiredFields.dni_img_verify.filled = true;
		this.getClientAddress();
	}

	private getClientAddress(): void {
		this._userService.getUserData(this._userService.getToken()).subscribe(
			(response: any) => {
				if (response.street != '') this.requiredFields.street.filled = true;
				if (response.outside_number != '') this.requiredFields.outside_number.filled = true;
				if (response.sector != '') this.requiredFields.sector.filled = true;
				if (response.city != '') this.requiredFields.city.filled = true;
				if (response.province != '') this.requiredFields.province.filled = true;
			},
			(error: any) => {
				// ...
			}
		);
	}

	public submitImageCedula(): void {
		this.uploadedFiles.forEach((element) => {
			let formData = new FormData();
			let fileExtension = element.name.split(".").pop();
			let imageName = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15) + '.'+fileExtension;
			formData.append('cedula', element, imageName);
			let promise = this._userService.updateImageCedula(this.token, formData).toPromise();

			promise.then(
				(response) => {
					if (response.status == 'success') {
						this.details = response.changes;
						localStorage.setItem('details', JSON.stringify(this.details));
						this._userService.detailsChangeEvent.emit();
					}
				},
				(error) => {
					let errores = '';

					Object.keys(error.error.errors).forEach(function(k) {
						errores+= '- '+ error.error.errors[k]+"<br>";
					});

					Swal.fire({
						title: 'UPS',
						icon: 'error',
						html: errores
					});
				}
			);
		});
	}

	public submitFotoComprobacionFile(): void {
		this.fotoComprobacionFile.forEach((element) => {
			let formData = new FormData();
			let fileExtension = element.name.split(".").pop();
			let imageName = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15) + '.' + fileExtension;
			formData.append('foto_comp', element, imageName);

			this._clienteService.updateImageFotoComprobacion(this.token, formData).subscribe(
				(response: any) => {
					if (response.status == 'success') {
						this.details = response.changes;
						localStorage.setItem('details', JSON.stringify(this.details));
						this._userService.detailsChangeEvent.emit();
					}
				},
				(error: any) => {
					this.error_upload = true;
					let errores = '';

					Object.keys(error.error.errors).forEach(function(k) {
						errores+= '- '+ error.error.errors[k]+"<br>";
					});

					Swal.fire({
						title: 'UPS',
						icon: 'error',
						html: errores
					});
				}
			);
		});
	}
}
