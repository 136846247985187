import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CanActivate } from '@angular/router';
import { UserService } from '../services/user.service';

@Injectable({
    providedIn: 'root'
})

export class RegistroCadianGuard implements CanActivate {

    constructor(
        private authService: UserService,
        private router: Router
    ) { }

    canActivate() {
        let identity = this.authService.getIdentity();
        // If the user is not logged in we'll send them back to the home page
        if ((identity != null && identity.role_user == 'CLIENTE') || identity == null) {
            return true;
        }

        this.router.navigate(['/']);

        return false;
    }
}