import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { TutorialesService } from 'src/app/services/tutoriales.service';
import { global } from '../../services/global';

@Component({
  selector: 'app-tutoriales',
  templateUrl: './tutoriales.component.html',
  styleUrls: ['./tutoriales.component.css']
})
export class TutorialesComponent implements OnInit {
  public identity;
  public token;
  public details;
  public tutoriales = [];
  public tutoriales_busqueda;
  public busqueda;
  public loading: boolean = false;
  public p: number = 1;
  public urlAdminOffice: string;

  constructor(
    public _userService: UserService,
    public _tutorialesService: TutorialesService
  ) {
    this.urlAdminOffice = global.urlAdminOffice;
    this.loadUser();
  }

  ngOnInit() {
    this.getTutoriales();
  }

  loadUser(){
    this.identity = this._userService.getIdentity();
    this.token = this._userService.getToken();
    this.details = this._userService.getDetails();
  }

  getTutoriales(){
    this._tutorialesService.getTutoriales().subscribe(
      response => {

        if(response.status == 'success'){
            this.tutoriales = response.tutoriales;
        }

      },
      errorResponse => {
        console.log(errorResponse);
      }
    );
  }

  shortDesc(text){

    let text_replace = text.replace(/<(.|\n)*?>/g, "");
    let text_array = text_replace.split(' ');
    let short_text = '';

    for(let i = 0; i <= text_array.length; i++){
        if(i == 35 || text_array[i] == undefined){
          return short_text;
        }

        short_text+= text_array[i]+' ';
    }
  }

  setIndex(index){
    
    let arr = [];

    for(let i = index; i < this.tutoriales.length; i++){
      arr.push(this.tutoriales[i]);
    }

    return arr;
  }

  toSeoUrl(url) {
    return url.toString()               // Convert to string
        .normalize('NFD')               // Change diacritics
        .replace(/[\u0300-\u036f]/g,'') // Remove illegal characters
        .replace(/\s+/g,'-')            // Change whitespace to dashes
        .toLowerCase()                  // Change to lowercase
        .replace(/&/g,'-and-')          // Replace ampersand
        .replace(/[^a-z0-9\-]/g,'')     // Remove anything that is not a letter, number or dash
        .replace(/-+/g,'-')             // Remove duplicate dashes
        .replace(/^-*/,'')              // Remove starting dashes
        .replace(/-*$/,'');             // Remove trailing dashes
  }

  buscar(form){

    if(this.busqueda != undefined || this.busqueda != ''){
      this.loading = true;
      let formData = new FormData();
      formData.append('busqueda', this.busqueda);

      this._tutorialesService.buscar(formData).subscribe(
        response => {
          if(response.status == 'success'){
              this.loading = false;
              this.tutoriales_busqueda = response.resultados;
          }
        },
        errorResponse => {
          console.log(errorResponse);
        }
      );
    }
  }

}


