import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import Swal from 'sweetalert2';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.css'],
  // providers: [UserService]
  providers: []
})
export class ForgotComponent implements OnInit {

  public password_value;
  public password_repeat_value;
  public token;

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    public _userService : UserService
  ) { 
    this.token = null;
    this.password_value = '';
    this.password_repeat_value = '';
  }

  ngOnInit() {
    this.checkToken();
  }

  onSubmit(form : NgForm){

    const formData = new FormData();
    formData.append('password', form.value['password']);
    formData.append('token', this.token);

    this._userService.cambiarPassword(formData).subscribe(
      response => {

        console.log(response);
        
        if(response.status == 'success'){
          Swal.fire('Contraseña cambiada con éxito', response.message , 'success');
          form.reset();
          this._router.navigate(['/login']);
        }

      },
      errorResponse => {
        console.log(<any>errorResponse);
        console.log(errorResponse.error.error);
        
        let errores = JSON.stringify(errorResponse.error.error);

        Swal.fire('UPS...', errores , 'error');

      }
    );

  }

  checkToken(){
    this._route.params.subscribe(params => {

      let token = params['token'];

      if(token == '' || token == undefined){
        this._router.navigate(['/']);
        return;
      }

    const formData = new FormData();
    formData.append('token', token);

    this._userService.checkTokenForgot(formData).subscribe(
      response => {
        
        if(response.status == 'success'){
          this.token = token;
        }

      },
      errorResponse => {
        Swal.fire('UPS', 'Token inválido' , 'error');
          this._router.navigate(['/']);
      }
    );
      

    });
  }

}
