import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';
import { User } from '../../models/user';
import { UserService } from '../../services/user.service';
import { faUser, faLock, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { TiposClienteService } from 'src/app/services/tipos_cliente.service';
import { ConfigService } from 'src/app/services/config.service';
import Swal from 'sweetalert2';
declare let $: any;

@Component({
	selector: 'register',
	templateUrl: './register.component.html',
	styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit, AfterViewInit {
	public page_title: string;
	public user: User;
	public checkbox: any;
	public faUser: any = faUser;
	public faLock: any = faLock;
	public faEnvelope: any = faEnvelope;
	public tipos_cliente: any;
	private identity?: any = null;
	private details?: any = null;
	private token?: string = null;
	private attemptsRegister: number = 4;
	private currentAttempt: number = 0;
	public emailsDomainsData: any[] = [];
	public emailDomainId: string = '';

	constructor(
		private userService: UserService,
		private router: Router,
		private tiposClienteService: TiposClienteService,
		public configService: ConfigService,
		private activatedRoute: ActivatedRoute
	) {
		this.page_title = 'Registrate';
		this.user = new User(1, '', '', '', '', '', 0, '', '');
		this.checkbox = false;

		this.activatedRoute.queryParams.subscribe((params: any) => {
			if (params.token != undefined) {
				this.verifyUserEmail(
					params.token,
					params.email != undefined ? params.email : null
				);
			}
		});
	}

	public ngOnInit(): void {
		this.getTiposCliente();
		this.getEmailsDomainsData();
	}

	public ngAfterViewInit(): void {
		$('#register_email_field').popover({
			html: true,
			container: 'body',
			content: $('#email_popover_content').html(),
			placement: 'top',
			fallbackPlacement: ['top'],
			trigger: 'focus'
		});
	}

	public setEmailDomain(id: number , nextInput: any): void {
		if (id != this.emailsDomainsData.length) {
			for (let item of this.emailsDomainsData) {
				if (item.id_domain == id) {
					nextInput.value = '@' + item.name;
					break;
				}
			}
		} else {
			nextInput.value = '';
		}

		nextInput.focus();
		nextInput.setSelectionRange(0, 0);
	}

	private getEmailsDomainsData(): void {
		this.userService.getEmailsDomainsData().subscribe(
			(response: any) => {
				this.emailsDomainsData = response;

				if (this.emailsDomainsData.length > 0) {
					this.emailsDomainsData.push({
						id_domain: this.emailsDomainsData[this.emailsDomainsData.length - 1].id_domain + 1,
				        name: '- No Está -',
				        tag: '- No Está -'
					});
				} else {
					this.emailsDomainsData.push({
						id_domain: 1,
				        name: '- No Está -',
				        tag: '- No Está -'
					});
				}
			},
			(error: any) => {
				// ...
			}
		);
	}

	private verifyUserEmail(token: string, email?: string) {
		Swal.fire({
			icon: 'info',
			title: 'Verificando correo...',
			allowOutsideClick: false,
			allowEscapeKey: false,
			onOpen: () => { Swal.showLoading(); }
		});

		this.userService.verifyUserEmail(token).subscribe(
			(response: any) => {
				setTimeout(() => {
					this.identity = response.user;
					this.details = response.user.details;
					this.token = response.token;

					Swal.fire({
						icon: 'success',
						title: 'Correo verificado',
						html: '¡Excelente! has verificado correctamente tu correo y ahora ya puedes acceder a tu cuenta',
						allowOutsideClick: false,
						allowEscapeKey: false,
						showConfirmButton: true,
						confirmButtonText: 'Aceptar'
					}).then((result: any) => {
						this.directLogin();
					});
				}, 1000);
			},
			(error: any) => {
				setTimeout(() => {
					if (email != null) {
						Swal.fire({
							icon: 'error',
							title: 'Correo no verificado',
							html: 'Al parecer tuvimos un problema al intentar verificar tu correo. Por favor intenta de nuevo.',
							allowOutsideClick: false,
							allowEscapeKey: false,
							showConfirmButton: true,
							confirmButtonText: 'Intentar de nuevo',
							showCancelButton: true,
							cancelButtonText: 'Cancelar'
						}).then((result: any) => {
							if (result.isConfirmed) {
								this.verifyEmailAgain(email);
							}
						});
					} else {
						Swal.fire({
							icon: 'error',
							title: 'Correo no verificado',
							html: 'Al parecer tuvimos un problema con la verificación. Por favor escribe tu correo para verificarlo de nuevo.',
							input: 'text',
							allowOutsideClick: false,
							allowEscapeKey: false,
							showConfirmButton: true,
							confirmButtonText: 'Continuar',
							showCancelButton: true,
							cancelButtonText: 'Cancelar'
						}).then((result: any) => {
							if (result.isConfirmed) {
								this.verifyEmailAgain(result.value);
							}
						});
					}
				}, 1000);
			}
		);
	}

	private verifyEmailAgain(email: string): void {
		Swal.fire({
			icon: 'info',
			title: 'Verificando correo...',
			allowOutsideClick: false,
			allowEscapeKey: false,
			onOpen: () => { Swal.showLoading(); }
		});

		this.userService.verifyEmailAgain(email).subscribe(
			(response: any) => {
				Swal.fire({
					icon: 'success',
					title: response.message,
					html: response.information,
					allowOutsideClick: false,
					allowEscapeKey: false,
					showConfirmButton: false,
					showCancelButton: false
				});
			},
			(error: any) => {
				if (error.error.email != undefined || error.error.user_not_found != undefined) {
					Swal.fire({
						icon: 'error',
						title: 'Correo no valido',
						html: 'El correo no es válido, por favor revísalo e intenta de nuevo',
						input: 'text',
						allowOutsideClick: false,
						allowEscapeKey: false,
						showConfirmButton: true,
						confirmButtonText: 'Continuar',
						showCancelButton: true,
						cancelButtonText: 'Cancelar'
					}).then((result: any) => {
						if (result.isConfirmed) {
							this.verifyEmailAgain(result.value);
						}
					});
				} else {
					Swal.fire({
						icon: 'error',
						title: 'Error desconocido',
						html: 'Algo salió mal. Por favor intenta de nuevo y si el problema sigue contacta a soporte.',
						allowOutsideClick: false,
						allowEscapeKey: false,
						showConfirmButton: true,
						confirmButtonText: 'Intentar de nuevo',
						showCancelButton: true,
						cancelButtonText: 'Cancelar'
					}).then((result: any) => {
						if (result.isConfirmed) {
							this.verifyEmailAgain(email);
						}
					});
				}
			}
		);
	}

	public directLogin(): void {
		if (this.identity != null && this.details != null && this.token != null) {
			localStorage.setItem('identity', JSON.stringify(this.identity));
			localStorage.setItem('details', JSON.stringify(this.details));
			localStorage.setItem('token', this.token);
			Swal.fire('Login exitoso...', 'Ingreso realizado con éxito', 'success');

			if (this.identity.role_user == 'CLIENTE') {
				this.userService.loginEvent.emit(true);
				this.router.navigate(['/perfil-cliente']);
			} else if (this.identity.role_user == 'CADIAN') {
				this.userService.loginEvent.emit(true);
				this.router.navigate(['/perfil-cadian']);
			} else {
				this.router.navigate(['/']);
			}
		}
	}

	public onSubmit(): void {
		if (this.currentAttempt < this.attemptsRegister) {
			this.currentAttempt++;

			if (this.currentAttempt == 1) {
				Swal.fire({
					icon: 'info',
					title: 'Creando cuenta...',
					allowOutsideClick: false,
					allowEscapeKey: false,
					onOpen: () => { Swal.showLoading(); }
				});
			}

			this.userService.register(this.user).subscribe(
				(response: any) => {
					if (response.status == 'success') {
						setTimeout(() => {
							Swal.hideLoading();

							Swal.update({
								icon: 'success',
								title: response.message,
								html: response.information,
								allowOutsideClick: false,
								allowEscapeKey: false,
								showConfirmButton: false
							});
						}, 1000);
					}
				},
				(error: any) => {
					if (this.currentAttempt < this.attemptsRegister) {
						this.onSubmit();
					} else {
						setTimeout(() => {
							this.currentAttempt = 0;
							Swal.hideLoading();

							Swal.update({
								icon: 'error',
								title: 'Algo salio mal',
								html: JSON.stringify(error.error.error),
								allowOutsideClick: false,
								allowEscapeKey: false,
								showConfirmButton: true,
								confirmButtonText: 'Aceptar'
							});
						}, 1000);
					}
				}
			);
		} else {
			this.currentAttempt = 0;
		}
	}

	public getTiposCliente(): void {
		this.tiposClienteService.getTiposCliente().subscribe(
			(response: any) => {
				if (response.status == 'success') {
					this.tipos_cliente = response.tipos_cliente;
				}
			},
			(error: any) => {
				// ...
			}
		);
	}
}
