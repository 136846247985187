import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { ReservaService } from 'src/app/services/reserva.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-detalle',
  templateUrl: './detalle.component.html',
  styleUrls: ['./detalle.component.css']
})
export class DetalleComponent implements OnInit {

  public identity;
  public token;
  public details;
  public reserva;

  constructor(
    public _userService: UserService,
    public _reservaService: ReservaService,
    private _router: Router,
    private _route: ActivatedRoute
  ) { 
    this.loadUser();
  }

  ngOnInit() {
    this.checkReservaToken();
  }

  loadUser(){
    this.identity = this._userService.getIdentity();
    this.token = this._userService.getToken();
    this.details = this._userService.getDetails();
  }

  checkReservaToken(){

    this._route.params.subscribe(params => {

      let token = params['token'];

      if(token != undefined || token != ''){

          let form = new FormData();
          form.append('token', token);

          this._reservaService.checkReserva(this.token, form).subscribe(
            response => {

              if(response.status == 'success'){
                this.reserva = response.reserva;
              }

            },
            errorResponse => {
              if(this.identity.role_user == 'CLIENTE'){
                this._router.navigate(['/perfil-cliente/reservas']);
              }
              else if(this.identity.role_user == 'CADIAN'){
                  this._router.navigate(['/perfil-cadian/reservas']);
              }
              else{
                  this._router.navigate(['/']);
              }
            }
          );
      }

    });
}

}
