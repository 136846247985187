import { Component, OnInit, ViewEncapsulation, AfterViewInit, OnDestroy } from '@angular/core';
import { UserService } from '../../services/user.service';
import { Cliente } from '../../models/cliente';
import { global } from '../../services/global';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { trigger } from '@angular/animations';
import { AppGlobals } from 'src/app/app.global';
import { BalanceService } from 'src/app/services/balance.service';
import { ReservaService } from 'src/app/services/reserva.service';
import { ClienteService } from 'src/app/services/cliente.service';
import { Router, NavigationEnd } from '@angular/router';
import Swal from 'sweetalert2';
import * as UIkit from 'uikit';
declare let $: any;

@Component({
	selector: 'app-perfil-cliente',
	templateUrl: './perfil-cliente.component.html',
	styleUrls: ['./perfil-cliente.component.css'],
	providers: [AppGlobals],
	encapsulation: ViewEncapsulation.None
})
export class PerfilClienteComponent implements OnInit, AfterViewInit, OnDestroy {
	public cliente: Cliente;
	public identity: any;
	public token: any;
	public details: any = {foto: null};
	public status: string;
	public url: any;
	public imageChangedEvent: any = '';
	public croppedImage: any = '';
	public loadingImage: any;
	public percent_progress: any;
	public balance: number = 0;
	public reservas: any[] = [];
	public stats: any;
	private ngAfterViewInitLaunched: boolean = false;
	private detailsChangeEventSubs: any;
	private routerEventsSubs: any;

	private addressData = {
		street: '',
		outsideNumber: '',
		sector: '',
		city: '',
		province: ''
	};

	// The following variable ("requiredFields") is used to verify the profile
	// progress percent of the client. The "requiredFields.property.field" is
	// just an indentifier and it can be any string value.
	private requiredFields: any = {
		// Client data
		profile_img: {
			field: 'cliente.foto',
			filled: false,
			popover_content: '#profile_img_popover_content',
			popover_to: '#profile_img'
		},
		gender: {
			field: 'cliente.key_genero',
			filled: false,
			popover_content: '#client_gender_popover_content2',
			popover_to: '#menu_options'
		},
		about_me: {
			field: 'cliente.acerca_de_mi',
			filled: false,
			popover_content: '#client_about_me_popover_content2',
			popover_to: '#menu_options'
		},

		// Client address
		street: {
			field: 'addressData.street',
			filled: false,
			popover_content: '#client_address_popover_content2',
			popover_to: '#menu_options'
		},
		outside_number: {
			field: 'addressData.outsideNumber',
			filled: false,
			popover_content: '#client_address_popover_content2',
			popover_to: '#menu_options'
		},
		sector: {
			field: 'addressData.sector',
			filled: false,
			popover_content: '#client_address_popover_content2',
			popover_to: '#menu_options'
		},
		city: {
			field: 'addressData.city',
			filled: false,
			popover_content: '#client_address_popover_content2',
			popover_to: '#menu_options'
		},
		province: {
			field: 'addressData.province',
			filled: false,
			popover_content: '#client_address_popover_content2',
			popover_to: '#menu_options'
		},
		telephone: {
			field: 'cliente.telefono',
			filled: false,
			popover_content: '#client_address_popover_content2',
			popover_to: '#menu_options'
		},

		// Client documents
		dni_img: {
			field: 'cliente.foto_dni',
			filled: false,
			popover_content: '#client_documents_popover_content2',
			popover_to: '#menu_options'
		},
		dni_img_verify: {
			field: 'cliente.foto_dni_comprobacion',
			filled: false,
			popover_content: '#client_documents_popover_content2',
			popover_to: '#menu_options'
		}
	};

	constructor(
		public userService: UserService,
		public clienteService: ClienteService,
		public balanceService: BalanceService,
		public reservaService: ReservaService,
		public appGlobals: AppGlobals,
		private router: Router
	) {
		this.cliente = new Cliente(1, '', '', '1', '1', '1', 1, '', '', 1, '', '', '', '', '', 1, '', '', '', 0, '', 0, '', '', 0, 0);
		this.url = global.url;
		this.stats = {reservas: 0, puntos: 0};

		this.routerEventsSubs = this.router.events.subscribe((event: any) => {
			if (event instanceof NavigationEnd) {
				this.requiredFields.gender.popover_content = '#client_gender_popover_content2';
				this.requiredFields.gender.popover_to = '#menu_options';
				this.requiredFields.about_me.popover_content = '#client_about_me_popover_content2';
				this.requiredFields.about_me.popover_to = '#menu_options';

				this.requiredFields.street.popover_content = '#client_address_popover_content2';
				this.requiredFields.street.popover_to = '#menu_options';
				this.requiredFields.outside_number.popover_content = '#client_address_popover_content2';
				this.requiredFields.outside_number.popover_to = '#menu_options';
				this.requiredFields.sector.popover_content = '#client_address_popover_content2';
				this.requiredFields.sector.popover_to = '#menu_options';
				this.requiredFields.city.popover_content = '#client_address_popover_content2';
				this.requiredFields.city.popover_to = '#menu_options';
				this.requiredFields.province.popover_content = '#client_address_popover_content2';
				this.requiredFields.province.popover_to = '#menu_options';
				this.requiredFields.telephone.popover_content = '#client_address_popover_content2';
				this.requiredFields.telephone.popover_to = '#menu_options';

				if (event.url == '/perfil-cliente/mi-cuenta') {
					this.requiredFields.gender.popover_content = '#client_gender_popover_content';
					this.requiredFields.gender.popover_to = '#menu_content';
					this.requiredFields.about_me.popover_content = '#client_about_me_popover_content';
					this.requiredFields.about_me.popover_to = '#menu_content';

					this.requiredFields.street.popover_content = '#client_address_popover_content';
					this.requiredFields.street.popover_to = '#menu_content';
					this.requiredFields.outside_number.popover_content = '#client_address_popover_content';
					this.requiredFields.outside_number.popover_to = '#menu_content';
					this.requiredFields.sector.popover_content = '#client_address_popover_content';
					this.requiredFields.sector.popover_to = '#menu_content';
					this.requiredFields.city.popover_content = '#client_address_popover_content';
					this.requiredFields.city.popover_to = '#menu_content';
					this.requiredFields.province.popover_content = '#client_address_popover_content';
					this.requiredFields.province.popover_to = '#menu_content';
					this.requiredFields.telephone.popover_content = '#client_address_popover_content';
					this.requiredFields.telephone.popover_to = '#menu_content';
				}

				this.requiredFields.dni_img.popover_content = '#client_documents_popover_content2';
				this.requiredFields.dni_img.popover_to = '#menu_options';
				this.requiredFields.dni_img_verify.popover_content = '#client_documents_popover_content2';
				this.requiredFields.dni_img_verify.popover_to = '#menu_options';

				if (event.url == '/perfil-cliente/documentos') {
					this.requiredFields.dni_img.popover_content = '#client_documents_popover_content';
					this.requiredFields.dni_img.popover_to = '#menu_content';
					this.requiredFields.dni_img_verify.popover_content = '#client_documents_popover_content';
					this.requiredFields.dni_img_verify.popover_to = '#menu_content';
				}

				this.showPopoverHelp();
			}
		});
	}

	public ngOnInit(): void {
		this.loadUser();
		this.getStats();
	}

	public ngAfterViewInit(): void {
		this.getClientAddress();

		this.detailsChangeEventSubs = this.userService.detailsChangeEvent.subscribe(() => {
			this.loadUser();
			this.getClientAddress();
		});

		this.ngAfterViewInitLaunched = true;
	}


	public ngOnDestroy(): void {
		$('.element-with-popover-c').popover('dispose');
		this.detailsChangeEventSubs.unsubscribe();
		this.routerEventsSubs.unsubscribe();
	}

	private refreshProgressBarPercent(): void {
		let fieldsFilled = 0;

		for (let property in this.requiredFields) {
			if (this.requiredFields[property].filled) {
				fieldsFilled++;
			}
		}

		let percent = (100 / Object.keys(this.requiredFields).length) * fieldsFilled;
		this.percent_progress = percent >= 100 ? 100 : Math.ceil(percent);
		this.showPopoverHelp();
	}

	private showPopoverHelp(): void {
		if (this.ngAfterViewInitLaunched) {
			$('.element-with-popover-c').popover('dispose');

			for (let property in this.requiredFields) {
				if (!this.requiredFields[property].filled) {
					$(this.requiredFields[property].popover_to).popover({
						html: true,
						container: 'body',
						content: $(this.requiredFields[property].popover_content).html(),
						placement: 'top',
						fallbackPlacement: ['top'],
						trigger: 'manual'
					});

					$(this.requiredFields[property].popover_to).popover('show');
					break;
				}
			}
		}
	}

	public getStats(): void {
		this.clienteService.stats(this.token).subscribe(
			(response: any) => {
				if (response.status == 'success') {
					this.stats.reservas = response.reservas.count;
					this.stats.puntos = response.puntos;
				}
			},
			(error: any) => {
				// ...
			}
		);
	}

	private loadUser(): void {
		this.identity = this.userService.getIdentity();
		this.token = this.userService.getToken();
		this.details = this.userService.getDetails();

		this.cliente = new Cliente(
			this.identity.sub,
			this.details.nombre,
			this.details.apellidos,
			this.details.key_provincia,
			this.details.key_municipio,
			this.details.key_sector,
			this.details.numero,
			this.details.direccion,
			this.details.tipo_cliente,
			this.details.key_genero,
			this.details.correo,
			this.details.telefono,
			this.details.foto,
			this.details.key_dni,
			this.details.foto_dni,
			this.details.forma_pago,
			this.details.estatus,
			this.details.acerca_de_mi,
			this.details.puesto,
			this.details.check_autorizacion,
			this.details.foto_dni_comprobacion,
			this.details.solicitudes_autonomas,
			this.details.facebook,
			this.details.instagram,
			this.details.codigo_postal,
			this.details.key_provincia
		);

		if (this.cliente.foto != null) this.requiredFields.profile_img.filled = true;
		if (this.cliente.key_genero != null) this.requiredFields.gender.filled = true;
		if (this.cliente.acerca_de_mi != null) this.requiredFields.about_me.filled = true;
		if (this.cliente.telefono != null) this.requiredFields.telephone.filled = true;
		if (this.cliente.foto_dni != null) this.requiredFields.dni_img.filled = true;
		if (this.cliente.foto_dni_comprobacion != null) this.requiredFields.dni_img_verify.filled = true;
		
		this.getBalance();
		this.getReservasActivas();
	}

	private getClientAddress(): void {
		this.userService.getUserData(this.userService.getToken()).subscribe(
			(response: any) => {
				this.addressData.street = response.street;
				this.addressData.outsideNumber = response.outside_number;
				this.addressData.sector = response.sector;
				this.addressData.city = response.city;
				this.addressData.province = response.province;
				if (this.addressData.street != '') this.requiredFields.street.filled = true;
				if (this.addressData.outsideNumber != '') this.requiredFields.outside_number.filled = true;
				if (this.addressData.sector != '') this.requiredFields.sector.filled = true;
				if (this.addressData.city != '') this.requiredFields.city.filled = true;
				if (this.addressData.province != '') this.requiredFields.province.filled = true;
				this.refreshProgressBarPercent();
			},
			(error: any) => {
				this.refreshProgressBarPercent();
			}
		);
	}

	public getBalance(): void {
		this.balanceService.getBalanceByUser(this.token).subscribe(
			(response: any) => {
				if (response.status == 'success') {
					this.balance = response.balance;
				}
			},
			(error: any) => {
				// ...
			}
		);
	}

	public submitImage(): void {
		const formData = new FormData();
		formData.append('profile', this.croppedImage);

		Swal.fire({
			title: "Subiendo imagen",
			text: "Espera unos segundos...",
			imageUrl: "../../../assets/images/loading.gif",
			showConfirmButton: false,
			allowOutsideClick: false
		});

		this.userService.updateImageProfile(this.token, formData).subscribe(
			(response: any) => {
				if (response.status == 'success') {
					Swal.fire('Imagen actualizada', response.message, 'success');
					this.details = response.changes;
					localStorage.setItem('details', JSON.stringify(this.details));
					let modal = UIkit.modal(".cropper");
					modal.hide();
					$('#profile_img').popover('dispose');
					this.userService.detailsChangeEvent.emit();
				}
			},
			(error: any) => {
				let errores = '';

				Object.keys(error.error.error).forEach(function (k) {
					errores += '- ' + error.error.error[k] + "<br>";
				});

				Swal.fire({
					title: 'UPS',
					icon: 'error',
					html: errores
				});
			}
		);
	}

	public changeImage(event): void {
		if (event.target.files.length > 0) {
			this.imageChangedEvent = event;
			this.loadingImage = true;
			let modal = UIkit.modal(".cropper");
			modal.show();
		}
	}

	public imageCropped(event: ImageCroppedEvent): void {
		const imageName = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15) + '.png';
		const imageBlob = this.dataURItoBlob(event.base64);
		const imageFile = new File([imageBlob], imageName, { type: 'image/png' });
		this.croppedImage = imageFile;
	}

	public imageLoaded(): void {
		this.loadingImage = false;
	}

	public startCropImage(): void {
		// ...
	}

	public cropperReady(): void {
		// ...
	}

	public loadImageFailed(): void {
		// ...
	}

	public dataURItoBlob(dataURI): any {
		let base64 = dataURI.replace(/^[^,]+,/, '');
		const byteString = atob(base64);
		const arrayBuffer = new ArrayBuffer(byteString.length);
		const int8Array = new Uint8Array(arrayBuffer);

		for (let i = 0; i < byteString.length; i++) {
			int8Array[i] = byteString.charCodeAt(i);
		}

		const blob = new Blob([int8Array], { type: 'image/png' });
		return blob;
	}

	public getReservasActivas(): void {
		this.reservaService.getReservasActivas(this.token).subscribe(
			(response: any) => {
				if (response.status == 'success') {
					this.reservas = response.reservas;
				}
			},
			(error: any) => {
				// ...
			}
		);
	}
}
