import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { BalanceService } from 'src/app/services/balance.service';

@Component({
  selector: 'app-balance',
  templateUrl: './balance.component.html',
  styleUrls: ['./balance.component.css']
})
export class BalanceComponent implements OnInit {

  public identity;
  public token;
  public details;
  public balance;

  constructor(
    public _userService: UserService,
    public _balanceService: BalanceService
  ) { 
    this.loadUser();
    this.getBalance();
  }

  ngOnInit() {
  }

  loadUser(){
    this.identity = this._userService.getIdentity();
    this.token = this._userService.getToken();
    this.details = this._userService.getDetails();
  }

  getBalance(){

    this._balanceService.getBalanceByUser(this.token).subscribe(
      response => {
        
        if(response.status == 'success'){
          this.balance = response.balance;
        }

      },
      errorResponse => {
        console.log(errorResponse);
      }
    );

  }

}
