import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { global } from './global';

@Injectable({
  providedIn: 'root'
})
export class DistritosService {

  public url: string;

  constructor(
    private _http: HttpClient
  ) { 

    this.url = global.url;

  }

  getDistritos():Observable<any>{

    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this._http.get(this.url+'distritos',{headers: headers});

  }

  getDistritosByMunicipio(municipio):Observable<any>{

    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this._http.get(this.url+'distritos/'+municipio, {
      headers: headers
  });

  }

  getDistritosByProvincia(provincia):Observable<any>{

    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this._http.get(this.url+'distritos/provincia/'+provincia, {
      headers: headers
  });

  }

  getDistritoById(id):Observable<any>{

    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this._http.get(this.url+'distrito/'+id, {
      headers: headers
  });

  }
}
