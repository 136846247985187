import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { global } from '../../../services/global';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-addons',
  templateUrl: './addons.component.html',
  styleUrls: ['./addons.component.css'],
  // providers: [UserService]
  providers: []
})
export class AddonsComponent implements OnInit {
  public identity;
  public token;
  public details;
  public url;
  public myRecaptcha: boolean;
  public nombre: string;
  public correo: string;

  constructor(public _userService: UserService) {
    //...
  }

  ngOnInit() {
    this.loadUser();
    this.url = global.url;
  }

  loadUser() {
    this.identity = this._userService.getIdentity();
    this.token = this._userService.getToken();
    this.details = this._userService.getDetails();
  }

  onSubmit(form) {
    const formData = new FormData();
    formData.append('nombre', this.nombre);
    formData.append('email', this.correo);

    this._userService.enviarInvitacion(this.token, formData).subscribe(
      response => {
        if (response.status == 'success') {
          Swal.fire('Muy bien', response.message , 'success');
          this.nombre = '';
          this.correo = '';
        }
      },
      errorResponse => {
        // console.log(<any>errorResponse);
        // console.log(errorResponse.error.error);
        
        let errores = JSON.stringify(errorResponse.error.error);
        Swal.fire('UPS...', errores , 'error');
      }
    );
  }

  onScriptLoad() {
    // console.log('Google reCAPTCHA loaded and is ready for use!');
  }

  onScriptError() {
    // console.log('Something went long when loading the Google reCAPTCHA');
  }
}
