import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { FeedService } from 'src/app/services/feed.service';
import { global } from '../../../services/global';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import * as UIkit from 'uikit';
import { VacantesService } from 'src/app/services/vacantes.service';
import Swal from 'sweetalert2';
import { ClienteService } from 'src/app/services/cliente.service';
import { DesbloqueoCuidadorService } from 'src/app/services/desbloqueoCuidador.service';
import { ReservaService } from 'src/app/services/reserva.service';
import { NgForm } from '@angular/forms';
import { BalanceService } from 'src/app/services/balance.service';
import { DistritosService } from 'src/app/services/distritos.service';
import { ProvinciasService } from 'src/app/services/provincias.service';
import { AtencionesService } from 'src/app/services/atenciones.service';
import * as moment from 'moment';
import { IDayCalendarConfig } from 'ng2-date-picker';
import { FavoritoService } from 'src/app/services/favorito.service';
import { ContratarService } from 'src/app/services/contratar.service';
import { Router } from '@angular/router';
import { MunicipiosService } from 'src/app/services/municipios.service';
import { ServiciosService } from 'src/app/services/servicios.service';
import { CargosService } from 'src/app/services/cargos.service';
import { MembresiasService } from 'src/app/services/membresias.service';

@Component({
  selector: 'app-feed-cliente',
  templateUrl: './feed-cliente.component.html',
  styleUrls: ['./feed-cliente.component.css']
})
export class FeedClienteComponent implements OnInit {
  public identity;
  public token;
  public details;
  public cuidadores;
  public url;
  public cuidador_selected;
  public cadian_obj: any = {};
  public id_cuidador;
  public balance;
  public faStar = faStar;
  public p: number = 1;
  public vacantes = [];
  public cadian_pagado : boolean = false;
  public atenciones;
  public provincias;
  public distritos;
  public city: string = '';

  public config = <IDayCalendarConfig>{
    locale: "es",
    min: moment(new Date()).format('DD-MM-YYYY'),
    format: "DD-MM-YYYY HH:mm"
  };
  
  public tipo = 'trabajo';
  public select_cuidador = 'todos';
  public select_edad = 'todos';
  public select_experiencia = 0;
  public select_tipo = 'todos';
  public municipios = [];
  public select_ciudad = 'todos';
  public select_categoria = 'todos';
  public servicios;
  public cargos = [];
  public membresia = [];
  public oferta_selected;
  public cadian_seleccionado: any = {};

  constructor(
    public _userService: UserService,
    public _feedService: FeedService,
    public _vacantesService: VacantesService,
    public _clienteService: ClienteService,
    public _desbloqueoService: DesbloqueoCuidadorService,
    public _reservaService: ReservaService,
    public _balanceService: BalanceService,
    public _distritosService: DistritosService,
    public _provinciasService: ProvinciasService,
    public _favoritoService: FavoritoService,
    public _atencionesService: AtencionesService,
    public _contratarService: ContratarService,
    public _municipiosService: MunicipiosService,
    public _servicioService: ServiciosService,
    public _cargosService: CargosService,
    public _membresiasService: MembresiasService,
    private _router: Router
  ) {
    this.loadUser();
    this.url = global.url;
   }

  ngOnInit() {
    this.getCuidadores();
    this.getMisVacantes();
    this.getBalance();
    this.getProvincias();
    this.getDistritos();
    this.getAtenciones();
    this.getMunicipios();
    this.getServicios();
    this.getCargos();
    this.getMembresiaEstatus();
  }
  

  loadUser(){
    this.identity = this._userService.getIdentity();
    this.token = this._userService.getToken();
    this.details = this._userService.getDetails();
  }


  getMembresiaEstatus(){

    this._membresiasService.getMembresiaEstatus(this.token).subscribe(
      response => {
        
        if(response.status == 'success'){
          this.membresia = response.membresias;
        }

      },
      errorResponse => {
        // console.log(errorResponse);
      }
    );

  }

  getCargos(){
    this._cargosService.getCargos().subscribe(
      response => {
        if(response.status == 'success'){
          this.cargos = response.cargos;
        }
      },
      errorResponse => {
        // console.log(errorResponse);
      }
    );
  }

  getServicios(){
    this._servicioService.getServicios().subscribe(
      response => {
        
        if(response.status == 'success'){
          this.servicios = response.servicios;
        }
      },
      errorResponse => {
        // console.log(errorResponse);
      }
    );
  }

  getMisVacantes(){
    this._vacantesService.getMisVacantes(this.token, this.tipo).subscribe(
      response => {
        
        if(response.status == 'success'){
          this.vacantes = response.resultado;
        }

      },
      errorResponse => {
        // console.log(errorResponse);
      }
    );
  }

  getProvincias(){
    this._provinciasService.getProvincias().subscribe(
      response => {

        if(response.status == 'success'){
          this.provincias = response.provincias;
        }

      },
      errorResponse => {

        let errores = '';
        Object.keys(errorResponse.error.error).forEach(function(k){
          errores+= '- '+ errorResponse.error.error[k]+"<br>";
        });

        Swal.fire({
          title: 'UPS',
          icon: 'error',
          html: errores
        });

      }
    );
  }

  getMunicipios(){
    this._municipiosService.getMunicipios().subscribe(
      response => {
        if(response.status == 'success'){
          this.municipios = response.municipios;
        }
      },
      errorResponse => {
        // console.log(errorResponse);
      }
    );
  }

  getDistritos($event = null, change = false){

    let provincia = 0;

    if(change){
    provincia = $event.target.selectedOptions[0].value;
    }

    this._distritosService.getDistritosByProvincia(provincia).subscribe(
      response => {

        if(response.status == 'success'){
          this.distritos = response.distritos;
        }

      },
      errorResponse => {

        let errores = '';
        Object.keys(errorResponse.error.error).forEach(function(k){
          errores+= '- '+ errorResponse.error.error[k]+"<br>";
        });

        Swal.fire({
          title: 'UPS',
          icon: 'error',
          html: errores
        });

      }
    );

  }

  getAtenciones(){
    this._atencionesService.getAtenciones().subscribe(
      response => {

        if(response.status == 'success'){
          this.atenciones = response.atenciones;
        }

      },
      errorResponse => {
        // console.log(errorResponse);
      }
    );
  }

  getBalance(){

    this._balanceService.getBalanceByUser(this.token).subscribe(
      response => {
        
        if(response.status == 'success'){
          this.balance = response.balance;
        }

      },
      errorResponse => {
        // console.log(errorResponse);
      }
    );

  }

  getCuidadores() {
    this.cuidadores = [];

    this._feedService.getCuidadores(
      this.token,
      this.select_cuidador,
      this.select_edad,
      this.select_experiencia,
      this.select_tipo,
      // this.select_ciudad,
      this.city,
      this.select_categoria
    ).subscribe(
      (response: any) => {
        this.cuidadores = response;
        this.p = 1;
      },
      (error: any) => {
        // ...
      }
    );
  }

  cuidadoresPageChange(page, element) {
    element.scrollIntoView({behavior: 'smooth', block: 'center'});
    this.p = page;
  }

  experiencia(id){

    let tiempo = 'N/E';

    switch(id){
      case 1:
        tiempo = '1 Año';
        break;
      case 2:
        tiempo = '2 Años';
        break;
      case 3:
        tiempo = '3 Años';
        break;
      case 4:
        tiempo = '+5 Años';
        break;
    }

    return tiempo;
  }

  invitar(token) {
    this.cuidador_selected = token;
    let modal = UIkit.modal("#modal1");
    modal.show();
  }

  enviarInvitacion(token_vacante){
    // console.log(token_vacante);
    this._feedService.enviarInvitacion(this.cuidador_selected, token_vacante, this.token).subscribe(
      response => {
        if(response.status == 'success'){
          let modal = UIkit.modal("#modal1");
          modal.hide();
          Swal.fire('¡Muy bien!', response.message , 'success');
        }
      },
      errorResponse => {
        Swal.fire('¡UPS!', errorResponse.error.message , 'error');
      }
    );

  }

  pagarDesbloqueo(event: any) {

    //DESHABILITAMOS EL BOTON
    event.target.disabled = true;

    //VERIFICAMOS SI PUBLICO UNA OFERTA
    if(this.id_cuidador == 0){
      return;
    }

    let form = new FormData();
    form.append('id_cadian', this.id_cuidador);

    Swal.fire({
      title: 'Un momento',
      icon: 'info',
      text: 'Confirmanos que quieres pagar el desbloqueo de este cuidador',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Pagar'
    }).then((result) => {
      if (result.value) {
        this._clienteService.pagarDesbloqueoBalance(this.token, form).subscribe(
          response => {
    
            if(response.status == 'success'){
              Swal.fire('¡Muy bien!', response.message , 'success');
    
              let modal = UIkit.modal("#modal2");
              modal.show();
            }
            else{
              Swal.fire('UPS', response.message , 'error');
            }
    
          },
          errorResponse => {
            // console.log(<any>errorResponse);
          }
        );
      }
    });

  }

  reservar(){
    let modal = UIkit.modal("#modal-reserva");
    modal.show();
  }

  contactar($event){

    let form = new FormData();
    form.append('id_cadian', this.id_cuidador);
    
    //CONSULTAMOS SI EL CUIDADOR A CONTACTAR YA FUE CONTACTADO ANTERIORMENTE
    this._desbloqueoService.checkDesbloqueo(this.token, form).subscribe(
      response => {
        
        if(response.status == 'success'){
          this.cadian_pagado = true;
          this._router.navigate(['/mensajeria/'+response.token_conversacion]);
        }
        else{
          this.cadian_pagado = false;
          // console.log(this.cadian_obj);
          let modal = UIkit.modal("#modal-desbloqueo");
          modal.show();
        }

      },
      errorResponse => {
        // console.log(errorResponse);
      }
    );
  }

  contratar($event){

    let form = new FormData();
    form.append('id_cadian', this.id_cuidador);
    
    //CONSULTAMOS SI EL CUIDADOR A CONTACTAR YA FUE CONTACTADO ANTERIORMENTE
    this._desbloqueoService.checkDesbloqueo(this.token, form).subscribe(
      response => {
        
        if(response.status == 'success'){
          this.cadian_pagado = true;
          let modal = UIkit.modal("#modal-contratar");
          modal.show();
        }
        else{
          // console.log(this.cadian_obj)
          this.cadian_pagado = false;
          let modal = UIkit.modal("#modal-desbloqueo");
          modal.show();
        }

      },
      errorResponse => {
        // console.log(errorResponse);
      }
    );
  }

  sendContratacion(id_oferta){

    let form = new FormData();
    form.append('id_cadian', this.id_cuidador);
    form.append('id_oferta', id_oferta);
    
    //CONSULTAMOS SI EL CUIDADOR A CONTACTAR YA FUE CONTACTADO ANTERIORMENTE
    this._contratarService.contratar(this.token, form).subscribe(
      response => {
        
        if(response.status == 'success'){
          Swal.fire('¡Muy bien!', response.message , 'success');
          let modal = UIkit.modal("#modal-contratar");
          modal.hide();
          this._router.navigate(['/mensajeria/'+response.conversacion]);
        }
      },
      errorResponse => {
        Swal.fire('¡Muy bien!', errorResponse.error.message , 'error');
      }
    );
  }

  onSubmit(form: NgForm){

    let formData = new FormData();
    formData.append('id_cuidador', this.id_cuidador);
    formData.append('direccion', form.value.direccion);
    formData.append('descripcion', form.value.descripcion);
    formData.append('provincia', form.value.provincia);
    formData.append('ciudad', form.value.ciudad);
    formData.append('fecha_reserva_inicio', form.value.fecha_reserva_inicio);
    formData.append('fecha_reserva_final', form.value.fecha_reserva_final);
    formData.append('atenciones', form.value.atenciones);

    this._reservaService.enviarReserva(this.token, formData).subscribe(
      response => {

        if(response.status == 'success'){
          Swal.fire('¡Muy bien!', response.message , 'success');

          let modal = UIkit.modal("#modal-reserva");
          modal.hide();

        }

      },
      errorResponse => {
        let errores = '';
        Object.keys(errorResponse.error.error).forEach(function(k){
          errores+= '- '+ errorResponse.error.error[k]+"<br>";
        });

        Swal.fire({
          title: 'UPS',
          icon: 'error',
          html: errores
        });
      }
    );
  }

  favorito(obj){
    this._favoritoService.favorito(this.token, 'CUIDADOR', obj.id_cadian).subscribe(
      response => {
        if(response.status == 'success'){
          if(response.accion == 'agregado'){
            obj.favorito = obj.id_cadian;
          }
          else if(response.accion == 'eliminado'){
            obj.favorito = null;
          }
        }
      },
      errorResponse => {
          Swal.fire({
            title: 'UPS',
            icon: 'error',
            text: errorResponse.error.message
          });
      }
    );
  }

  parseHorario(str){
    return JSON.parse(str);
  }

  dia(numero){
    numero--;
    let dias = ['Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo'];
    return dias[numero];
  }
  
  horarioFormat(str){
    const horas = str.split(':');
    if(horas.length > 1){
      return horas[0]+':'+horas[1];
    }
    else{
      return 'Sin definir';
    }
  }

  verHorarios(cadian) {
    this.cadian_seleccionado = cadian;
    let modal = UIkit.modal("#modal-horarios");
    modal.show();
  }
}
