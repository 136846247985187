import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons';
import { global } from '../../../services/global';
import * as crypto from 'crypto-js';

@Component({
    selector: 'app-recargar-balance',
    templateUrl: './recargar-balance.component.html',
    styleUrls: ['./recargar-balance.component.css']
})
export class RecargarBalanceComponent implements OnInit {
    public faCreditCard = faCreditCard;
    public monto: any;
    public merchantId: string;
    public url: string;
    public urlPaymentApproved: string;
    public urlPaymentDeclined: string;
    public urlPaymentCanceled: string;
    public auth_hash: any;
    public amount_fixed: any;
    public orderNumber : number;
    private keyToHash: string;

    constructor() {
        if (global.productionMode) {
            this.merchantId = '39414060012';
            this.url = 'https://pagos.azul.com.do/PaymentPage/Default.aspx';
            this.keyToHash = '$OfgZyH0jcS7t&zqpaaWfQ59xm9pWmroxgzjzecVkHF5M1Z6jahpAxPR!UPMHew#&kK68yf0M2hp65pXUvTNkf9XV$oA6$#OLSz8bHYVuVMf$JQG#RCyyQdjQiPp80YT';
            this.urlPaymentApproved = 'https://cad.com.do/payment-approved';
            this.urlPaymentDeclined = 'https://cad.com.do/payment-declined';
            this.urlPaymentCanceled = 'https://cad.com.do/payment-canceled';
        } else {
            this.merchantId = '39038540035';
            this.url = 'https://pruebas.azul.com.do/paymentpage/Default.aspx';
            this.keyToHash = 'asdhakjshdkjasdasmndajksdkjaskldga8odya9d8yoasyd98asdyaisdhoaisyd0a8sydoashd8oasydoiahdpiashd09ayusidhaos8dy0a8dya08syd0a8ssdsax';

            // quality
            this.urlPaymentApproved = 'https://cad.cervixsoft.com/payment-approved';
            this.urlPaymentDeclined = 'https://cad.cervixsoft.com/payment-declined';
            this.urlPaymentCanceled = 'https://cad.cervixsoft.com/payment-canceled';

            // localhost
            /* this.urlPaymentApproved = 'http://localhost:4200/payment-approved';
            this.urlPaymentDeclined = 'http://localhost:4200/payment-declined';
            this.urlPaymentCanceled = 'http://localhost:4200/payment-canceled'; */
        }
    }

    public ngOnInit(): void {
        this.orderNumber = this.randNumber();
    }

    public calculateAuthHash(): void {
        let amount = Number(this.monto).toFixed(2);
        this.amount_fixed = amount.toString().replace(".", "");

        let hash = this.merchantId +
            "CAD - Cuidados a Domicilio" +
            "E-Commerce" +
            "$" +
            this.orderNumber.toString() +
            this.amount_fixed +
            "1000" +
            this.urlPaymentApproved +
            this.urlPaymentDeclined +
            this.urlPaymentCanceled +
            "0" +
            "" +
            "" +
            "0" +
            "" +
            "" +
            this.keyToHash;
        
        this.auth_hash = crypto.HmacSHA512(hash, this.keyToHash);
    }

    public randNumber(): any {
        return Math.floor(Math.random() * 10000000);
    }
}
