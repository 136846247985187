import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CanActivate } from '@angular/router';
import { UserService } from '../services/user.service';

@Injectable({
    providedIn: 'root'
})

export class ClienteTradEmp implements CanActivate {

    constructor(
        private authService: UserService,
        private router: Router
    ) { }

    canActivate() {

        let identity = this.authService.getIdentity();
        let details = this.authService.getDetails();

        if (identity != null && identity.role_user == 'CLIENTE' && details.key_tipo_cliente == 1) {
            return true;
        }

        this.router.navigate(['/']);

        return false;
    }
}