import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { global } from './global';

@Injectable({providedIn: 'root'})
export class HistorialService {
	constructor(private httpClient: HttpClient) {
		// ...
	}

	public getHistorial(token: string): Observable<any> {
		return this.httpClient.get(
			global.url + 'user/historial',
			{headers: new HttpHeaders().set('Authorization', token)}
		);
	}

	public getUnlockedCadianData(idClient: string, unlockedAt: string, token: string): Observable<any> {
		return this.httpClient.get(
			global.url + 'user/historial/unlocked-cadian/data',
			{
				headers: new HttpHeaders().set('Authorization', token),
				params: new HttpParams({fromObject: {id_client: idClient, unlock_date: unlockedAt}})
			}
		);
	}
}