import { Component, OnInit } from '@angular/core';
import { BalanceService } from 'src/app/services/balance.service';
import { UserService } from 'src/app/services/user.service';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import {NgForm} from '@angular/forms';
import { TransferirService } from 'src/app/services/transferir.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-transferir',
  templateUrl: './transferir.component.html',
  styleUrls: ['./transferir.component.css']
})
export class TransferirComponent implements OnInit {

  public identity;
  public token;
  public details;
  public balance;
  public email;
  public _loading : boolean = false;
  public step1 : boolean = true;
  public step2 : boolean = false;
  public step3 : boolean = false;
  faEnvelope = faEnvelope;

  constructor(
    public _userService: UserService,
    public _balanceService: BalanceService,
    public _transferService: TransferirService
  ) { 
    this.loadUser();
    this.getBalance();
  }

  loadUser(){
    this.identity = this._userService.getIdentity();
    this.token = this._userService.getToken();
    this.details = this._userService.getDetails();
  }

  ngOnInit() {
  }

  getBalance(){

    this._balanceService.getBalanceByUser(this.token).subscribe(
      response => {
        
        if(response.status == 'success'){
          this.balance = response.balance;
        }

      },
      errorResponse => {
        console.log(errorResponse);
      }
    );

  }

  checkEmailTransfer(form: NgForm){

    this._loading = true;
    let formData = new FormData();
    formData.append('email', form.value.email);
    
    this._transferService.checkEmailTransfer(this.token, formData).subscribe(
      response => {
        
        if(response.estatus == 'success'){
          this.step1 = false;
          this.step2 = true;
          this.email = form.value.email;
        }

      },
      errorResponse => {
        Swal.fire('UPS',errorResponse.error.message,'error');
      }
    );

    this._loading = false;
  }

  onSubmit(form: NgForm) {
    this._loading = true;
    let formData = new FormData();
    formData.append('email', this.email);
    formData.append('concepto', form.value.concepto);
    formData.append('monto', form.value.monto);

    this._transferService.enviarTransferencia(this.token, formData).subscribe(
      response => {
        
        if(response.estatus == 'success'){
          this.step1 = false;
          this.step2 = false;
          this.step3 = true;
        }

      },
      errorResponse => {
        Swal.fire('UPS',errorResponse.error.message,'error');
      }
    );

    this._loading = false;
  }

}
